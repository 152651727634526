import styled, { useTheme } from 'styled-components';
import React from 'react';
import ListButton from 'components/ListButton/ListButton';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import AccessTime from '@material-ui/icons/AccessTime';
import { BookingModel } from 'Services/BookingService';
import { useMediaQuery } from '@material-ui/core';

export interface Props {
  startDateTime: string;
  listDurationHours: number;
  incrementSizeMinutes: number;
  customTimeLabel: string;
  displaySubLabel?: boolean;
  onNext?: (bookingModel?: BookingModel) => void;
  onCustomTimeNext?: () => void;
}

export const TimeSelectList: React.FC<Props> = ({
  startDateTime,
  listDurationHours,
  incrementSizeMinutes,
  customTimeLabel,
  displaySubLabel,
  onNext,
  onCustomTimeNext,
}) => {
  // -- Load required dayjs plugins
  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);

  const isMobileView = useMediaQuery('(max-width:600px)');

  // -- get selected day convert to local time
  let dateTime = dayjs(startDateTime);
  const endTime = dateTime.add(listDurationHours, 'hours');
  const theme = useTheme();

  const generateTimeString = (hours: string, minutes: string) => {
    const hoursInt = parseInt(hours);
    const minutesInt = parseInt(minutes);
    const plural = (val: string) => `${parseInt(val) === 1 ? '' : 's'}`;

    const hourStr = hoursInt ? `${hours} hour${plural(hours)}` : '';
    const minuteStr = minutesInt ? `${minutes} minute${plural(minutes)}` : '';

    return `(${`${hourStr} ${minuteStr}`.trim()})`;
  };

  const timeSelectList: Array<dayjs.Dayjs> = [];
  const subLabelList: Array<string> = [];

  do {
    // -- generate time list
    timeSelectList.push(dateTime.clone());
    dateTime = dateTime.add(incrementSizeMinutes, 'minutes');

    // -- Generate duration list
    const timeDifference = dateTime.diff(startDateTime, 'hours', true);
    const hours = timeDifference.toString().split('.')[0];
    const minutes = dayjs
      .duration(timeDifference, 'hours')
      .minutes()
      .toString();
    const durationString = generateTimeString(hours, minutes);
    subLabelList.push(durationString);
  } while (dateTime.diff(endTime) < 0);

  return (
    <Container role="list" aria-label="select time">
      {timeSelectList.map((time, i) => (
        <ListItem key={i} role="listitem">
          <ListButton
            buttonHeight="50px"
            buttonWidth="100%"
            chevronIconSize="small"
            label={time.format('LT').toString()}
            isLabelBold={true}
            subLabel={displaySubLabel ? subLabelList[i].toString() : ''}
            onClick={() => {
              onNext({
                bookedDateObj: time.toDate(),
                bookedDate: dayjs(time).toISOString(),
              });
            }}
            labelLeftMargin="0px"
            subLabelLeftMargin="35%"
          />
        </ListItem>
      ))}
      <ListItem role="listitem">
        <ListButton
          buttonHeight="50px"
          buttonWidth="100%"
          chevronIconSize="small"
          label={customTimeLabel}
          labelLeftMargin="40px"
          backgroundColor={theme.palette.selectedBlue}
          labelColor={theme.primaryWhite}
          onClick={() => onCustomTimeNext()}
        >
          <AccessTime
            htmlColor={theme.primaryWhite}
            style={{ fontSize: isMobileView ? '24px' : '48px' }}
          />
        </ListButton>
      </ListItem>
    </Container>
  );
};

const Container = styled.div`
  max-width: 90%;
`;

const ListItem = styled.div`
  @media (max-width: 600px) {
    margin-top: 10px;
  }

  @media (min-width: 601px) {
    margin-top: 20px;
  }
`;
