import { Typography } from '@material-ui/core';
import React from 'react';
import { useTheme } from 'styled-components';

export const Title: React.FC<{
  text: string;
}> = ({ text }) => {
  const theme = useTheme();
  return (
    <Typography
      variant={'h2'}
      align="left"
      style={{ color: theme.primaryBlack }}
    >
      {text}
    </Typography>
  );
};
