import React from 'react';
import { Grid } from '@material-ui/core';

export const AppFooter: React.FC = () => {
  return (
    <Grid container justifyContent="center" spacing={3} role="contentinfo">
      <Grid item></Grid>
    </Grid>
  );
};
