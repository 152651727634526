import React, { useContext, useState } from 'react';
import { AcceptBookingStep } from '../BookingSteps';
import { useHistory } from 'react-router-dom';
import { AcceptReservedBooking } from './AcceptReservedBooking';
import AppContext from 'AppContext';
import { BookingViewParam } from 'Booking/UserBookingsContent';
import FlowContext from 'Booking/FlowContext';
import { FlowStep } from 'Booking/FlowStep';
import { DoneCancel } from 'Booking/View/DoneCancel';
import { CovidDeclaration } from 'Booking/CovidDeclaration/CovidDeclaration';
import { AcceptReservedDoneBooking } from './AcceptReservedDoneBooking';
import { confirmReservedBooking } from 'Services/BookingService';
import BookingContext from '../BookingContext';

export interface AcceptBookingStepData {
  currentStep: AcceptBookingStep;
  pastSteps: AcceptBookingStep[];
}

export const AcceptBookingFlow: React.FC = () => {
  const history = useHistory<BookingViewParam>();
  const bookingContext = useContext(BookingContext);

  const appContext = useContext(AppContext);
  const type = appContext.bookingOptions.find(
    (x) => x.resourceType === history.location.state.type
  );

  const [stepData, setStepData] = useState<AcceptBookingStepData>({
    currentStep: AcceptBookingStep.ViewDetails,
    pastSteps: [],
  });
  const [cancelResult, setCancelResult] = useState(false);

  const updateStepData = (nextStep?: AcceptBookingStep) => {
    if (nextStep === undefined || nextStep === null) {
      const previousStep = stepData.pastSteps.pop();
      if (previousStep === undefined) {
        history.push('/'); //no previous step, so go to home page.
      } else {
        setStepData({
          currentStep: previousStep,
          pastSteps: stepData.pastSteps,
        });
      }
    } else {
      stepData.pastSteps.push(stepData.currentStep);
      setStepData({ currentStep: nextStep, pastSteps: stepData.pastSteps });
    }
  };

  const onCancelBooking = (result: boolean) => {
    setCancelResult(result);
    updateStepData(AcceptBookingStep.DoneCancellation);
  };

  const handleBack = () => {
    updateStepData();
  };

  const handleCovidDeclationNext = async () => {
    const confirmBooking = async () => {
      return await confirmReservedBooking(history.location.state.bookingId);
    };

    confirmBooking().then(() => {
      bookingContext.setBookingEntries({
        ...bookingContext.bookingEntries,
        isReloadRequired: true,
      });
    });

    updateStepData(AcceptBookingStep.DoneConfirmBooking);
  };

  const onConfirmBooking = () => {
    updateStepData(AcceptBookingStep.CovidDeclaration);
  };

  return (
    <FlowContext.Provider
      value={{
        currentStep: stepData.currentStep,
      }}
    >
      <>
        <FlowStep step={AcceptBookingStep.ViewDetails}>
          <AcceptReservedBooking
            bookingId={history.location.state.bookingId}
            typeModel={type}
            onConfirm={onConfirmBooking}
            onCancel={onCancelBooking}
            onBack={handleBack}
          />
        </FlowStep>
        <FlowStep step={AcceptBookingStep.DoneCancellation}>
          <DoneCancel cancelResult={cancelResult} onBack={handleBack} />
        </FlowStep>
        <FlowStep step={AcceptBookingStep.CovidDeclaration}>
          <CovidDeclaration
            onNext={handleCovidDeclationNext}
            onBack={handleBack}
          />
        </FlowStep>
        <FlowStep step={AcceptBookingStep.DoneConfirmBooking}>
          <AcceptReservedDoneBooking
            header="Booking Done!"
            bookingResult={true}
            onBack={handleBack}
          />
        </FlowStep>
      </>
    </FlowContext.Provider>
  );
};
