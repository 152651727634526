import styled, { css } from 'styled-components';
import React, { MouseEvent, ReactNode } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

/**
 *List Button Component
 */

export interface Props {
  children?: React.ReactNode;
  buttonWidth?: string;
  buttonHeight?: string;
  buttonDisabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  chevronIconSize?: string;
  isLabelBold?: boolean;
  label?: string;
  subText?: string;
  subLabel?: string;
  buttonIcon?: React.ReactNode;
  backgroundColor?: string;
  labelColor?: string;
  labelLeftMargin?: string;
  subLabelLeftMargin?: string;
}

const ListButton: React.FC<Props> = (props: Props) => {
  const {
    children,
    onClick,
    buttonWidth,
    buttonHeight,
    buttonDisabled,
    chevronIconSize,
    isLabelBold,
    label,
    subText,
    subLabel,
    buttonIcon,
    backgroundColor,
    labelColor,
    labelLeftMargin,
    subLabelLeftMargin,
  } = props;

  return (
    <CustomButton
      onClick={buttonDisabled ? null : onClick}
      width={buttonWidth}
      buttonDisabled={buttonDisabled}
      height={buttonHeight}
      backgroundColor={backgroundColor}
    >
      <Container>
        <IconTextWrapper>
          {children}
          <TextWrapper leftMargin={labelLeftMargin}>
            <LabelText isBold={isLabelBold} color={labelColor}>
              {label}
            </LabelText>
            <SubLabelText>{subText}</SubLabelText>
          </TextWrapper>
        </IconTextWrapper>

        {subLabel && (
          <CenterWrapper
            buttonIcon={buttonIcon}
            subLabelLeftMargin={subLabelLeftMargin}
          >
            {buttonIcon}
            <SubLabel buttonIcon={buttonIcon}>{subLabel}</SubLabel>
          </CenterWrapper>
        )}

        <div>
          {!buttonDisabled && (
            <ChevronIcon icontype={chevronIconSize} iconcolor={labelColor} />
          )}
        </div>
      </Container>
    </CustomButton>
  );
};

export default ListButton;

ListButton.defaultProps = {
  buttonWidth: '50%',
};

const iconSize = {
  large: '72px',
  small: '22px',
};

const mobIconSize = {
  large: '30px',
  small: '20px',
};

/**
 * Styles
 */

const TextStyles = css`
  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const CustomButton = styled.div.attrs({
  role: 'button',
  tabIndex: 0,
  ariaPressed: false,
})<{
  width: string;
  buttonDisabled: boolean;
  height: string;
  backgroundColor: string;
}>`
  padding: 10px 35px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.backgroundColor ||
    (props.buttonDisabled
      ? props.theme.palette.disabledGrey
      : props.theme.primaryWhite)};

  ${(props) =>
    !props.buttonDisabled &&
    css`
      cursor: pointer;
      &:active {
        box-shadow: 0 0 0 2px ${(props) => props.theme.palette.secondary};
        border: 2px solid ${(props) => props.theme.primaryBlack};
        margin: -2px;
      }
      &:focus-visible {
        box-shadow: 0 0 0 4px ${(props) => props.theme.palette.secondary};
        border: none;
      }
    `}

  @media (max-width: 600px) {
    height: 35px;
    padding: 10px 20px;
  }

  @media (min-width: 601px) {
    width: ${(props) => props.width};
    height: ${(props) => props.height || '70px'};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const IconTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.div<{ leftMargin: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${(props) => props.leftMargin || '20px'};
`;

const CenterWrapper = styled.div<{
  buttonIcon?: ReactNode;
  subLabelLeftMargin?: string;
}>`
  display: ${(props) => (props.buttonIcon !== undefined ? 'flex' : '')};
  flex-direction: ${(props) => (props.buttonIcon !== undefined ? 'row' : '')};
  justify-content: ${(props) =>
    props.buttonIcon !== undefined ? 'space-between' : ''};
  align-items: ${(props) =>
    props.buttonIcon !== undefined ? 'center' : 'left'};
  width: ${(props) => (props.buttonIcon === undefined ? '40%' : '')};
  margin-left: ${(props) =>
    props.subLabelLeftMargin ? props.subLabelLeftMargin : ''};
`;

const SubLabel = styled.span<{ buttonIcon?: ReactNode }>`
  color: ${({ theme }) => theme.primaryBlack};
  margin-left: ${(props) => (props.buttonIcon !== undefined ? '10px' : '0px')};
  width: ${(props) => (props.buttonIcon === undefined ? '40%' : '')};
  text-align: ${(props) => (props.buttonIcon === undefined ? 'left' : '')};

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 16px;
  }
`;

export const ChevronIcon = styled(ChevronRightIcon)<{
  icontype: string;
  iconcolor: string;
}>`
  color: ${(props) => props.iconcolor || props.theme.secondaryBlack};
  @media (max-width: 600px) {
    height: ${(props): string => mobIconSize[props.icontype]};
    width: ${(props): string => mobIconSize[props.icontype]};
  }

  @media (min-width: 601px) {
    height: ${(props): string => iconSize[props.icontype]};
    width: ${(props): string => iconSize[props.icontype]};
  }
`;

const LabelText = styled.span<{ isBold: boolean; color: string }>`
  color: ${(props) => props.color || props.theme.primaryBlack};
  font-weight: ${(props): number => (props.isBold ? 700 : 400)};
  ${TextStyles};
`;

const SubLabelText = styled.span`
  color: ${({ theme }) => theme.primaryGray};
  ${TextStyles};
`;
