import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import styled, { useTheme } from 'styled-components';

const Padding = styled.div`
  padding-top: 50px;
`;

export const Loading: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} md={12}>
          <Padding>
            <CircularProgress
              style={{
                left: -40,
                color: theme.palette.secondary,
              }}
            />
          </Padding>
        </Grid>
      </Grid>
    </>
  );
};
