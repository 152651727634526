import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme as ut,
} from '@material-ui/core';
import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import { LocationCard } from './LocationCard';
import { Padding } from 'Booking/BookingMain';
import { BookingModel } from 'Services/BookingService';
import { NextStepTrigger } from './NextStepTrigger';
import { PastLocations } from './PastLocations';
import { LocationModel } from 'Services/LocationService';
import { LocationType } from './LocationType';
import { Title } from 'Shared/Title';

export const HintText = styled(Typography)`
  color: ${({ theme }) => theme.primaryGray};

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 14px;
  }

  margin-top: 30px;
  margin-bottom: 5px;
`;

export const LocationsContent: React.FC<{
  onNext: (
    trigger: NextStepTrigger,
    bookingModel?: BookingModel,
    pastLocations?: LocationModel[]
  ) => void;
  type: string;
}> = ({ onNext, type }) => {
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));

  const [pastLocations, setPastLocations] = useState<{
    content: LocationModel[];
    isLoaded: boolean;
  }>({ content: null, isLoaded: false });

  const onPastLocationsLoad = useCallback(
    (locations: LocationModel[], isLoaded: boolean) => {
      setPastLocations({ content: locations, isLoaded: isLoaded });
    },
    []
  );
  let hintText = '';
  let locationCaption = 'Choose location';
  if (pastLocations.content?.length) {
    hintText = 'Book somewhere else';
    locationCaption = 'Choose another location';
  }
  return (
    <Container
      style={{ maxWidth: '90%', paddingLeft: 10, paddingRight: 10 }}
      role="main"
    >
      <Padding />
      <Title text="Where do you want to book?" />
      <PastLocations
        onNext={onNext}
        onPastLocationsLoad={onPastLocationsLoad}
        type={type}
      />
      <HintText id="chooseAnotherLocationHint">{hintText}</HintText>
      <Grid container spacing={isMobileView ? 2 : 3}>
        <Grid item xs={12} md={6}>
          <LocationCard
            disabled={!pastLocations.isLoaded}
            text={locationCaption}
            locationType={LocationType.NONE}
            onSelect={() =>
              onNext(
                NextStepTrigger.ChooseAnotherLocationSelect,
                null,
                pastLocations.content
              )
            }
            ariaAttributes={{ 'aria-describedby': 'chooseAnotherLocationHint' }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
