import { Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { CompletionCard } from './CompletionCard';
import { useHistory } from 'react-router-dom';
import { OperationType } from './OperationType';
import { NextButton } from 'Shared/NextButton';
import { GridSingleColumn } from 'Shared/GridSingleColumn';
import BookingConfirmation from '../BookOnBehalf/BookingOnBehalfGroup/BookingConfirmation/BookingConfirmation';
import { GroupConfirmationModel } from 'Services/BookingService';
import {
  BookingStatus,
  BookingStatusModel,
} from 'Booking/BookOnBehalf/BookingOnBehalfGroup/UsersBookingStatus/BookingStatus/BookingStatus';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import { BookingOptionModel } from 'Services/ConfigService';

export const CompletionContent: React.FC<{
  isOperationSuccessful: boolean;
  operationType?: OperationType;
  bookingConfirmations?: GroupConfirmationModel[];
  typeModel?: BookingOptionModel;
}> = ({
  isOperationSuccessful,
  operationType,
  bookingConfirmations,
  typeModel,
}) => {
  const history = useHistory();

  const generateBookingStatus = (
    bookingConfirmations: GroupConfirmationModel[]
  ) => {
    const successfulBookings: BookingStatusModel[] = [];
    const unsuccessfulBookings: BookingStatusModel[] = [];

    if (bookingConfirmations) {
      bookingConfirmations.forEach((confirmation) => {
        const bookingStatusModel: BookingStatusModel = {
          user: {
            name: `${
              confirmation.bookeeFirstName !== undefined
                ? confirmation.bookeeFirstName
                : ''
            } ${
              confirmation.bookeeLastName !== undefined
                ? confirmation.bookeeLastName
                : ''
            }`,
            userId: confirmation.userId.toString(),
            email: '',
          },
          status: confirmation.status,
          resourceName: confirmation.resource.name,
        };

        confirmation.status === 'PENDING_CONFIRMATION' ||
        confirmation.status === 'BOOKED'
          ? successfulBookings.push(bookingStatusModel)
          : unsuccessfulBookings.push(bookingStatusModel);
      });
    }

    return unsuccessfulBookings.length > 0 ? (
      <BookingStatus
        successList={successfulBookings}
        failureList={unsuccessfulBookings}
      />
    ) : (
      <BookingConfirmation isOperationSuccessful={isOperationSuccessful} />
    );
  };

  const generateCompletionContent = (
    operationType: OperationType,
    bookingConfirmations: GroupConfirmationModel[]
  ): ReactNode => {
    switch (operationType) {
      case OperationType.CONFIRM:
        return (
          <CompletionCard
            isOperationSuccessful={isOperationSuccessful}
            operationType={operationType}
            type={typeModel}
          />
        );
      case OperationType.CANCEL:
        return (
          <CompletionCard
            isOperationSuccessful={isOperationSuccessful}
            operationType={operationType}
          />
        );
      case OperationType.PENDING:
        return (
          <BookingConfirmation isOperationSuccessful={isOperationSuccessful} />
        );
      case OperationType.ERROR:
        return <ErrorWholePage message="Sorry, we couldn’t load this page." />;
      default:
        return generateBookingStatus(bookingConfirmations);
    }
  };

  return (
    <Grid container justifyContent="center" role="main">
      <GridSingleColumn>
        <Grid item>
          {generateCompletionContent(operationType, bookingConfirmations)}
        </Grid>
        <Grid
          container
          justifyContent="center"
          style={{ paddingTop: 22, paddingBottom: 24 }}
        >
          <NextButton
            text="Back to home"
            width={163}
            onClick={() => history.push('/')}
          />
        </Grid>
      </GridSingleColumn>
    </Grid>
  );
};
