import React, { useEffect, useState, ChangeEvent, useContext } from 'react';
import styled from 'styled-components';
import { FormControl, FormLabel, Link, Grid } from '@material-ui/core';
import { BookingModel } from 'Services/BookingService';
import { FilterGroupModel, getFilters } from 'Services/ResourceService';
import { ContainerSingleColumn } from 'Shared/ContainerSingleColumn';
import { NextButton } from 'Shared/NextButton';
import { Title } from 'Shared/Title';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import { FilterItem } from './FilterItem/FilterItem';
import FlowContext from 'Booking/FlowContext';

export const FilterContent: React.FC<{
  onNext: () => void;
  onFilterChange: (bookingModel?: BookingModel) => void;
}> = ({ onNext, onFilterChange }) => {
  const filterSelectionPrefix = 'group';
  const filterSelectionSuffix = 'filter';

  const [filters, setFilters] = useState<Array<FilterGroupModel>>([]);
  const [filterSelection, setFilterSelection] = useState({});
  const [filterSelectionDefault, setFilterDefault] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>();
  const [retryCount, setRetryCount] = useState(0);
  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const bookingModel: BookingModel = useContext(FlowContext).bookingModel;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // copy input group to new object
    const resetGroup = { ...filterSelection[event.target.name] };

    // reset every value to false if this is a radio group
    if (event.target.type === 'radio') {
      Object.keys(resetGroup).forEach((key) => {
        resetGroup[key] = false;
      });
    }

    // update target value
    resetGroup[event.target.value] = event.target.checked;
    // merge with parent object and update state
    setFilterSelection({
      ...filterSelection,
      [event.target.name]: resetGroup,
    });

    JSON.stringify(filterSelectionDefault) !=
    JSON.stringify({
      ...filterSelection,
      [event.target.name]: resetGroup,
    })
      ? setResetFlag(true)
      : setResetFlag(false);
  };

  const handleReset = () => {
    setFilterSelection(filterSelectionDefault);
    setResetFlag(false);
  };

  const handleOnNext = () => {
    const selectedFilterIds = [];

    for (const group in filterSelection) {
      Object.keys(filterSelection[group]).forEach((key) => {
        if (filterSelection[group][key]) {
          selectedFilterIds.push(key.replace(filterSelectionSuffix, ''));
        }
      });
    }

    const selectedFilterList = filters
      .map(({ resourceAttributes: filters }) => {
        return filters.filter(({ resourceAttributeId: filterId }) => {
          return selectedFilterIds.includes(filterId.toString());
        });
      })
      .flat(1);
    return selectedFilterList;
  };

  const sortFilters = (filters) =>
    filters.map((filterGroup) =>
      Object.assign(filterGroup, {
        resourceAttributes: filterGroup.resourceAttributes.reduce(
          (acc, cur) => {
            if (cur.isDefaultSelected) {
              acc.unshift(cur);
            } else {
              acc.push(cur);
            }

            return acc;
          },
          []
        ),
      })
    );

  useEffect(() => {
    setLoading(true);
    getFilters()
      .then((res) => {
        setFilters(res && res.length ? sortFilters(res) : []);
        const selection = res.reduce((acc, cur) => {
          acc[`${filterSelectionPrefix}${cur.resourceAttributeGroupId}`] = {};

          cur.resourceAttributes.forEach((filter) => {
            acc[`${filterSelectionPrefix}${cur.resourceAttributeGroupId}`][
              `${filterSelectionSuffix}${filter.resourceAttributeId}`
            ] = filter.isDefaultSelected;
          });

          return acc;
        }, {});
        setFilterDefault(selection);
        setResetFlag(bookingModel.resetFlag);
        bookingModel.selectedFilter
          ? setFilterSelection(bookingModel.selectedFilter)
          : setFilterSelection(selection);
      })
      .catch(() => setIsLoadingFailed(true))
      .finally(() => setLoading(false));
  }, [retryCount]);

  useEffect(() => {
    onFilterChange({
      filter: handleOnNext(),
      selectedFilter: filterSelection,
      resetFlag: resetFlag,
    });
  }, [filterSelection]);

  if (loading) {
    return <LoadingWholePage />;
  }

  if (isLoadingFailed) {
    return (
      <ErrorWholePage
        message="Sorry, we couldn’t load this page."
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }

  if (filters.length === 0) {
    return <ErrorWholePage message="Sorry, no applicable filters found." />;
  }

  const filterType = (selectionType) => (selectionType ? 'checkbox' : 'radio');

  return (
    <>
      <ContainerSingleColumn>
        <Title text="Select desk features (Optional)" />
        {filters.map((filterGroup, i) => (
          <StyledFormControl component="fieldset" key={i}>
            <StyledFormLabel component="legend">
              {filterGroup.resourceAttributeGroupName}
            </StyledFormLabel>
            {filterGroup.resourceAttributes.map((filter, j) => (
              <FilterItem
                key={j}
                name={`${filterSelectionPrefix}${filterGroup.resourceAttributeGroupId}`}
                value={`${filterSelectionSuffix}${filter.resourceAttributeId}`}
                label={filter.resourceAttributeName}
                onChange={handleChange}
                checked={
                  filterSelection[
                    `${filterSelectionPrefix}${filterGroup.resourceAttributeGroupId}`
                  ][`${filterSelectionSuffix}${filter.resourceAttributeId}`]
                }
                icon={filter.resourceAttributeIcon}
                type={filterType(filterGroup.isMultipleSelection)}
                description={filter.resourceAttributeNoteText}
              />
            ))}
          </StyledFormControl>
        ))}
      </ContainerSingleColumn>
      {resetFlag ? (
        <LinkContainer item>
          <Link
            component="button"
            variant="inherit"
            onClick={handleReset}
            aria-label="Reset"
            underline="none"
          >
            Reset
          </Link>
        </LinkContainer>
      ) : null}
      <ButtonContainer>
        <NextButton text="Next" width={128} onClick={onNext} />
      </ButtonContainer>
    </>
  );
};

const StyledFormControl = styled(FormControl)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
` as typeof FormControl;

const StyledFormLabel = styled(FormLabel)`
  font-size: 14px;
  color: #000;
  &.Mui-focused {
    color: #000;
  }
  margin-bottom: 12px;
  @media (min-width: 601px) {
    margin-bottom: 6px;
  }
` as typeof FormLabel;

const ButtonContainer = styled(ContainerSingleColumn)`
  padding-top: 75px;
  text-align: center;
`;
const LinkContainer = styled(Grid)`
  text-align: center;
  height: 0px;
  @media (max-width: 600px) {
    padding-top: 30px;
  }
`;
