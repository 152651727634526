import React from 'react';
import { BookingType } from 'Booking/BookingType';
import { generateFlowHeader } from 'Helpers/Utils';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BaseContainer } from 'Shared/BaseContainer';
import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { PreferenceContent } from './PreferenceContent';
import { BookingModel } from 'Services/BookingService';

interface Props {
  type: string;
  onNext: (features: BookingModel) => void;
  onBack: () => void;
  bookingHeaderType?: BookingType;
}

export const SelectPreference: React.FC<Props> = (props) => {
  const { type, onBack, onNext, bookingHeaderType } = props;
  const heading = generateFlowHeader(bookingHeaderType, type);
  useDocumentTitle([heading, 'Choose meeting room preferences']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <PreferenceContent onNext={onNext} bookingType={bookingHeaderType} />
    </BaseContainer>
  );
};
