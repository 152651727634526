import { createContext } from 'react';
import {
  BookingOptionModel,
  BookOnBehalfOptionsModel,
  BookOnBehalfColleagueOptionsModel,
} from 'Services/ConfigService';
import { MeModel } from 'Services/MeService';

export interface AppConfig {
  bookingOptions: BookingOptionModel[];
  bookOnBehalfOptions: BookOnBehalfOptionsModel;
  bookOnBehalfColleagueOptions: BookOnBehalfColleagueOptionsModel;
  loggedInUser: MeModel;
  //add additional config properties here.
}

const appContext = createContext<AppConfig>(null);

export default appContext;
