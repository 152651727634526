import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Grid, Typography } from '@material-ui/core';
import { getCovidDeclaration } from 'Services/CovidDeclarationService';
import SanitizeHtml from 'SanitizeHtml';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import { NextButton } from 'Shared/NextButton';

const Padding = styled.div`
  @media (max-width: 600px) {
    padding-top: 24px;
  }

  @media (min-width: 601px) {
    padding-top: 74px;
  }
`;

const Text = styled(Typography)`
  color: ${({ theme }) => theme.secondaryBlack};

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

export const CovidDeclarationContent: React.FC<{
  onNext: () => void;
}> = ({ onNext }) => {
  const [covidDeclaration, setCovidDeclaration] = useState<{
    content: string;
    isLoadingFailed?: boolean;
  }>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const fetchCovidDeclaration = async () => {
      return await getCovidDeclaration();
    };
    setIsLoading(true);
    fetchCovidDeclaration()
      .then((res) => setCovidDeclaration({ content: res }))
      .catch(() =>
        setCovidDeclaration({
          content: null,
          isLoadingFailed: true,
        })
      )
      .finally(() => setIsLoading(false));
  }, [retryCount]);

  if (isLoading) {
    return <LoadingWholePage />;
  }

  if (covidDeclaration?.isLoadingFailed) {
    return (
      <ErrorWholePage
        message="Sorry, we couldn’t load this page."
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }

  return (
    <Container role="main">
      <Grid container justifyContent="center">
        <Grid item xs={10} md={9}>
          <Padding>
            <Text paragraph={true}>
              <SanitizeHtml html={covidDeclaration?.content} />
            </Text>
          </Padding>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        style={{ paddingTop: 24, paddingBottom: 24 }}
      >
        <NextButton text="I agree" width={135} onClick={onNext} />
      </Grid>
    </Container>
  );
};
