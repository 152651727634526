// -- Environment variable type
type Environment = {
  Development: string;
  Staging: string;
  Production: string;
};

export const AppEnvironment: Environment = {
  Development: 'Development',
  Staging: 'Staging',
  Production: 'Production',
};
