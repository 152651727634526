import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const InstuctionsText = styled(Typography)`
  padding-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 14px;
    padding-left: 20px;
  }

  @media (min-width: 601px) {
    font-size: 14px;
    padding-left: 30px;
  }
`;

export const Instructions: React.FC = () => {
  return (
    <>
      <InstuctionsText align="left" role={'heading'} aria-level={4}>
        <b>Instructions:</b>
      </InstuctionsText>
      <InstuctionsText align="left">Park in any unreserved bay</InstuctionsText>
    </>
  );
};
