import { Button, ButtonBase, Typography } from '@material-ui/core';
import { ArrowForwardIos, Refresh } from '@material-ui/icons';
import styled, { DefaultTheme } from 'styled-components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

type activeTheme = {
  bool?: boolean;
};

export const BookingOptionButton = styled(Button)<{
  $activetheme?: activeTheme;
  theme?: DefaultTheme;
  $isDescriptionVisible?: boolean;
  $disabledBackground?: boolean;
}>`
  background-color: ${(props) =>
    props.$disabledBackground
      ? 'rgba(255,255,255,0.5)'
      : props.$activetheme && props.$activetheme.bool
      ? props.theme.palette.secondary
      : props.theme.primaryWhite} !important;
  width: 100%;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  min-width: 250px;

  &:focus {
    box-shadow: 0 0 0 2px
      ${(props) =>
        props.$activetheme && props.$activetheme.bool
          ? props.theme.palette.primary
          : props.theme.palette.secondary} !important;
    border: 2px solid
      ${(props) =>
        props.$activetheme && props.$activetheme.bool
          ? props.theme.primaryWhite
          : props.theme.primaryBlack};
  }

  @media (max-width: 600px) {
    height: ${(props) => (props.$isDescriptionVisible ? '98px' : '55px')};
  }

  @media (min-width: 601px) {
    height: 90px;
  }
`;

export const DeskFeatureSelectButton = styled(ButtonBase)<{
  activetheme?: activeTheme;
  theme: DefaultTheme;
  component?: React.ElementType;
}>`
  background-color: ${(props) =>
    props.activetheme && props.activetheme.bool
      ? props.theme.palette.secondary
      : props.theme.primaryWhite} !important;
  width: 100%;
  border-radius: 2px;

  &:focus {
    box-shadow: 0 0 0 2px
      ${(props) =>
        props.activetheme && props.activetheme.bool
          ? props.theme.palette.primary
          : props.theme.palette.secondary} !important;
    border: 2px solid
      ${(props) =>
        props.activetheme && props.activetheme.bool
          ? props.theme.primaryWhite
          : props.theme.primaryBlack};
  }

  @media (max-width: 600px) {
    min-width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (min-width: 600px) {
    min-width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const DisableableOptionDeskFeature = styled(DeskFeatureSelectButton)`
  background-color: ${(props) =>
    props.disabled ? 'rgba(255,255,255,0.5)' : ''} !important;
`;

export const DisableableOption = styled(BookingOptionButton)`
  background-color: ${(props) =>
    props.disabled ? 'rgba(255,255,255,0.5)' : ''} !important;
`;

export const ButtonIconSize = `  
  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 601px) {
    width: 48px;
    height: 48px;
  }`;

export const baseText = styled.span`
  text-transform: none;
  @media (max-width: 600px) {
    font-size: 14px;
    padding-left: 15px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
    padding-left: 25px;
  }
`;

const fontSize14px18px = `@media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }`;

export const AddressText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  text-transform: none;
  text-align: left;
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
  ${fontSize14px18px}
`;

export const ErrorCardText = styled(Typography)`
  color: ${({ theme }) => theme.primaryGray};
  text-transform: none;
  ${fontSize14px18px}
`;

export const ArrowIcon = styled(ArrowForwardIos)`
  color: ${({ theme }) => theme.secondaryBlack};

  @media (max-width: 600px) {
    height: 15px;
    width: 15px;
  }

  @media (min-width: 601px) {
    height: 44px;
    width: 44px;
  }
`;

export const ChevronRight = styled(ChevronRightIcon)<{
  $isDescriptionVisible?: boolean;
}>`
  color: ${({ theme }) => theme.secondaryBlack};

  height: 24px;
  width: 24px;

  @media (max-width: 600px) {
    padding-bottom: ${(props?) =>
      props.$isDescriptionVisible ? '54px' : '0px'};
  }
`;

export const AddIcon = styled(AddCircleOutlineIcon)`
  color: ${({ theme }) => theme.primaryGray};
  height: 24px;
  width: 24px;
`;

export const RefreshIcon = styled(Refresh)`
  color: ${({ theme }) => theme.primaryWhite};
  height: 15px;
  width: 15px;
`;

export const NextIcon = styled(ArrowForwardIos)`
  color: ${({ theme }) => theme.primaryWhite};
  height: 15px;
  width: 15px;
`;
