import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface Props {
  indicatorColour?: string;
  label?: string;
  indicatorSize?: number;
  indicatorThickness?: number;
}

export const LoadingIndicator: React.FC<Props> = ({
  label,
  indicatorColour,
  indicatorSize,
  indicatorThickness,
}) => {
  const theme = useTheme();

  return (
    <Container>
      <Label>{label}</Label>
      <CircularProgress
        aria-label={`${label} progress`}
        role="progressbar"
        variant="indeterminate"
        disableShrink
        size={indicatorSize ? indicatorSize : 30}
        thickness={indicatorThickness ? indicatorThickness : 4}
        style={{
          color: indicatorColour ? indicatorColour : theme.palette.loadingGrey,
          animationDuration: '600ms',
        }}
      />
    </Container>
  );
};

export default LoadingIndicator;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 10px;
`;
