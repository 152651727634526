import React from 'react';
import { baseText } from 'Shared/ButtonStyles';
import InfoIcon from '@material-ui/icons/Info';
import styled, { useTheme } from 'styled-components';
import { Close } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';

export const NoMatchesCard: React.FC<{
  onSelect?: () => void;
  text: string;
  showCard: boolean;
  boldText?: string;
  closable?: boolean;
  onClickCloseCard?: () => void;
}> = ({ text, boldText, closable, showCard, onClickCloseCard }) => {
  const theme = useTheme();
  return (
    <AriaContainer aria-label={boldText ? boldText + text : text}>
      {showCard && (
        <Container>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={9} md={7}>
              <Grid item container direction="column" alignItems="flex-start">
                <IconTextContainer closable={closable}>
                  <IconContainer>
                    <InfoIcon
                      data-testid="infoIcon"
                      fontSize={'large'}
                      htmlColor={theme.palette.yellow}
                    />
                  </IconContainer>
                  <TextContainer>
                    <StyledText>
                      {boldText && <strong>{boldText}</strong>}
                      {text}
                    </StyledText>
                  </TextContainer>
                </IconTextContainer>
              </Grid>
            </Grid>
            <Grid item xs={3} md={5}>
              {closable && (
                <CloseContainer>
                  <IconActionButton
                    onClick={onClickCloseCard}
                    disableRipple
                    disableFocusRipple
                    aria-label="Close notification"
                  >
                    <Close style={{ color: theme.primaryBlack }} />
                  </IconActionButton>
                </CloseContainer>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </AriaContainer>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryWhite};
  width: 100%;

  @media (max-width: 600px) {
    height: 84px;
    min-width: 335px;
  }

  @media (min-width: 601px) {
    height: 90px;
  }
`;

const IconTextContainer = styled.div<{ closable: boolean }>`
  display: flex;
  text-align: left;
  justify-content: left;
  margin-top: ${(props): string => (props.closable ? '' : '20px')};

  @media (max-width: 600px) {
    padding-left: 20px;
  }

  @media (min-width: 601px) {
    padding-left: 30px;
    max-height: 35px;
  }
`;

const AriaContainer = styled.div`
  margin-top: 20px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: left;
  @media (max-width: 600px) {
    width: 26px;
    height: 26px;
  }
  @media (min-width: 601px) {
    width: 32px;
    height: 32px;
  }
`;

const TextContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: left;
  min-width: 260px;
  padding-left: 10px;

  @media (min-width: 601px) {
    padding-top: 10px;
    max-width: 530px;
  }
`;

export const StyledText = styled(baseText)`
  color: ${({ theme }) => theme.primaryGray};
  justify-content: left;

  @media (max-width: 800px) {
    line-height: 20px;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  &:hover {
    background-color: 'transparent';
  }

  @media (max-width: 600px) {
    padding-bottom: 40px;
  }

  @media (min-width: 601px) {
    padding-bottom: 30px;
  }
`;

const IconActionButton = styled(IconButton)`
  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.primary} !important;
  }
`;
