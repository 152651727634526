import styled from 'styled-components';
import React from 'react';
import { RecentUserCard } from '../../Booking/RecentUsers/RecentUserCard';
import BookingIcon, {
  IconStyling,
  WarningTypes,
} from '../../Booking/BookingIcon/BookingIcon';
import { UserModel } from 'Services/MeService';

export interface Props {
  heading: string;
  users: Array<UserModel>;
  isGroupBooking: boolean;
  onNext?: (user: UserModel) => void;
}

const iconStyle: IconStyling = {
  width: '58px',
  height: '56px',
  textSize: '24px',
  mobileWidth: '33px',
  mobileHeight: '32px',
  mobileTextSize: '14px',
};

export const RecentUserList: React.FC<Props> = ({
  heading,
  users,
  onNext,
  isGroupBooking,
}) => {
  return (
    <Container role="main">
      <Title>{heading}</Title>
      <div role="list" aria-label={heading}>
        {users.map((user, i) => (
          <ListItem key={i} role="listitem">
            <RecentUserCard
              label={user.name}
              onSelect={() => onNext(user)}
              isGroupBooking={isGroupBooking}
              alreadyHasDeskBooking={
                isGroupBooking
                  ? !Object.values(user.availability)[0].isAvailable
                  : user.alreadyHasDeskBooking
              }
            >
              <BookingIcon
                user={user}
                status={
                  isGroupBooking &&
                  !Object.values(user.availability)[0].isAvailable
                    ? WarningTypes.WARNING
                    : WarningTypes.DEFAULT
                }
                styling={iconStyle}
              />
            </RecentUserCard>
          </ListItem>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryGray};

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 14px;
    margin-bottom: -6px;
  }
`;

const ListItem = styled.div`
  @media (max-width: 600px) {
    margin-top: 10px;
  }

  @media (min-width: 601px) {
    margin-top: 20px;
  }
`;
