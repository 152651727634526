import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import React from 'react';
import { BookingOptionModel } from 'Services/ConfigService';
import { ReserveBookingContent } from './ReserveBookingContent';
import { UserModel as User } from 'Services/MeService';
import styled from 'styled-components';

export const ReserveBooking: React.FC<{
  typeModel: BookingOptionModel;
  onNext: (result: boolean) => void;
  onBack: () => void;
  user: User;
}> = ({ typeModel, onNext, onBack, ...rest }) => {
  const heading = `Book on behalf of someone: ${typeModel.caption}`;
  useDocumentTitle([heading, 'Reserve Booking']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <ReserveBookingContent typeModel={typeModel} onNext={onNext} {...rest} />
    </BaseContainer>
  );
};

const BaseContainer = styled.div`
  min-width: 100%;
  background-color: ${({ theme }) => theme.background.primary};
  padding-left: 0px;
  padding-right: 0px;
`;
