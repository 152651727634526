import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { BookingType } from './BookingType';
import {
  ArrowIcon,
  BookingOptionButton,
  ChevronRight,
} from 'Shared/ButtonStyles';
import { makeStyles } from '@material-ui/styles';

export const BookingOption: React.FC<{
  caption: string;
  description: string;
  type: BookingType;
  onBookingSelect: (type: BookingType) => void;
  $isDescriptionVisible?: boolean;
}> = ({
  caption,
  description,
  type,
  onBookingSelect,
  children,
  $isDescriptionVisible,
}) => {
  const classes = useStyles({ $isDescriptionVisible });

  const getIcon = ($isDescriptionVisible: boolean): ReactNode => {
    return !$isDescriptionVisible ? (
      <ArrowIcon />
    ) : (
      <ChevronRight $isDescriptionVisible={$isDescriptionVisible} />
    );
  };

  return (
    <BookingOptionButton
      fullWidth
      disableRipple
      disableFocusRipple
      onClick={() => onBookingSelect(type)}
      classes={{ startIcon: classes.startIcon, endIcon: classes.endIcon }}
      startIcon={
        <BookingOptionIconBackground
          $isDescriptionVisible={$isDescriptionVisible}
        >
          {children}
        </BookingOptionIconBackground>
      }
      $isDescriptionVisible={$isDescriptionVisible}
      endIcon={getIcon($isDescriptionVisible)}
    >
      <TextContainer $isDescriptionVisible={$isDescriptionVisible}>
        <TypeText $isDescriptionVisible={$isDescriptionVisible}>
          {caption}
        </TypeText>
        {$isDescriptionVisible && <MessageText>{description}</MessageText>}
      </TextContainer>
    </BookingOptionButton>
  );
};

export interface StyleProps {
  width: string;
  height: string;
}

const useStyles = makeStyles(() => ({
  startIcon: {
    display: 'block',
    '@media (min-width: 601px)': {
      paddingLeft: '39px',
    },
    '@media (max-width: 600px)': {
      paddingLeft: '14px',
    },
  },
  endIcon: {
    '@media (min-width: 601px)': {
      marginRight: '12px',
    },
    '@media (max-width: 600px)': {
      marginRight: '2px',
    },
  },
}));

const BookingOptionIconBackground = styled.span<{
  $isDescriptionVisible?: boolean;
}>`
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: 50px;
  flex: 0 0 content;
  display: inline-block;

  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
    margin-bottom: ${(props) => (props.$isDescriptionVisible ? '40px' : '0px')};
  }

  @media (min-width: 601px) {
    width: 64px;
    height: 64px;
  }
`;

const TextContainer = styled.div<{ $isDescriptionVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const TypeText = styled.span<{ $isDescriptionVisible?: boolean }>`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  flex: 1 1;
  text-align: left;
  padding-left: 12px;
  padding-top: 4px;
  text-transform: none;

  @media (max-width: 600px) {
    font-size: 14px;
    position: ${(props) =>
      props.$isDescriptionVisible ? 'absolute' : 'normal'};
    top: ${(props) => (props.$isDescriptionVisible ? '10px' : '0px')};
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const MessageText = styled.div`
  color: ${({ theme }) => theme.primaryGray};
  text-transform: none;
  padding-left: 12px;
  text-align: left;
  letter-spacing: normal;

  @media (max-width: 600px) {
    font-size: 14px;
    padding-right: 40px;
    position: absolute;
    white-space: wrap;
    line-height: normal;
    top: 45px;
  }

  @media (min-width: 601px) {
    font-size: 14px;
  }
`;
