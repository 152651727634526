import * as React from 'react';
import styled from 'styled-components';

export interface Props {
  message: string;
}

export const UserSelectionWarning: React.FC<Props> = ({ message }) => {
  return (
    <Wrapper>
      <MessageContainer>
        <Message>{message}</Message>
      </MessageContainer>
    </Wrapper>
  );
};

export default UserSelectionWarning;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  letter-spacing: 0.32px;

  @media (max-width: 600px) {
    margin: 20px 0 31px;
    line-height: 16px;
  }

  @media (min-width: 601px) {
    margin: 42px 0 48px;
    line-height: 22px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  text-align: center;

  @media (max-width: 600px) {
    width: 315px;
  }

  @media (min-width: 601px) {
    width: 534px;
  }
`;

const Message = styled.span`
  user-select: none;
  color: ${({ theme }) => theme.palette.warningRed};
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;
