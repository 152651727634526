import React, { useContext } from 'react';
import AppContext from 'AppContext';
import BookingIcon, {
  IconStyling,
  WarningTypes,
} from 'Booking/BookingIcon/BookingIcon';
import styled from 'styled-components';
import { UserModel } from 'Services/MeService';
import { AddIcon } from 'Shared/ButtonStyles';
import { BookingOptionButton } from 'Shared/ButtonStyles';
import { makeStyles } from '@material-ui/styles';

export interface Props {
  onClick?: (data: UserModel) => void;
  alreadyHasDeskBooking?: boolean;
}

const iconStyle: IconStyling = {
  width: '58px',
  height: '56px',
  textSize: '24px',
  mobileWidth: '33px',
  mobileHeight: '32px',
  mobileTextSize: '14px',
};

export const CurrentUser: React.FC<Props> = (props: Props) => {
  const { alreadyHasDeskBooking } = props;
  const { userId, email, name } = useContext(AppContext).loggedInUser;
  const me = {
    userId,
    name: 'You',
    email,
  };

  const handleOnSelect = () => {
    props.onClick && !alreadyHasDeskBooking && props.onClick(me);
  };

  const bookingIcon = (
    <span>
      <BookingIcon
        user={{ userId, email, name }}
        styling={iconStyle}
        status={
          alreadyHasDeskBooking ? WarningTypes.WARNING : WarningTypes.DEFAULT
        }
      />
    </span>
  );

  const classes = useStyles();
  const alreadyHasDeskBookingText = `You already have desk booking`;

  return (
    <BookingOptionButton
      variant="text"
      fullWidth
      disableRipple
      disableFocusRipple
      onClick={() => {
        handleOnSelect();
      }}
      style={{ paddingLeft: '0px' }}
      classes={{
        startIcon: classes.startIcon,
        endIcon: classes.endIcon,
      }}
      startIcon={bookingIcon}
      endIcon={!alreadyHasDeskBooking && <AddIcon />}
      $disabledBackground={alreadyHasDeskBooking}
    >
      {
        <LabelText>
          <div>{`You (${name})`}</div>

          {alreadyHasDeskBooking && (
            <InfoText>{alreadyHasDeskBookingText}</InfoText>
          )}
        </LabelText>
      }
    </BookingOptionButton>
  );
};

const useStyles = makeStyles(() => ({
  startIcon: {
    display: 'block',
    '@media (min-width: 601px)': {
      marginLeft: '21px',
      marginRight: '31px',
    },
    '@media (max-width: 600px)': {
      marginLeft: '13px',
      marginRight: '12px',
    },
  },
  endIcon: {
    display: 'block',
    marginRight: '14px',
    marginTop: '8px',
  },
}));

const LabelText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1;
  text-align: left;
  text-transform: none;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;
const InfoText = styled.div`
  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 16px;
  }
  font-weight: 700;
`;
