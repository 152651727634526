import React from 'react';
import { Container, Divider, Grid, Typography } from '@material-ui/core';
import {
  ComputerRounded,
  GroupWork,
  LocalParking,
  LocationOn,
  TransferWithinAStation,
} from '@material-ui/icons';
import { DateRange } from '@material-ui/icons';
import styled, { css } from 'styled-components';
import { BookingType } from 'Booking/BookingType';
import { BookingOptionModel } from 'Services/ConfigService';
import FeaturesList from 'Shared/FeaturesList/FeaturesList';
import { ResourceFeatureOptions } from 'Services/ResourceService';
import TimeCard from 'components/TimeCard/TimeCard';

export interface Props {
  typeModel: BookingOptionModel;
  dateTime: string;
  resource?: string;
  location: string;
  reservedBy?: string;
  resourceFeatureOptions?: Array<ResourceFeatureOptions>;
  bookingDateObj?: Date;
  bookingDuration?: string;
}

export const ConfirmationCard: React.FC<Props> = (props) => {
  const {
    typeModel,
    dateTime,
    resource,
    location,
    reservedBy,
    resourceFeatureOptions = [],
    bookingDateObj,
    bookingDuration,
  } = props;
  const meetingRoomBooking = typeModel.resourceType === BookingType.Room;

  return (
    <>
      <DivSingleColumn container direction="row" alignItems="center">
        <IconTextContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TypeText role={'heading'} aria-level={3}>
              {typeModel.caption}
            </TypeText>
            {reservedBy && (
              <ReservedContainer>
                <ReservedByText>
                  Reserved by <NameLabel>{reservedBy}</NameLabel>
                </ReservedByText>
              </ReservedContainer>
            )}
          </div>
          <IconStyle>
            <BookingTypeIcon bookingType={typeModel.resourceType} />
          </IconStyle>
        </IconTextContainer>
      </DivSingleColumn>
      <ContainerSingleColumn>
        <Grid container direction="column">
          <BookingDetails container item direction="row" alignItems="center">
            <Grid container item xs={2} xl={1} justifyContent="center">
              <LocationIcon />
            </Grid>
            <Grid item xs={10} xl={11}>
              {resource ? (
                <MessageText style={{ paddingTop: 0, textAlign: 'left' }}>
                  {!meetingRoomBooking
                    ? `${resource}, ${location}`
                    : `${resource},`}
                </MessageText>
              ) : (
                <MessageText style={{ paddingTop: 0, textAlign: 'left' }}>
                  {location}
                </MessageText>
              )}
              {meetingRoomBooking && (
                <Grid>
                  <MessageText style={{ paddingTop: 0, textAlign: 'left' }}>
                    {location}
                  </MessageText>
                </Grid>
              )}
            </Grid>
          </BookingDetails>
          <Grid container item>
            <Divider style={{ width: '100%' }} />
          </Grid>
          {(typeModel.resourceType == BookingType.Desk || meetingRoomBooking) &&
            resourceFeatureOptions.length > 0 && (
              <ResourceFeatureContainer>
                <BookingDetails
                  container
                  item
                  style={{ height: 'auto', paddingLeft: '4px' }}
                >
                  <FeaturesList
                    featureList={resourceFeatureOptions.map(
                      ({
                        resourceFeatureOptionName,
                        resourceFeatureOptionIcon,
                      }) => ({
                        icon: resourceFeatureOptionIcon,
                        title: resourceFeatureOptionName,
                      })
                    )}
                  />
                </BookingDetails>
                <Grid container item>
                  <Divider style={{ width: '100%' }} />
                </Grid>
              </ResourceFeatureContainer>
            )}
          <DateContainer container item direction="row" alignItems="center">
            <DateTime container item xs={2} xl={1} justifyContent="center">
              <CalendarIcon />
            </DateTime>
            <DateTime item xs={10} xl={11}>
              <MessageText style={{ paddingTop: 0, textAlign: 'left' }}>
                {dateTime}
              </MessageText>
            </DateTime>
          </DateContainer>
          {meetingRoomBooking && (
            <TimeCard
              bookingDate={bookingDateObj}
              bookingDuration={bookingDuration}
            />
          )}
        </Grid>
      </ContainerSingleColumn>
    </>
  );
};

export const BookingTypeIcon: React.FC<{
  bookingType: BookingType;
}> = ({ bookingType }) => {
  switch (bookingType) {
    case BookingType.Desk:
      return <DeskIcon />;
    case BookingType.CarSpace:
      return <CarParkIcon />;
    case BookingType.Room:
      return <MeetingRoomIcon />;
    case BookingType.SiteVisit:
      return <SiteVisitIcon />;
    default:
      return null;
  }
};

const DateContainer = styled(Grid)`
  padding-top: 24px;
`;

const DateTime = styled(Grid)`
  padding-bottom: 14px;
`;

const IconStyle = styled.div`
  @media (max-width: 600px) {
    padding-right: 25px;
  }

  @media (min-width: 601px) {
    padding-right: 33px;
  }
`;

const IconTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ReservedContainer = styled.span`
  display: flex;

  @media (max-width: 600px) {
    max-width: 300px;
  }

  @media (min-width: 601px) {
    max-width: 550px;
  }
`;

const NameLabel = styled.span`
  @media (min-width: 601px) {
    font-weight: bold;
  }
`;

const ReservedByText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 600px) {
    font-size: 12px;
    padding-left: 15px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
    padding-left: 30px;
  }
`;

const ResourceFeatureContainer = styled.div`
  padding-top: 10px;
`;

const ButtonText = styled(Typography)`
  text-transform: none;
`;

const TypeText = styled(Typography)`
  font-weight: bold;

  @media (max-width: 600px) {
    font-size: 14px;
    padding-left: 15px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
    padding-left: 30px;
  }
`;

const MessageText = styled(ButtonText)`
  width: 100%;

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const iconSize = `  
  @media (max-width: 600px) {
    height: 24px;
    width: 24px;
  }

  @media (min-width: 601px) {
    height: 48px;
    width: 48px;
  }`;

const LocationIcon = styled(LocationOn)`
  ${iconSize}
`;

const CalendarIcon = styled(DateRange)`
  ${iconSize}
`;

const ContainerSingleColumn = styled(Container)`
  background-color: ${({ theme }) => theme.primaryWhite} !important;
  border-radius: 5px;

  @media (max-width: 600px) {
    min-width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (min-width: 600px) {
    min-width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const rowHeight = `
  @media (max-width: 600px) {
    height: 64px;
  }

  @media (min-width: 601px) {
    height: 105px;
  }`;

const DivSingleColumn = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary} !important;
  color: ${({ theme }) => theme.primaryWhite} !important;
  border-radius: 4px 4px 0px 0px;

  ${rowHeight}
`;

const BookingDetails = styled(Grid)`
  ${rowHeight}
`;

const IconBaseStyle = css`
  ${iconSize}
  color: ${({ theme }) => theme.primaryWhite};

  /* @media (max-width: 600px) {
    height: 36px;
    width: 36px;
  }

  @media (min-width: 601px) {
    height: 48px;
    width: 48px;
  } */
`;

const DeskIcon = styled(ComputerRounded)`
  ${IconBaseStyle}
`;

const MeetingRoomIcon = styled(GroupWork)`
  ${IconBaseStyle}
`;

const SiteVisitIcon = styled(TransferWithinAStation)`
  ${IconBaseStyle}
`;

const CarParkIcon = styled(LocalParking)`
  ${IconBaseStyle}
`;
