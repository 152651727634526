import styled from 'styled-components';
import React, { ChangeEvent, useState } from 'react';
import { FormControl } from '@material-ui/core';
import { FilterItem } from 'Booking/Filter/FilterItem/FilterItem';
import { HintText } from '../PreferenceContent';
import { FilterModel } from 'Services/ResourceService';

export interface Props {
  featureOptions: FilterModel[];
  handleSelection: (value: FilterModel[]) => void;
}

export const PreferenceList: React.FC<Props> = ({
  handleSelection,
  featureOptions,
}) => {
  const [featureOptionList, setFeatureOptionList] = useState(featureOptions);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    const { checked } = e.target;
    const featureList = JSON.parse(JSON.stringify(featureOptionList));
    featureList[i].isDefaultSelected = checked;
    setFeatureOptionList(featureList);
    handleSelection(featureList);
  };

  return (
    <Wrapper>
      <HintText>Required features</HintText>

      <Container role="list" aria-label="Choose feature options">
        {featureOptionList.map((filter, i) => (
          <ListItem role="listitem" key={i}>
            <StyledFormControl component="fieldset">
              <FilterItem
                name={`${filter.resourceAttributeId}`}
                value={`${filter.resourceAttributeId}`}
                label={filter.resourceAttributeName}
                onChange={(e) => handleChange(e, i)}
                checked={filter.isDefaultSelected}
                icon={filter.resourceAttributeIcon}
                type="checkbox"
              />
            </StyledFormControl>
          </ListItem>
        ))}
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    margin-top: 17px;
  }
`;

const Wrapper = styled.div`
  margin-top: 60px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const StyledFormControl = styled(FormControl)`
  margin-top: 0;
` as typeof FormControl;

const ListItem = styled.div``;
