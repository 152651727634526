import React from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import InfoIcon from '@material-ui/icons/Info';
import styled, { useTheme } from 'styled-components';
import { baseText } from '../Shared/ButtonStyles';
import { useMediaQuery, useTheme as ut } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { BookingType } from './BookingType';

export const AvailabilityText = styled(baseText)`
  color: ${({ theme }) => theme.primaryBlack};
  padding-left: 11px;
`;

export const InfoIconLayout = styled.span`
  display: flex;
`;

const TooltipHeader = styled.span`
  padding-left: 20px;
  padding-top: 20px;
  font-size: 18px;
  font-weight: bold;
`;

const TooltipBody = styled.span`
  padding-left: 20px;
  padding-top: 5px;
  font-size: 16px;
`;

const IconTextContainer = styled.span`
  display: flex;
`;

const IconContainer = styled.span`
  display: flex;
  align-items: center;
`;

const TextContainer = styled.span`
  display: flex;
  align-items: center;
  text-align: left;
`;

export enum Availability {
  AVAILABLE = 'Available',
  LIMITED = 'Limited availability',
  NOTAVAILABLE = 'No availability',
  ANOTHERBOOKING = 'You already have a booking',
  NOMATCHES = 'No matches',
  MAXSITEVISITS = 'Maximum number of site visits reached for this day.',
  ANOTHERSITEBOOKING = 'You already have a booking for this building',
  BOBOANOTHERBOOKING = 'Already has a booking',
  OVERFLOWPARKINGONLY = 'Overflow Parking Only',
}

export const AvailabilityIconText: React.FC<{
  availability: Availability;
  toolTip?: boolean;
  bookingFlowType?: number;
  guestUser?: boolean;
}> = ({ availability, toolTip, bookingFlowType, guestUser }) => {
  type AvailabilityDictionary<T extends string, U> = {
    [K in T]: U;
  };
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:600px)');

  let tooltipHeader = '';
  let tooltipBody = '';

  const BookedTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: theme.primaryWhite,
      color: theme.primaryBlack,
      maxWidth: bookingFlowType === BookingType.CarSpace ? 384 : 321,
      height: bookingFlowType === BookingType.CarSpace ? 150 : 120,
      border: '1px solid ' + theme.palette.borderGray,
      lineHeight: '25px',
    },
    arrow: {
      fontSize: 15,
      width: 18,
      '&::before': {
        border: '1px solid ' + theme.palette.borderGray,
        backgroundColor: theme.primaryWhite,
        boxSizing: 'border-box',
      },
    },
  }))(Tooltip);

  const updateTooltipType = (bookingFlowType: number, isGuestUser = false) => {
    let flowTypeHeader = '';
    let flowTypeBody = '';

    if (bookingFlowType === BookingType.Desk) {
      flowTypeHeader = 'Desk';
      flowTypeBody = 'desk';
    }

    if (bookingFlowType === BookingType.SiteVisit) {
      flowTypeHeader = 'Site';
      flowTypeBody = 'site';
    }

    // Set tooltip for Desk and SiteVisit
    // CarSpace and BOBO Desk has different messages
    tooltipHeader = `${flowTypeHeader} already booked`;
    tooltipBody = `To book a different ${flowTypeBody} on this day, please cancel your existing booking.`;

    if (bookingFlowType === BookingType.Desk && isGuestUser) {
      tooltipHeader = 'Only one desk booking per day';
      tooltipBody =
        'The person you are booking for has a desk currently booked on this day';
    }

    if (bookingFlowType === BookingType.CarSpace) {
      tooltipHeader = 'Car Park already booked';
      tooltipBody =
        'You can only have one car park booking per day. You can cancel your existing booking to book another one, depending on the availability.';
    }

    return true;
  };

  const dict: AvailabilityDictionary<Availability, JSX.Element> = {
    [Availability.AVAILABLE]: (
      <CheckCircleRoundedIcon
        data-testid="checkCircle"
        fontSize={matches ? 'small' : 'medium'}
        htmlColor={theme.palette.greenLight}
      />
    ),
    [Availability.LIMITED]: (
      <InfoRoundedIcon
        fontSize={matches ? 'small' : 'medium'}
        htmlColor={theme.palette.yellow}
      />
    ),
    [Availability.NOMATCHES]: (
      <InfoRoundedIcon
        data-testid="infoRounded"
        fontSize={matches ? 'small' : 'medium'}
        htmlColor={theme.palette.yellow}
      />
    ),
    [Availability.NOTAVAILABLE]: (
      <CancelRoundedIcon
        data-testid="cancelRounded"
        fontSize={matches ? 'small' : 'medium'}
        htmlColor={theme.palette.red}
      />
    ),
    [Availability.ANOTHERBOOKING]: (
      <span
        aria-label={
          'Desk already booked. To book a different desk on this day, please cancel your existing booking.'
        }
      >
        {updateTooltipType(bookingFlowType, guestUser) && (
          <BookedTooltip
            title={
              availability == Availability.ANOTHERBOOKING ? (
                <React.Fragment>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <TooltipHeader>{tooltipHeader}</TooltipHeader>
                    <TooltipBody>{tooltipBody}</TooltipBody>
                  </span>
                </React.Fragment>
              ) : (
                ''
              )
            }
            arrow
            placement={'top-start'}
            open={toolTip}
          >
            <InfoIconLayout>
              <InfoIcon
                data-testid="infoIcon"
                fontSize={matches ? 'small' : 'medium'}
                htmlColor={theme.palette.primary}
              />
            </InfoIconLayout>
          </BookedTooltip>
        )}
      </span>
    ),
    [Availability.MAXSITEVISITS]: (
      <ErrorIcon
        data-testid="ErrorIcon"
        fontSize={matches ? 'small' : 'medium'}
        htmlColor={theme.palette.red}
      />
    ),
    [Availability.ANOTHERSITEBOOKING]: (
      <ErrorIcon
        data-testid="ErrorIcon"
        fontSize={matches ? 'small' : 'medium'}
        htmlColor={theme.palette.red}
      />
    ),
    [Availability.BOBOANOTHERBOOKING]: (
      <span
        aria-label={
          'The person you are booking for has a desk currently booked on this day'
        }
      >
        {updateTooltipType(bookingFlowType, guestUser) && (
          <BookedTooltip
            title={
              availability == Availability.BOBOANOTHERBOOKING ? (
                <React.Fragment>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <TooltipHeader>{tooltipHeader}</TooltipHeader>
                    <TooltipBody>{tooltipBody}</TooltipBody>
                  </span>
                </React.Fragment>
              ) : (
                ''
              )
            }
            arrow
            placement={'top-start'}
            open={toolTip}
          >
            <InfoIconLayout>
              <InfoIcon
                data-testid="infoIcon"
                fontSize={matches ? 'small' : 'medium'}
                htmlColor={theme.palette.primary}
              />
            </InfoIconLayout>
          </BookedTooltip>
        )}
      </span>
    ),
    [Availability.OVERFLOWPARKINGONLY]: (
      <ErrorIcon
        data-testid="ErrorIcon"
        fontSize={matches ? 'small' : 'medium'}
        htmlColor={theme.palette.yellow}
      />
    ),
  };
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));
  return (
    <IconTextContainer>
      <IconContainer>{dict[availability]}</IconContainer>
      <TextContainer>
        <AvailabilityText style={{ fontSize: isMobileView ? '12px' : '16px' }}>
          {availability}
        </AvailabilityText>
      </TextContainer>
    </IconTextContainer>
  );
};
