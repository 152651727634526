import React, { useContext, useState } from 'react';
import { BookingViewStep } from '../BookingSteps';
import { useHistory } from 'react-router-dom';
import { UserBooking } from './UserBooking';
import AppContext from 'AppContext';
import { BookingViewParam } from 'Booking/UserBookingsContent';
import FlowContext from 'Booking/FlowContext';
import { FlowStep } from 'Booking/FlowStep';
import { DoneCancel } from './DoneCancel';
import { FloorPlan } from 'Booking/FloorPlan/FloorPlan';

export interface BookingViewStepData {
  currentStep: BookingViewStep;
  pastSteps: BookingViewStep[];
}

export const BookingViewFlow: React.FC = () => {
  const history = useHistory<BookingViewParam>();

  const appContext = useContext(AppContext);
  const type = appContext.bookingOptions.find(
    (x) => x.resourceType === history.location.state.type
  );

  const [stepData, setStepData] = useState<BookingViewStepData>({
    currentStep: BookingViewStep.ViewDetails,
    pastSteps: [],
  });
  const [cancelResult, setCancelResult] = useState(false);
  const [img, setImg] = useState<{ imageSrc: string; image: string }>(null);
  const updateStepData = (nextStep?: BookingViewStep) => {
    if (nextStep === undefined || nextStep === null) {
      const previousStep = stepData.pastSteps.pop();
      if (previousStep === undefined) {
        history.push('/'); //no previous step, so go to home page.
      } else {
        setStepData({
          currentStep: previousStep,
          pastSteps: stepData.pastSteps,
        });
      }
    } else {
      stepData.pastSteps.push(stepData.currentStep);
      setStepData({ currentStep: nextStep, pastSteps: stepData.pastSteps });
    }
  };

  const handleCancel = (result: boolean) => {
    setCancelResult(result);
    updateStepData(BookingViewStep.DoneCancellation);
  };

  const handleBack = () => {
    updateStepData();
  };

  const onViewFloorPlan = (img) => {
    setImg(img);
    updateStepData(BookingViewStep.FloorPlan);
  };

  return (
    <FlowContext.Provider
      value={{
        currentStep: stepData.currentStep,
      }}
    >
      <>
        <FlowStep step={BookingViewStep.ViewDetails}>
          <UserBooking
            bookingId={history.location.state.bookingId}
            typeModel={type}
            onNext={handleCancel}
            onBack={handleBack}
            onViewFloorPlan={onViewFloorPlan}
          />
        </FlowStep>
        <FlowStep step={BookingViewStep.DoneCancellation}>
          <DoneCancel cancelResult={cancelResult} onBack={handleBack} />
        </FlowStep>

        <FlowStep step={BookingViewStep.FloorPlan}>
          <FloorPlan onGoBack={handleBack} floorPlan={img} />
        </FlowStep>
      </>
    </FlowContext.Provider>
  );
};
