import * as React from 'react';
import styled from 'styled-components';

export interface Props {
  label?: string;
}

export const EnvironmentFooter: React.FC<Props> = ({ label }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
    </Wrapper>
  );
};

export default EnvironmentFooter;

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.primaryGray};
`;
