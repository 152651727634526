import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useTheme } from 'styled-components';
import { ActionButton } from './ActionButton';

export const ShowMoreButton: React.FC<{
  showingLess: boolean;
  handleExpand: () => void;
  text: string;
  width: number;
}> = ({ showingLess, handleExpand, text, width }) => {
  const theme = useTheme();
  return (
    <ActionButton
      style={{
        backgroundColor: theme.palette.secondary,
        width: width,
      }}
      onClick={handleExpand}
      text={text}
    >
      {showingLess ? <ExpandMore /> : <ExpandLess />}
    </ActionButton>
  );
};
