import React, { useContext, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Title } from 'Shared/Title';
import { ContainerSingleColumn } from 'Shared/ContainerSingleColumn';
import { NextButton } from 'Shared/NextButton';
import { BookingModel } from 'Services/BookingService';
import EditFilter from 'Booking/Filter/EditFilter';
import FlowContext from 'Booking/FlowContext';
import {
  FilterModel,
  MeetingRoomAvailabilityModel,
  getAvailableMeetingRooms,
  ResourceAvailabilityModel,
  ResourceFeatureOptions,
} from 'Services/ResourceService';
import { useMediaQuery, useTheme as ut } from '@material-ui/core';
import { ResourceCard } from 'Shared/ResourceCard/ResourceCard';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import { WarningCard } from 'components/WarningCard/WarningCard';
import { LoadingIndicator } from 'components/LoadingIndicator/LoadingIndicator';
import { BookingType } from 'Booking/BookingType';
import { getPersonInfo } from 'Helpers/Utils';
import InfoIcon from '@material-ui/icons/Info';
import { StyledErrorIcon } from 'Error/ErrorCard';

interface Props {
  onNext: (bookingModel?: BookingModel) => void;
  onEdit: () => void;
}

export const SelectRoomContent: React.FC<Props> = ({ onNext, onEdit }) => {
  const bookingModel: BookingModel = useContext(FlowContext).bookingModel;

  /* Selected AttributeIds */
  const filterId: Array<FilterModel['resourceAttributeId']> = [];
  bookingModel.filter.forEach(
    (resourceFeature) =>
      resourceFeature.isDefaultSelected &&
      filterId.push(resourceFeature.resourceAttributeId)
  );

  const [availableMeetingRooms, setAvailableMeetingRooms] = useState<
    ResourceAvailabilityModel[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>(false);
  const [isShowMoreFailed, setIsShowMoreFailed] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  /* Pagination */
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  /* No matches */
  const [noRecords, setNoRecords] = useState(false);
  const [noMatches, setNoMatches] = useState(false);

  /* Hide show more button */
  const [hideShowMore, setHideShowMore] = useState(false);

  /* Edit Preferences */
  const preferencesList: Array<FilterModel['resourceAttributeName']> = [];
  preferencesList.push(`${bookingModel.seatingCapacity} Person`);
  bookingModel.filter.forEach(
    ({ isDefaultSelected, resourceAttributeName }) => {
      isDefaultSelected && preferencesList.push(resourceAttributeName);
    }
  );

  /* Info text to be displayed for no data/no matching records */
  const getInfoText = () => {
    return noRecords
      ? 'No results found for your selected day, time or location.'
      : 'No matches found for your selected meeting room features. Showing other meeting rooms that are available at the chosen location. ';
  };

  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));
  const theme = useTheme();

  /* Generate query string */
  const getParams = () => ({
    buildingId: bookingModel.buildingId,
    floorId: bookingModel.floorId,
    resourceType: BookingType.Room,
    meetingRoomCapacity: bookingModel.seatingCapacity,
    selectedAttributeId: filterId.join(','),
    startDate: bookingModel.bookingStartDate,
    endDate: bookingModel.bookingEndDate,
    imageFormat: 'SVG',
    page,
    restrictedResourcesOnly: false,
    afterNoMatch: false,
  });

  const setData = (res: MeetingRoomAvailabilityModel) => {
    if (page === 1 && (res === null || !res.resources.length))
      setNoRecords(true);
    else {
      /* Do not show show more button if fewer than 4 are returned */
      if (res.resources.length < 4) setHideShowMore(true);
      /* Append seating capacity to feature options */
      res.resources.map(
        ({
          seatingCapacity,
          resourceFeatureOptions = [] as ResourceFeatureOptions[],
        }) => {
          seatingCapacity &&
            resourceFeatureOptions?.splice(
              0,
              0,
              getPersonInfo(seatingCapacity)
            );
        }
      );

      if (res.roomNoMatch) setNoMatches(true);

      setAvailableMeetingRooms([...availableMeetingRooms, ...res.resources]);
    }
  };

  useEffect(() => {
    page === 1 && setLoading(true);

    getAvailableMeetingRooms(getParams())
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        page === 1 ? setIsLoadingFailed(true) : setIsShowMoreFailed(true);
        setShowLoader(false);
      })
      .finally(() => {
        setLoading(false);
        setShowLoader(false);
      });
  }, [pageCount, retryCount]);

  const handleShowMore = () => {
    /* Keep the current page number if show more API fails*/
    const pageIncrement = isShowMoreFailed ? 0 : 1;
    setPage(page + pageIncrement);
    setIsShowMoreFailed(false);
    setShowLoader(true);
    setPageCount(pageCount + 1);
  };

  if (loading) {
    return <LoadingWholePage />;
  }

  if (isLoadingFailed) {
    return (
      <ErrorWholePage
        message="Sorry, we couldn’t load this page."
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }

  return (
    <ContainerSingleColumn role="main">
      <Title text="Choose a meeting room" />

      <EditFilter
        label="Preferences"
        onEdit={onEdit}
        selectedList={preferencesList}
      />

      {(noRecords || noMatches) && (
        <WarningCard text={getInfoText()}>
          <InfoIcon fontSize={'medium'} htmlColor={theme.palette.orange} />
        </WarningCard>
      )}

      <CardWrapper role="list">
        {!!availableMeetingRooms.length &&
          availableMeetingRooms.map((meetingRoom, i) => {
            return (
              <ResourceCard
                key={i}
                level={bookingModel.floorName}
                resource={meetingRoom}
                style={{
                  paddingLeft: isMobileView ? '18px' : '30px',
                  paddingTop: isMobileView ? '16px' : '24px',
                  paddingBottom: '17px',
                  minHeight: isMobileView ? '48px' : '64px',
                }}
                onSelect={() => {
                  onNext({
                    resourceId: meetingRoom.resourceId,
                    resourceName: meetingRoom.name,
                    resourceFeatureOptions: meetingRoom.resourceFeatureOptions,
                  });
                }}
              />
            );
          })}
      </CardWrapper>

      {isShowMoreFailed && (
        <WarningCard
          width="100%"
          text="There was a problem trying to show more rooms. Please try again."
        >
          <StyledErrorIcon />
        </WarningCard>
      )}

      <ButtonContainer>
        {showLoader ? (
          <LoadingIndicator label="Checking available meeting rooms..." />
        ) : (
          !noRecords &&
          !hideShowMore && (
            <NextButton
              text="Show more rooms"
              width={220}
              onClick={handleShowMore}
            />
          )
        )}
      </ButtonContainer>
    </ContainerSingleColumn>
  );
};

const ButtonContainer = styled(ContainerSingleColumn)`
  padding-top: 25px;
  text-align: center;
  @media (max-width: 600px) {
    padding-top: 35px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    padding-top: 0;
  }

  > * {
    margin-top: 20px;

    @media (max-width: 600px) {
      margin-top: 10px;
    }
  }
`;
