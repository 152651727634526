import { BookingStepHeader } from 'Booking/BookingStepHeader';
import React from 'react';
import { BaseContainer } from 'Shared/BaseContainer';
import { CompletionContent } from 'Booking/Completion/CompletionContent';
import { OperationType } from 'Booking/Completion/OperationType';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BookingOptionModel } from 'Services/ConfigService';
import { BookingType } from 'Booking/BookingType';

export const DoneBooking: React.FC<{
  type: string;
  typeModel?: BookingOptionModel;
  bookingResult: boolean;
  onBack: () => void;
}> = ({ type, bookingResult, onBack, typeModel }) => {
  const heading =
    typeModel?.resourceType === BookingType.Room && bookingResult
      ? 'Booking Requested'
      : `Book a ${type}`;
  useDocumentTitle([heading, 'Booking Complete']);

  return (
    <BaseContainer>
      <BookingStepHeader
        heading={heading}
        onBack={onBack}
        isBackButtonHidden={true}
      />
      <CompletionContent
        isOperationSuccessful={bookingResult}
        operationType={OperationType.CONFIRM}
        typeModel={typeModel}
      />
    </BaseContainer>
  );
};
