import React from 'react';
import { Grid } from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import styled from 'styled-components';
import {
  AddressText,
  ButtonIconSize,
  DisableableOption,
} from 'Shared/ButtonStyles';
import { AvailabilityIconText, Availability } from '../Availability';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useMediaQuery } from '@material-ui/core';

const FloorIcon = styled(LayersIcon)`
  color: ${({ theme }) => theme.primaryBlack};
  ${ButtonIconSize}
`;

export const FloorCard: React.FC<{
  floorName: string;
  floorId: number;
  availability: Availability;
  onSelect: () => void;
}> = ({ floorName, floorId, availability, onSelect }) => {
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <Container tabIndex={availability === Availability.AVAILABLE ? null : 0}>
      <DisableableOption
        variant="text"
        fullWidth
        disableRipple
        disableFocusRipple
        disabled={availability === Availability.NOTAVAILABLE ? true : false}
        onClick={() => onSelect()}
        key={floorId}
      >
        <Grid component="span" container direction="row" alignItems="center">
          <Grid component="span" item xs={2} md={2}>
            <Grid component="span" container justifyContent="center">
              <FloorIcon data-testid="floorIcon" />
            </Grid>
          </Grid>
          <Grid
            component="span"
            container
            item
            xs={3}
            md={3}
            alignContent="flex-start"
          >
            <AddressText>{floorName}</AddressText>
          </Grid>
          <Grid
            component="span"
            justifyContent="flex-start"
            container
            item
            xs={6}
            md={6}
          >
            <AvailabilityIconText availability={availability} />
          </Grid>
          <Grid
            component="span"
            container
            item
            xs={1}
            md={1}
            alignItems="center"
          >
            {availability === Availability.NOTAVAILABLE ? null : (
              <ChevronRightIcon
                fontSize={matches ? 'small' : 'medium'}
                data-testid="chevron"
              />
            )}
          </Grid>
        </Grid>
      </DisableableOption>
    </Container>
  );
};

const Container = styled.div``;
