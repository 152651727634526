import React from 'react';
import { Container, Grid, Link, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Error, Launch } from '@material-ui/icons';
import { BookingType } from 'Booking/BookingType';
import { GridSingleColumn } from 'Shared/GridSingleColumn';

const MessageText = styled(Typography)`
  color: ${({ theme }) => theme.secondaryBlack};
  text-transform: none;
  font-size: 18px;
`;

const FailIcon = styled(Error)`
  color: ${({ theme }) => theme.palette.redLight};
  height: 48px;
  width: 48px;
`;

const ContainerSingleColumn = styled(Container)`
  background-color: ${({ theme }) => theme.primaryWhite} !important;
  border-radius: 4px;
`;

export const NoAvailabiltyErrorCard: React.FC<{
  bookingType: BookingType;
}> = ({ bookingType }) => {
  const getMessageText = () => {
    switch (bookingType) {
      case BookingType.CarSpace:
        return [
          'Sorry, the car park you are trying to book is fully booked on your requested day.',
        ];
      case BookingType.Desk:
        return [
          'Sorry, there are no desks available for the selected level.',
          'Please select another level.',
        ];
    }
    return [''];
  };
  return (
    <Grid container justifyContent="center">
      <GridSingleColumn>
        <ContainerSingleColumn>
          <Grid container direction="column" alignItems="center">
            <Grid item style={{ paddingTop: 30 }}>
              <MessageText>
                <FailIcon />
              </MessageText>
            </Grid>
            <Grid item style={{ paddingTop: 10, paddingBottom: 40 }}>
              {getMessageText().map((text, i) => {
                return (
                  <MessageText key={i} style={{ textAlign: 'center' }}>
                    {text}
                  </MessageText>
                );
              })}
              {bookingType === BookingType.CarSpace && (
                <MessageText style={{ textAlign: 'center' }}>
                  <Link
                    target="_blank"
                    href="https://apps.powerapps.com/play/f2902581-f154-4a55-9090-8da6416caf3d?tenantId=49dfc6a3-5fb7-49f4-adea-c54e725bb854&redirected=true"
                  >
                    Find out how you can book an overflow car park <Launch />
                  </Link>
                </MessageText>
              )}
            </Grid>
          </Grid>
        </ContainerSingleColumn>
      </GridSingleColumn>
    </Grid>
  );
};
