import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import React from 'react';
import { BookingOptionModel } from 'Services/ConfigService';
import { BaseContainer } from 'Shared/BaseContainer';
import { AcceptReservedBookingContent } from './AcceptReservedBookingContent';

export const AcceptReservedBooking: React.FC<{
  bookingId: number;
  typeModel: BookingOptionModel;
  onConfirm: (confirm: boolean) => void;
  onCancel: (cancelResult: boolean) => void;
  onBack: () => void;
}> = ({ bookingId, typeModel, onConfirm, onCancel, onBack }) => {
  const heading = `Reserved ${typeModel.caption}`;
  useDocumentTitle([heading, 'Your Booking']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <AcceptReservedBookingContent
        bookingId={bookingId}
        typeModel={typeModel}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </BaseContainer>
  );
};
