import BookingIcon, { WarningTypes } from '../../BookingIcon/BookingIcon';
import React, { Fragment, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { UserModel } from 'Services/MeService';
import { AddIcon } from 'Shared/ButtonStyles';
import { SearchPrompt } from './SearchUserContent';

export interface Props {
  search: string;
  highlight: boolean;
  isUserAlreadySelected?: boolean;
  alreadyHasDeskBooking?: boolean;
  initials?: string;
  userId?: UserModel['userId'];
  name?: UserModel['name'];
  email?: UserModel['email'];
  children?: React.ReactNode;
  groupName?: string;
  onClick?: (event: MouseEvent) => void;
}

/**
 * Common regular expressions for user search text input
 *
 * @member removable Cleanup pattern from impurities
 * @member repeating Repeating characters to replace
 * @member separator Name separators
 */
export const Filters = {
  removable: /[`~!#$%^&*()_=+[{\]}\\|;:"<>/?]/g,
  repeating: /(\s|,|-|'|\.|@)+/g,
  separator: /\s|,/,
  replaceWithSpace: /[,]/g,
};

export const SearchOutcomes: React.FC<Props> = (props: Props) => {
  const { isUserAlreadySelected, alreadyHasDeskBooking } = props;
  // Highlight text
  const searchBolding = (
    needle: string,
    haystack: string,
    shouldHighlight: boolean
  ) => {
    let highlightedText: React.ReactNode = <>{haystack}</>;
    // @reference https://stackoverflow.com/a/48950887
    if (needle && needle.length > 0 && shouldHighlight) {
      // Breakdown keywords in to an array
      const keywords = needle
        .replace(Filters.removable, '')
        .replace(Filters.repeating, '$1')
        .replace(Filters.replaceWithSpace, ' ')
        .split(Filters.separator)
        .sort()
        .reverse()
        .filter((f) => f !== '' && f.length > 1);

      // Join them and create a RegEx. E.g.: /^(foo|bar|baz)/g
      const pattern = new RegExp(`\\b(${keywords.join('|')})`, 'gi');

      // Match keywords in search string
      const rest = haystack.split(pattern).filter(Boolean);
      const match = haystack.match(pattern) || [];

      highlightedText = rest.map((subString: string, index: number) =>
        match.includes(subString) ? (
          <Fragment key={index}>
            <mark>{subString}</mark>
          </Fragment>
        ) : (
          <Fragment key={index}>{subString}</Fragment>
        )
      );
    }

    return <NameTextField>{highlightedText}</NameTextField>;
  };
  return (
    <WrapperButton
      role="listitem"
      data-group-name={props.groupName}
      onClick={props.onClick}
      isUserAlreadySelected={isUserAlreadySelected}
    >
      <Wrapper>
        {props.initials && (
          <IconWrapper>
            <BookingIcon
              status={
                (isUserAlreadySelected || alreadyHasDeskBooking) &&
                WarningTypes.WARNING
              }
              user={{
                userId: props.userId,
                name: props.name,
                email: props.email,
              }}
            />
          </IconWrapper>
        )}
        <TextContainer
          disabled={isUserAlreadySelected || alreadyHasDeskBooking}
          aria-label={`${props.name} (${props.email})`}
        >
          {props.name &&
            searchBolding(props.search, props.name, props.highlight)}
          {props.email && <EmailTextField>{props.email}</EmailTextField>}
        </TextContainer>
        {!Object.values<string>(SearchPrompt)?.includes(props.name) &&
          !(isUserAlreadySelected || alreadyHasDeskBooking) && (
            <IconContainer>
              <AddIcon fontSize="medium" />
            </IconContainer>
          )}
      </Wrapper>
      {alreadyHasDeskBooking && (
        <WarningContainer>
          <DeskBookingWarning>Already has desk booking</DeskBookingWarning>
        </WarningContainer>
      )}
      {isUserAlreadySelected && (
        <WarningContainer>
          <UserAlreadySelectedWarning>
            Already part of this booking
          </UserAlreadySelectedWarning>
        </WarningContainer>
      )}
    </WrapperButton>
  );
};

const WrapperButton = styled.button<{ isUserAlreadySelected: boolean }>`
  cursor: ${(props) => (props.isUserAlreadySelected ? 'unset' : 'pointer')};
  box-sizing: border-box;
  background: ${({ theme }) => theme.primaryWhite};
  border: 1px solid ${({ theme }) => theme.palette.lightGray};
  width: 100%;
  min-height: 50px;
`;

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
`;

const WarningContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;

  @media (max-width: 600px) {
    margin: -6px 0 12px 99px;
  }

  @media (min-width: 600px) {
    margin: -6px 0 12px 102px;
  }
`;

const TextContainer = styled.span<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.textGray : theme.primaryBlack};
  text-align: left;
  line-height: 16px;
  letter-spacing: 0.32px;
  flex-basis: 95%;
  min-width: 0;
`;

const BoldText = css`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary};
  background-color: transparent;
`;

const NameTextField = styled.span`
  padding-left: 20px;
  font-size: 18px;
  margin: 0px 0px -2px 0px;
  padding-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  mark {
    ${BoldText};
  }
`;

const EmailTextField = styled.span`
  padding: 5px 0px 0px 20px;
  font-size: 16px;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WarningTextStyle = css`
  font-size: 16px;
  margin: 0px;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

const DeskBookingWarning = styled.span`
  ${WarningTextStyle}
  color: ${({ theme }) => theme.palette.errorRed};
`;

const UserAlreadySelectedWarning = styled.span`
  ${WarningTextStyle}
  color: ${({ theme }) => theme.palette.warningBrown};
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-left: 24px;
`;

const IconContainer = styled.div`
  display: flex;
  padding-right: 23px;
`;
