import React from 'react';
import { baseText } from 'Shared/ButtonStyles';
import InfoIcon from '@material-ui/icons/Info';
import styled, { useTheme } from 'styled-components';

export const WarningCard: React.FC<{
  onSelect?: () => void;
  text: string;
  iconColour?: string;
}> = ({ text, iconColour }) => {
  const theme = useTheme();
  return (
    <Container aria-labelledby={text} aria-live="assertive">
      <IconTextContainer>
        <IconContainer>
          <InfoIcon
            data-testid="infoIcon"
            fontSize={'medium'}
            htmlColor={iconColour ? iconColour : theme.palette.red}
          />
        </IconContainer>
        <TextContainer>
          <StyledText>{text}</StyledText>
        </TextContainer>
      </IconTextContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryWhite};
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 20px;

  @media (max-width: 600px) {
    height: 62px;
  }

  @media (min-width: 601px) {
    max-width: 630px;
    height: 64px;
  }
`;

const IconTextContainer = styled.div`
  display: flex;
  text-align: left;
  justify-content: left;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @media (max-width: 600px) {
    padding-left: 20px;
  }

  @media (min-width: 601px) {
    padding-left: 30px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: left;
`;

const TextContainer = styled.span`
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
`;

export const StyledText = styled(baseText)`
  color: ${({ theme }) => theme.primaryGray};
  justify-content: left;
`;
