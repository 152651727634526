import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { FloorContent } from './FloorContent';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BookingType } from 'Booking/BookingType';
import { generateFlowHeader } from 'Helpers/Utils';
import { FloorAvailabilityModel } from 'Services/ResourceService';
import { UserModel } from 'Services/MeService';

export const SelectFloor: React.FC<{
  type: string;
  onNext: (floorModel?: FloorAvailabilityModel) => void;
  onBack: () => void;
  onEdit?: () => void;
  bookingHeaderType?: BookingType;
  isGroupBooking?: boolean;
  users?: UserModel[];
  maxUserCount?: number;
}> = ({
  type,
  onNext,
  onBack,
  onEdit,
  bookingHeaderType,
  isGroupBooking,
  users,
}) => {
  const heading = generateFlowHeader(bookingHeaderType, type);
  useDocumentTitle([heading, 'Select Floor']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <FloorContent
        onNext={onNext}
        type={type}
        onEdit={onEdit}
        isGroupBooking={isGroupBooking}
        users={users}
      />
    </BaseContainer>
  );
};
