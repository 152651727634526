import React from 'react';
import { baseText } from 'Shared/ButtonStyles';
import styled from 'styled-components';

export const WarningCard: React.FC<{
  text: string;
  children: React.ReactNode;
  width?: string;
}> = ({ text, children, width }) => {
  return (
    <AriaContainer role="main" aria-label={text}>
      <Container>
        <IconTextContainer>
          <IconContainer>{children}</IconContainer>
          <TextContainer width={width}>
            <StyledText>{text}</StyledText>
          </TextContainer>
        </IconTextContainer>
      </Container>
    </AriaContainer>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryWhite};
  padding: 20px;
`;

const IconTextContainer = styled.div`
  display: flex;
`;

const AriaContainer = styled.div`
  margin-top: 20px;

  @media (max-width: 600px) {
    margin-top: 13px;
  }
`;

const TextContainer = styled.div<{
  width: string;
}>`
  display: flex;
  align-items: center;
  flex-basis: ${(props) => props.width ?? '52%'};

  @media (max-width: 600px) {
    flex-basis: ${(props) => props.width ?? '65%'};
  }
`;

const IconContainer = styled.div`
  display: flex;
`;

export const StyledText = styled(baseText)`
  color: ${({ theme }) => theme.primaryGray};
  padding-left: 20px;

  @media (max-width: 800px) {
    line-height: 20px;
  }
`;
