import styled from 'styled-components';
import { Loading } from './Loading';

const LoadingPadding = styled.div`
  padding-top: 250px;
`;

export const LoadingWholePage: React.FC = () => {
  return (
    <>
      <LoadingPadding>
        <Loading />
      </LoadingPadding>
    </>
  );
};
