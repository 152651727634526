import React, { useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme as ut,
} from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export const BookingStepHeader: React.FC<{
  heading: string;
  isBackButtonHidden?: boolean;
  onBack?: () => void;
}> = ({ heading, isBackButtonHidden = false, onBack }) => {
  const theme = useTheme();
  const history = useHistory();
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));
  const headerTitleRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    isMobileView ? headerRef.current.focus() : headerTitleRef.current.focus();
  });

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary,
        paddingLeft: '38px',
        paddingTop: '26px',
      }}
      role="banner"
    >
      <Grid container direction="row">
        {!isMobileView && (
          <Grid
            container
            item
            justifyContent="flex-start"
            style={{ height: '40px' }}
          >
            <Typography
              style={{
                color: theme.primaryWhite,
                outline: 'none',
                fontSize: '2.25rem',
                fontFamily: 'inherit',
              }}
              tabIndex={-1}
              ref={headerTitleRef}
            >
              <strong>my</strong>Workplace
            </Typography>
          </Grid>
        )}

        <Header container item xs={12}>
          <Grid
            item
            xs={2}
            md={2}
            style={{ outline: 'none' }}
            tabIndex={-1}
            ref={headerRef}
          >
            {!isBackButtonHidden && (
              <IconActionButton
                onClick={onBack}
                disableRipple
                disableFocusRipple
                role="navigation"
                aria-label="Back"
              >
                <ArrowBack
                  style={{ fontSize: '28px', color: theme.primaryWhite }}
                />
              </IconActionButton>
            )}
          </Grid>
          <HeadingGrid item xs={8} md={8}>
            <Typography
              align="center"
              variant="h1"
              style={{
                color: theme.primaryWhite,
                fontSize: '1.25rem',
                fontFamily: '"Arial Regular", "Arial", sans-serif',
              }}
            >
              {heading}
            </Typography>
          </HeadingGrid>
          <Grid container item xs={2} md={2} justifyContent="flex-end">
            <IconActionButton
              onClick={() => history.push('/')}
              disableRipple
              disableFocusRipple
              aria-label="Close"
              role="navigation"
            >
              <Close style={{ fontSize: '28px', color: theme.primaryWhite }} />
            </IconActionButton>
          </Grid>
        </Header>
      </Grid>
    </div>
  );
};

const Header = styled(Grid)`
  padding-bottom: 15px;
  padding-right: 38px;

  @media (max-width: 600px) {
    padding-top: 0px;
  }

  @media (min-width: 601px) {
    padding-top: 21px;
  }
`;

const IconActionButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.primaryWhite} !important;
  }
`;

const HeadingGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
