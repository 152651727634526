import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { UserModel } from 'Services/MeService';

export interface IconStyling {
  width: string;
  height: string;
  textSize: string;
  mobileWidth: string;
  mobileHeight: string;
  mobileTextSize: string;
}
export enum WarningTypes {
  SUCCESS,
  WARNING,
  DEFAULT,
}

export interface Props {
  user?: UserModel;
  styling?: IconStyling;
  status?: WarningTypes;
}

export const getFontColor = (
  theme: DefaultTheme,
  status: WarningTypes
): string => {
  switch (status) {
    case WarningTypes.SUCCESS:
      return theme.typography.green;
    case WarningTypes.WARNING:
      return theme.palette.pink;
    default:
      return theme.typography.primary;
  }
};

export const getBackgroundColor = (
  theme: DefaultTheme,
  status: WarningTypes
): string => {
  switch (status) {
    case WarningTypes.SUCCESS:
      return theme.background.green;
    case WarningTypes.WARNING:
      return theme.palette.lightPink;
    default:
      return theme.background.secondary;
  }
};

const BookingIcon: React.FC<Props> = (props) => {
  const iconStyling = props?.styling;
  const defaultIconStyle: IconStyling = {
    width: '58px',
    height: '56px',
    textSize: '24px',
    mobileWidth: '55px',
    mobileHeight: '53px',
    mobileTextSize: '20px',
  };
  const { status } = props ?? { status: undefined };

  let displayName = '';
  const regexPattern = /[,-.`~!@#$%^&*()[\]{}]/g;
  //if name is not empty
  const name = props?.user?.name;
  if (name !== undefined && name !== '') {
    //splitting the first and last name
    const [first, last] = name.trim().split(' ');
    //removing special characters from the first name
    const firstName = first.replace(/[^a-zA-Z ]/g, '');

    //if the user does not have a last name
    if (last === undefined) {
      displayName = firstName.charAt(0).toUpperCase();
    } else {
      //if the user has both a first name and last name
      const lastName = last.replace(/[^a-zA-Z ]/g, '');
      displayName = `${firstName.charAt(0).replace(regexPattern, '')}${lastName
        .charAt(0)
        .replace(regexPattern, '')}`.toUpperCase();
    }
  }
  return (
    <TextWrapper
      aria-hidden="true"
      styling={iconStyling ? iconStyling : defaultIconStyle}
      status={status}
    >
      {displayName}
    </TextWrapper>
  );
};

const TextWrapper = styled.span<{
  styling?: IconStyling;
  status?: WarningTypes;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  color: ${({ theme, status }) => getFontColor(theme, status)};
  text-align: center;
  line-height: normal;
  background-color: ${({ theme, status }) => getBackgroundColor(theme, status)};
  @media (min-width: 600px) {
    width: ${(props) => props.styling.width};
    height: ${(props) => props.styling.height};
    font-size: ${(props) => props.styling.textSize};
  }
  @media (max-width: 600px) {
    width: ${(props) => props.styling.mobileWidth};
    height: ${(props) => props.styling.mobileHeight};
    font-size: ${(props) => props.styling.mobileTextSize};
  }
`;

export default BookingIcon;
