import { BehalfOfBookingStep } from '../../BookingSteps';
import { SelectFloor } from 'Booking/Floor/SelectFloor';
import { SelectDesk } from 'Booking/DeskBooking/Desk/SelectDesk';
import { SelectFilter } from '../../Filter/SelectFilter';
import React, { useContext, useState } from 'react';
import { BookingType } from '../../BookingType';
import { SelectLocation } from '../../Location/SelectLocation';
import { useHistory } from 'react-router-dom';
import { SelectDate } from 'Booking/Date/SelectDate';
import { AnotherLocation } from 'Booking/Location/AnotherLocation';
import AppContext from 'AppContext';
import { ReserveBooking } from 'Booking/BookOnBehalf/BookingOnBehalfColleague/ReserveBooking/ReserveBooking';
import { BookingModel } from 'Services/BookingService';
import { DoneBooking } from 'Booking/BookOnBehalf/BookingOnBehalfColleague/DoneBooking/DoneBooking';
import FlowContext from 'Booking/FlowContext';
import { FlowStep } from 'Booking/FlowStep';
import { NextStepTrigger } from 'Booking/Location/NextStepTrigger';
import { LocationModel } from 'Services/LocationService';
import LocationContext from 'Booking/Location/LocationContext';
import { SearchUser } from './SearchUser';
import { ConfirmUser } from 'Booking/BookOnBehalf/BookingOnBehalfColleague/ConfirmUser/ConfirmUser';
import { UserModel as GuestUser } from '../../../Services/MeService';

export interface BehalfOfBookingStepData {
  currentStep: BehalfOfBookingStep;
  pastSteps: BehalfOfBookingStep[];
}

export const BookingOnBehalfColleagueFlow: React.FC = () => {
  const history = useHistory();

  const appContext = useContext(AppContext);

  const [stepData, setStepData] = useState<BehalfOfBookingStepData>({
    currentStep: BehalfOfBookingStep.SearchUser,
    pastSteps: [],
  });
  const [result, setResult] = useState<boolean>(false);
  const [bookingModel, setBookingModel] = useState<BookingModel>({
    resourceType: BookingType.Desk,
    filter: null,
    selectedFilter: null,
    resetFlag: false,
  });
  const [guestUser, setGuestUser] = useState<GuestUser>({} as GuestUser);
  const [locationData, setLocationData] = useState<{
    pastLocations: LocationModel[];
  }>(null);

  const updateStepData = (
    nextStep?: BehalfOfBookingStep,
    avoidCurrentStepRemember = false
  ) => {
    if (nextStep === undefined || nextStep === null) {
      const previousStep = stepData.pastSteps.pop();
      if (previousStep === undefined) {
        history.push('/book-on-behalf');
      } else {
        setStepData({
          currentStep: previousStep,
          pastSteps: stepData.pastSteps,
        });
      }
    } else {
      if (!avoidCurrentStepRemember) {
        stepData.pastSteps.push(stepData.currentStep);
      }
      setStepData({ currentStep: nextStep, pastSteps: stepData.pastSteps });
    }
  };

  const handleSelectLocationNext = (
    trigger: NextStepTrigger,
    deskBookingModel?: BookingModel,
    pastLocations?: LocationModel[]
  ) => {
    switch (trigger) {
      case NextStepTrigger.LocationSelect:
        updateStepData(BehalfOfBookingStep.SelectDate);
        if (deskBookingModel) {
          setBookingModel({
            ...bookingModel,
            buildingId: deskBookingModel.buildingId,
            buildingName: deskBookingModel.buildingName,
            city: deskBookingModel.city,
          });
        }
        break;
      case NextStepTrigger.ChooseAnotherLocationSelect:
        setLocationData({ pastLocations });
        updateStepData(BehalfOfBookingStep.ChooseAnotherLocation);
        break;
    }
  };

  const handleSearchUserNext = (user: GuestUser) => {
    setGuestUser(user);
    setBookingModel({
      ...bookingModel,
      userId: user.userId as unknown as string,
    });
    updateStepData(BehalfOfBookingStep.ConfirmUser, false);
  };

  const handleResetSearchUser = () => {
    setGuestUser({} as GuestUser);

    // Note: no need to reset bookingModel/userId since it will
    // always be set to the one selected.

    updateStepData(BehalfOfBookingStep.SearchUser, false);
  };

  const handleEditFilter = () => {
    setStepData({
      currentStep: BehalfOfBookingStep.SelectFilter,
      pastSteps: stepData.pastSteps.slice(
        0,
        stepData.pastSteps.indexOf(BehalfOfBookingStep.SelectFilter)
      ),
    });
  };

  const handleBookingDone = (result?: boolean) => {
    setResult(result);
    updateStepData(BehalfOfBookingStep.DoneBooking);
  };

  const type = appContext.bookOnBehalfOptions?.bookingOptions?.find(
    (x) => x.resourceType === BookingType.Desk
  );

  const handleNext = (
    nextStep: BehalfOfBookingStep,
    deskBookingModel?: BookingModel
  ) => {
    switch (nextStep) {
      case BehalfOfBookingStep.SelectDate:
        if (deskBookingModel) {
          setBookingModel({
            ...bookingModel,
            buildingId: deskBookingModel.buildingId,
            buildingName: deskBookingModel.buildingName,
            city: deskBookingModel.city,
          });
        }
        break;
      case BehalfOfBookingStep.SelectFilter:
        if (deskBookingModel) {
          setBookingModel({
            ...bookingModel,
            bookedDateObj: deskBookingModel.bookedDateObj,
            bookedDate: deskBookingModel.bookedDate,
            allDay: true,
          });
        }
        break;
      case BehalfOfBookingStep.SelectFloor:
        // Nothing to do here as setBookingModel is called when filters change
        // See handleOnFilterChange()
        break;
      case BehalfOfBookingStep.SelectDesk:
        if (deskBookingModel) {
          setBookingModel({
            ...bookingModel,
            floorId: deskBookingModel.floorId,
            floorName: deskBookingModel.floorName,
            resourceAvailability: deskBookingModel.resourceAvailability,
          });
        }
        break;
      case BehalfOfBookingStep.ReserveBooking:
        if (deskBookingModel) {
          setBookingModel({
            ...bookingModel,
            resourceId: deskBookingModel.resourceId,
            resourceName: deskBookingModel.resourceName,
            resourceFeatureOptions: deskBookingModel.resourceFeatureOptions,
            revisionNumber: 0,
            duration: '00:00:00',
            subject: type.caption,
            description: '',
          });
        }
        break;
      case BehalfOfBookingStep.DoneBooking:
        if (deskBookingModel) {
          setResult(result);
        }
        break;
    }
    updateStepData(nextStep);
  };

  const handleBack = () => {
    updateStepData();
  };

  const handleOnFilterChange = (deskBookingModel: BookingModel) => {
    setBookingModel({
      ...bookingModel,
      filter: deskBookingModel.filter,
      selectedFilter: deskBookingModel.selectedFilter,
      resetFlag: deskBookingModel.resetFlag,
    });
  };

  return (
    <FlowContext.Provider
      value={{
        currentStep: stepData.currentStep,
        bookingModel,
      }}
    >
      <>
        <FlowStep step={BehalfOfBookingStep.SearchUser}>
          <SearchUser
            type={type.caption}
            onNext={(user) => handleSearchUserNext(user)}
            onBack={handleBack}
            loggedInEmail={appContext.loggedInUser.email}
          />
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.ConfirmUser}>
          <ConfirmUser
            user={guestUser}
            type={type.caption}
            onNext={() => handleNext(BehalfOfBookingStep.SelectLocation)}
            onBack={handleBack}
            onChangeUser={() => {
              handleResetSearchUser();
            }}
          />
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.SelectLocation}>
          <SelectLocation
            type={type.caption}
            onNext={handleSelectLocationNext}
            onBack={handleBack}
            bookingFlowType={BookingType.BehalfOfColleague}
          />
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.ChooseAnotherLocation}>
          <LocationContext.Provider
            value={{
              pastLocations: locationData?.pastLocations,
            }}
          >
            <AnotherLocation
              type={type.caption}
              onNext={(location) =>
                handleNext(BehalfOfBookingStep.SelectDate, location)
              }
              onBack={handleBack}
              bookingHeaderType={BookingType.BehalfOfColleague}
            />
          </LocationContext.Provider>
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.SelectDate}>
          <SelectDate
            type={type.caption}
            onNext={(date) =>
              handleNext(BehalfOfBookingStep.SelectFilter, date)
            }
            onBack={handleBack}
            bookingFlowType={BookingType.Desk}
            guestUser={true}
            bookingHeaderType={BookingType.BehalfOfColleague}
            isGroupBooking={false}
          />
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.SelectFilter}>
          <SelectFilter
            type={type.caption}
            onNext={() => handleNext(BehalfOfBookingStep.SelectFloor)}
            onFilterChange={handleOnFilterChange}
            onBack={handleBack}
            bookOnBehalfOf={true}
          />
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.SelectFloor}>
          <SelectFloor
            type={type.caption}
            onNext={(floor) =>
              handleNext(BehalfOfBookingStep.SelectDesk, floor)
            }
            onBack={handleBack}
            onEdit={handleEditFilter}
            bookingHeaderType={BookingType.BehalfOfColleague}
          />
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.SelectDesk}>
          <SelectDesk
            type={type.caption}
            onNext={(desk) =>
              handleNext(BehalfOfBookingStep.ReserveBooking, desk)
            }
            onBack={handleBack}
            onEdit={handleEditFilter}
            bookingHeaderType={BookingType.BehalfOfColleague}
          />
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.ReserveBooking}>
          <ReserveBooking
            typeModel={type}
            onNext={(result) => handleBookingDone(result)}
            onBack={handleBack}
            user={guestUser}
          />
        </FlowStep>
        <FlowStep step={BehalfOfBookingStep.DoneBooking}>
          <DoneBooking
            type={type.caption}
            bookingResult={result}
            onBack={handleBack}
          />
        </FlowStep>
      </>
    </FlowContext.Provider>
  );
};
