import { getWithAuth } from './ServiceBase';

export interface BookingOptionModel {
  caption: string;
  description: string;
  resourceType: number;
}

export interface BookOnBehalfOptionsModel {
  bookingOptions: BookingOptionModel[];
}

export interface BookOnBehalfColleagueOptionsModel {
  bookingOptions: BookingOptionModel[];
}

export interface BookingOptionsModel {
  bookingOptions: BookingOptionModel[];
  bookOnBehalfOptions: {
    bookingOptions: BookingOptionModel[];
  };
}

export const getBookingOptions = async (): Promise<BookingOptionsModel> => {
  const bookingOptionsUrl = `${process.env.REACT_APP_API_URI}/api/Me/booking-options`;
  return getWithAuth(bookingOptionsUrl);
};
