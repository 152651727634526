import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import { DisableableOption, ErrorCardText } from 'Shared/ButtonStyles';

export const StyledErrorIcon = styled(ErrorIcon)`
  color: ${({ theme }) => theme.palette.redLight};
  height: 24px;
  width: 24px;
`;

export const ErrorCard: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <DisableableOption
          variant="text"
          fullWidth
          disableRipple
          disableFocusRipple
          disabled={true}
        >
          <Grid container direction="row" alignItems="center">
            <Grid
              container
              item
              xs={2}
              md={2}
              direction="column"
              alignItems="center"
            >
              <StyledErrorIcon />
            </Grid>
            <Grid container item xs={10} md={10} alignItems="flex-start">
              <ErrorCardText>{children}</ErrorCardText>
            </Grid>
          </Grid>
        </DisableableOption>
      </Grid>
    </>
  );
};
