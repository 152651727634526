import { BookingStepHeader } from 'Booking/BookingStepHeader';
import React from 'react';
import { BaseContainer } from 'Shared/BaseContainer';
import { CompletionContent } from 'Booking/Completion/CompletionContent';
import { OperationType } from 'Booking/Completion/OperationType';
import { useDocumentTitle } from 'Helpers/CustomHooks';

export const AcceptReservedDoneBooking: React.FC<{
  header: string;
  bookingResult: boolean;
  onBack: () => void;
}> = ({ header, bookingResult, onBack }) => {
  useDocumentTitle([header, header]);

  return (
    <BaseContainer>
      <BookingStepHeader
        heading={header}
        onBack={onBack}
        isBackButtonHidden={true}
      />
      <CompletionContent
        isOperationSuccessful={bookingResult}
        operationType={OperationType.CONFIRM}
      />
    </BaseContainer>
  );
};
