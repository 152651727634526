import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { UserModel as User } from '../../../../Services/MeService';
import { Title } from 'Shared/Title';
import UserDetails from 'components/UserDetails/UserDetails';
import { GridSingleColumn } from 'Shared/GridSingleColumn';
import { NextButton } from 'Shared/NextButton';
import styled from 'styled-components';

export const ConfirmUserContent: FC<{
  user: User;
  type: string;
  onNext: () => void;
  onChangeUser: () => void;
}> = ({ user, type, onNext, onChangeUser }) => {
  return (
    <Grid container justifyContent="center" role="main">
      <GridSingleColumn>
        <TitleContainer item>
          <Title text="You are booking for" />
        </TitleContainer>
        <Grid item>
          <UserDetails user={user} onChangeUser={onChangeUser} />
        </Grid>
        <ButtonContainer item>
          <NextButton
            text={`Find a ${type.toLowerCase()}`}
            width={170}
            onClick={onNext}
          />
        </ButtonContainer>
      </GridSingleColumn>
    </Grid>
  );
};

const TitleContainer = styled(Grid)`
  margin-bottom: 18px;
  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled(Grid)`
  text-align: center;
  margin-top: 20px;
  @media (max-width: 600px) {
    margin-top: 2px;
  }
`;
