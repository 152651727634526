/**
 * API versioning config - please update this file with API versions when creating/uplifting API integrations to new versions.
 */

// -- MeService
export const bookedUsersVersion = '1.1';

// -- BookingService
export const reserveGroupBookingVersion = '1.1';
export const getUserBookingsVersion = '1.1';
