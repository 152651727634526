import { BookingType } from 'Booking/BookingType';
import { LocationType } from 'Booking/Location/LocationType';
import { getWithAuth } from './ServiceBase';

export interface LocationModel {
  buildingId?: number;
  buildingName: string;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  country: string;
  postCode: string;
  latitude: string;
  longitude: string;
  maxCapacity: number;
  timeZoneId: number;
  locationType: LocationType;
  timeZoneAbbreviation: string;
  isBookable: boolean;
}

export const getLocations = async (
  resourceType?: BookingType
): Promise<LocationModel[]> => {
  const locationsUrl = `${process.env.REACT_APP_API_URI}/api/Buildings/customerlocations?resourceType=${resourceType}`;
  return getWithAuth<LocationModel[]>(locationsUrl);
};

export const getPastLocations = async (
  resourceType?: BookingType
): Promise<LocationModel[]> => {
  const pastLocationsUrl = `${process.env.REACT_APP_API_URI}/api/Me/booked-buildings?resourceType=${resourceType}`;
  return getWithAuth<LocationModel[]>(pastLocationsUrl);
};
