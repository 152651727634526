import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import {
  AddressText,
  ChevronRight,
  DisableableOptionDeskFeature,
} from 'Shared/ButtonStyles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import FeaturesList from '../FeaturesList/FeaturesList';
import { ResourceAvailabilityModel } from 'Services/ResourceService';
import styled from 'styled-components';

export interface Props {
  style?: CSSProperties;
  level: string;
  resource: ResourceAvailabilityModel;
  onSelect: () => void;
}

export const ResourceCard: React.FC<Props> = ({
  level,
  resource,
  onSelect,
  style,
}) => {
  return (
    <div>
      <DisableableOptionDeskFeature
        component="div"
        disableRipple
        onClick={() => onSelect()}
      >
        <AssetGrid container direction="column">
          <Grid container direction="row" item style={style}>
            <Grid container item xs={11} md={11} alignContent="center">
              <InfoText>{level + ' - ' + resource.name}</InfoText>
            </Grid>
            <Grid container item xs={1} md={1} alignItems="center">
              <ChevronRight />
            </Grid>
          </Grid>
          <Grid container direction="column" item>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Divider style={{ width: '100%' }} />
              </Grid>
            </Grid>

            {!!resource.resourceFeatureOptions?.length && (
              <FeaturesContainer container direction="row">
                <FeaturesList
                  featureList={resource.resourceFeatureOptions.map(
                    ({
                      resourceFeatureOptionName,
                      resourceFeatureOptionIcon,
                    }) => ({
                      icon: resourceFeatureOptionIcon,
                      title: resourceFeatureOptionName,
                    })
                  )}
                />
              </FeaturesContainer>
            )}
          </Grid>
        </AssetGrid>
      </DisableableOptionDeskFeature>
    </div>
  );
};

const InfoText = styled(AddressText)`
  font-weight: 700;
`;

const FeaturesContainer = styled(Grid)`
  margin-top: 10px;
  padding: '5px';
`;

const AssetGrid = styled(Grid)`
  @media (max-width: 600px) {
    min-height: 64px;
  }
  @media (min-width: 601px) {
    min-height: 64px;
  }
`;
