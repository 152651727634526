import React, { FC, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ResourceFeatureOptions } from 'Services/ResourceService';
import { defaultFilterItem } from 'Booking/Filter/DefaultFilterItem';

export interface DeskType {
  icon?: ResourceFeatureOptions['resourceFeatureOptionIcon'];
  title: string;
}

const FeatureList: FC<DeskType> = ({ icon, title }) => {
  const iconRef = useRef();
  useEffect(() => {
    const iconContainer = iconRef.current as SVGElement;

    if (iconContainer && !iconContainer.childElementCount) {
      const tempElm = document.createElement('div');
      tempElm.innerHTML = icon && icon.length > 0 ? icon : defaultFilterItem;

      const tempSvgElm = tempElm.querySelector('svg') as SVGElement;
      if (tempSvgElm) {
        // Set accessibility attributes
        tempSvgElm.setAttribute('aria-hidden', 'true');
        tempSvgElm.setAttribute('focusable', 'false');

        iconContainer.appendChild(tempSvgElm);
      }
    }
  }, [iconRef]);
  return (
    <StyledListItem as="li">
      <StyledListItemIcon>
        <Icon ref={iconRef} />
      </StyledListItemIcon>
      <StyledListItemText disableTypography={true}>{title}</StyledListItemText>
    </StyledListItem>
  );
};

const FeaturesList: FC<{
  featureList: Array<DeskType>;
}> = ({ featureList }) =>
  featureList &&
  featureList.length > 0 && (
    <>
      <ScreenReaderOnly>Desk features</ScreenReaderOnly>
      <StyledList disablePadding={true}>
        {featureList.map((feature, key) => (
          <FeatureList key={key} icon={feature.icon} title={feature.title} />
        ))}
      </StyledList>
    </>
  );

export default FeaturesList;

const StyledList = styled(List)`
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled.div`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.primaryBlack};
  & svg {
    height: 20px;
    width: 20px;
  }
`;

const StyledListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px 20px;
  margin-left: 10px;
  margin-bottom: 14px;
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }

  background-color: ${({ theme }) => theme.background.primary};
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 24px;
  padding-right: 6px;
  margin-left: -12px;
`;

const StyledListItemText = styled(ListItemText)`
  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const ScreenReaderOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;
