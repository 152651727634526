import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { StartTime, TimeContent } from './TimeContent';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BookingType } from 'Booking/BookingType';
import { generateFlowHeader } from 'Helpers/Utils';
import { BookingModel } from 'Services/BookingService';

export const SelectTime: React.FC<{
  type: string;
  onNext: (bookingModel?: BookingModel) => void;
  onBack: () => void;
  startListTime: StartTime;
  listDurationHours: number;
  incrementSizeMinutes: number;
  customTimeLabel: string;
  displaySubLabel: boolean;
  customMinTime: string;
  customMaxTime: number;
  bookingHeaderType?: BookingType;
  headingText: string;
}> = ({
  type,
  onNext,
  onBack,
  bookingHeaderType,
  startListTime,
  listDurationHours,
  incrementSizeMinutes,
  customTimeLabel,
  displaySubLabel,
  customMinTime,
  customMaxTime,
  headingText,
}) => {
  const heading = generateFlowHeader(bookingHeaderType, type);
  useDocumentTitle([heading, 'Select a time']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <TimeContent
        onNext={onNext}
        startListTime={startListTime}
        listDurationHours={listDurationHours}
        incrementSizeMinutes={incrementSizeMinutes}
        customTimeLabel={customTimeLabel}
        displaySubLabel={displaySubLabel}
        customMinTime={customMinTime}
        customMaxTime={customMaxTime}
        headingText={headingText}
      />
    </BaseContainer>
  );
};
