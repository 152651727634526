import React from 'react';
import { CheckCircle, Error } from '@material-ui/icons';
import styled, { css } from 'styled-components';

export interface ErrorModel {
  user?: string;
  floorName?: string;
  resourceName?: string;
  reason?: string;
}
export interface Props {
  isOperationSuccessful: boolean;
  data?: ErrorModel[];
}

const BookingConfirmation: React.FC<Props> = ({
  isOperationSuccessful,
  data,
}) => (
  <>
    {isOperationSuccessful ? (
      <ContainerSingleColumn>
        <IconWrapper>
          <SuccessIcon />
          <Bookings>
            <MessageText>Booking is reserved</MessageText>
          </Bookings>
        </IconWrapper>
        <TextWrapper>
          <MessageTextBold>What’s next?</MessageTextBold>
          <MessageText>
            Each colleague needs to confirm this booking on myWorkplace app
            before it cancels out in <MessageTextBold>24 hours</MessageTextBold>{' '}
            from now.
          </MessageText>
        </TextWrapper>
      </ContainerSingleColumn>
    ) : (
      data &&
      data.length &&
      data.map((d) => (
        <ContainerSingleColumn>
          <FailureIconWrapper>
            <FailIcon />
            <FailureDetails>
              <MessageTextBold> Unsuccessful booking(s):</MessageTextBold>
              {d.user && <NameText>{` ${d.user}:`} </NameText>}
              {d.floorName && d.resourceName && (
                <ResourceText>{` ${d.floorName} - ${d.resourceName}`}</ResourceText>
              )}
            </FailureDetails>
          </FailureIconWrapper>
          <TextWrapper>
            <MessageText>
              <MessageTextBold>Reason:{''} </MessageTextBold>
              {d.reason}
            </MessageText>
          </TextWrapper>
        </ContainerSingleColumn>
      ))
    )}
  </>
);

/**
 * Styles
 */
const ContainerSingleColumn = styled.div`
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 4px;
  padding: 30px 30px 45px;
  margin-top: 20px;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;
const TextStyles = css`
  color: ${({ theme }) => theme.primaryBlack};
  line-height: normal;
  font-size: 18px;
`;
const IconStyles = css`
  @media (max-width: 600px) {
    font-size: 24px !important;
  }
  font-size: 48px !important;
`;

const SuccessIcon = styled(CheckCircle)`
  ${IconStyles};
  color: ${({ theme }) => theme.palette.greenLight};
`;

const FailIcon = styled(Error)`
  ${IconStyles};
  color: ${({ theme }) => theme.palette.redLight};
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const FailureIconWrapper = styled.div`
  display: flex;
`;
const FlexStyles = css`
  display: flex;
  flex-direction: column;
`;
const TextWrapper = styled.div`
  ${FlexStyles}
  margin-top: 20px;
  margin-left: 66px;
  padding-left: 5px;
  max-width: 500px;

  @media (max-width: 600px) {
    margin-left: 32px;
  }
`;
const Bookings = styled.div`
  ${FlexStyles}
  @media (max-width: 600px) {
    padding-left: 14px;
  }
  padding-left: 23px;
`;
const MessageText = styled.span`
  ${TextStyles}
`;
const ResourceText = styled.span`
  ${TextStyles}
  margin-left: 11px;
`;

const FailureDetails = styled.div`
  ${FlexStyles}
  margin-top: 12px;
  padding-left: 23px;
  @media (max-width: 600px) {
    margin-top: 5px;
    padding-left: 14px;
  }
`;
const NameText = styled.p`
  font-weight: 700;
  ${TextStyles}
  margin: 10px 0 0;
  &:before {
    content: '• ';
  }
`;
const MessageTextBold = styled.span`
  font-weight: 700;
  ${TextStyles}
`;

export default BookingConfirmation;
