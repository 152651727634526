import { getWithAuth } from './ServiceBase';
import { UserModel } from './MeService';
export interface UserAvailability {
  startDate: string;
  endDate: string;
  isAvailable: boolean;
}
export interface GuestUser {
  user: UserModel;
  availability: UserAvailability;
}

export interface Users {
  users: Array<GuestUser>;
}

export const getGuestUserData = async (
  filter?: string,
  id?: string,
  availabilityStartDate?: string,
  availabilityEndDate?: string,
  includeAvailability?: boolean,
  availabilityResourceType?: number
): Promise<Users> => {
  let userUrl = `${process.env.REACT_APP_API_URI}/api/Users?`;
  const params: string[] = [];

  if (filter) params.push(`filter=${filter}`);
  if (id) params.push(`id=${id}`);
  if (availabilityStartDate)
    params.push(`availabilityStartDate=${availabilityStartDate}`);
  if (availabilityEndDate)
    params.push(`availabilityEndDate=${availabilityEndDate}`);
  if (includeAvailability)
    params.push(`includeAvailability=${includeAvailability}`);
  if (availabilityResourceType)
    params.push(`availabilityResourceType=${availabilityResourceType}`);

  const urlParams = params.join('&');
  userUrl += urlParams;

  return getWithAuth<Users>(userUrl);
};
