import { BehalfOfBookingStep } from '../../BookingSteps';
import { SelectFloor } from 'Booking/Floor/SelectFloor';
import React, { useContext, useState } from 'react';
import { BookingType } from '../../BookingType';
import { SelectLocation } from '../../Location/SelectLocation';
import { useHistory } from 'react-router-dom';
import { SelectDate } from 'Booking/Date/SelectDate';
import { AnotherLocation } from 'Booking/Location/AnotherLocation';
import AppContext from 'AppContext';
import { ReserveBooking } from 'Booking/BookOnBehalf/BookingOnBehalfGroup/ReserveBooking/ReserveBooking';
import { BookingModel } from 'Services/BookingService';
import { DoneGroupBooking } from 'Booking/BookOnBehalf/BookingOnBehalfGroup/DoneBooking/DoneGroupBooking';
import FlowContext from 'Booking/FlowContext';
import { FlowStep } from 'Booking/FlowStep';
import { NextStepTrigger } from 'Booking/Location/NextStepTrigger';
import { LocationModel } from 'Services/LocationService';
import LocationContext from 'Booking/Location/LocationContext';
import { SearchUser } from './SearchUser';
import { UserModel } from 'Services/MeService';
import { FloorAvailabilityModel } from 'Services/ResourceService';
import UserContext from 'Booking/BookOnBehalf/BookingOnBehalfGroup/SelectUsers/UserContext';
import CovidDeclarationContext from 'Booking/CovidDeclaration/CovidDeclarationContext';
import { CovidDeclaration } from 'Booking/CovidDeclaration/CovidDeclaration';

export interface BehalfOfBookingStepData {
  currentStep: BehalfOfBookingStep;
  pastSteps: BehalfOfBookingStep[];
}

export const BookingOnBehalfGroupFlow: React.FC = () => {
  const history = useHistory();

  const appContext = useContext(AppContext);
  const covidDeclarationContext = useContext(CovidDeclarationContext);

  const [stepData, setStepData] = useState<BehalfOfBookingStepData>({
    currentStep: BehalfOfBookingStep.SelectDate,
    pastSteps: [],
  });
  const [result, setResult] = useState<boolean>(false);
  const [bookingModel, setBookingModel] = useState<BookingModel[]>([]);
  const [users, setUsers] = useState<UserModel[]>();
  const [selectedDate, setSelectedDate] =
    useState<{ bookedDateObj: Date; bookedDate: string }>(null);

  const [locationData, setLocationData] = useState<{
    pastLocations: LocationModel[];
  }>(null);
  const selectionLimit = 10;

  const updateStepData = (
    nextStep?: BehalfOfBookingStep,
    avoidCurrentStepRemember = false
  ) => {
    if (nextStep === undefined || nextStep === null) {
      const previousStep = stepData.pastSteps.pop();
      if (previousStep === undefined) {
        history.push('/book-on-behalf');
      } else {
        setStepData({
          currentStep: previousStep,
          pastSteps: stepData.pastSteps,
        });
      }
    } else {
      if (!avoidCurrentStepRemember) {
        stepData.pastSteps.push(stepData.currentStep);
      }
      setStepData({ currentStep: nextStep, pastSteps: stepData.pastSteps });
    }
  };

  const generateBookingList = (bookingOptions: BookingModel): BookingModel[] =>
    bookingModel.map((elem) => {
      return {
        ...elem,
        ...bookingOptions,
      };
    });

  const handleSelectLocationNext = (
    trigger: NextStepTrigger,
    deskBookingModel?: BookingModel,
    pastLocations?: LocationModel[]
  ) => {
    switch (trigger) {
      case NextStepTrigger.LocationSelect:
        updateStepData(BehalfOfBookingStep.SelectFloor);
        if (deskBookingModel) {
          const bookingList = generateBookingList({
            buildingId: deskBookingModel.buildingId,
            buildingName: deskBookingModel.buildingName,
            city: deskBookingModel.city,
            resourceAvailability: deskBookingModel.resourceAvailability,
          });

          setBookingModel(bookingList);
        }
        break;
      case NextStepTrigger.ChooseAnotherLocationSelect:
        setLocationData({ pastLocations });
        updateStepData(BehalfOfBookingStep.ChooseAnotherLocation);
        break;
    }
  };

  const handleSearchUserNext = (users: UserModel[]) => {
    const bookingList = users.map((d) => {
      return {
        user: { userId: d.userId, name: d.name, email: d.email },
        resourceType: BookingType.Desk,
        resetFlag: false,
        ...selectedDate,
      };
    });

    setUsers(users);
    setBookingModel(bookingList);

    updateStepData(BehalfOfBookingStep.SelectLocation, false);
  };

  const handleCovidDeclarationNext = () => {
    covidDeclarationContext.agree();
    updateStepData(BehalfOfBookingStep.DoneBooking, true);
  };

  const handleBookingDone = () => {
    const displayCovidDeclaration = !bookingModel.find((model) => {
      return model.user.userId === appContext.loggedInUser.userId;
    });

    covidDeclarationContext.isAgreed || displayCovidDeclaration
      ? updateStepData(BehalfOfBookingStep.DoneBooking)
      : updateStepData(BehalfOfBookingStep.CovidDeclaration);
  };

  const type = appContext.bookOnBehalfOptions?.bookingOptions?.find(
    (x) => x.resourceType === BookingType.Desk
  );

  const handleNext = (
    nextStep: BehalfOfBookingStep,
    deskBookingModel?: BookingModel
  ) => {
    switch (nextStep) {
      case BehalfOfBookingStep.SearchUser:
        setUsers([]);
        if (deskBookingModel) {
          const dateObj = {
            bookedDateObj: deskBookingModel.bookedDateObj,
            bookedDate: deskBookingModel.bookedDate,
          };
          setSelectedDate(dateObj);
          setBookingModel([dateObj]);
        }
        break;
      case BehalfOfBookingStep.SelectFloor:
        if (deskBookingModel) {
          const bookingList = generateBookingList({
            buildingId: deskBookingModel.buildingId,
            buildingName: deskBookingModel.buildingName,
            city: deskBookingModel.city,
            resourceAvailability: deskBookingModel.resourceAvailability,
          });

          setBookingModel(bookingList);
        }
        break;

      case BehalfOfBookingStep.DoneBooking:
        if (deskBookingModel) {
          setResult(result);
        }
        break;
    }
    updateStepData(nextStep);
  };

  const handleBack = () => {
    updateStepData();
  };

  const handleSelectFloorNext = async (floorModel?: FloorAvailabilityModel) => {
    if (floorModel) {
      const bookingObj = bookingModel.map((d, i) => {
        return {
          ...d,
          floorId: floorModel.floorId,
          floorName: floorModel.floorName,
          resourceId: floorModel.resources[i].resourceId,
          resourceName: floorModel.resources[i].name,
          resourceFeatureOptions:
            floorModel.resources[i].resourceFeatureOptions,
          revisionNumber: 0,
          duration: '00:00:00',
          subject: type.caption,
          description: '',
          allDay: true,
        };
      });

      setBookingModel(bookingObj);
    }
    updateStepData(BehalfOfBookingStep.ReserveBooking);
  };

  return (
    <FlowContext.Provider
      value={{
        currentStep: stepData.currentStep,
        bookingModel,
      }}
    >
      <>
        <FlowStep step={BehalfOfBookingStep.SelectDate}>
          <SelectDate
            type={type.caption}
            onNext={(date) => handleNext(BehalfOfBookingStep.SearchUser, date)}
            onBack={handleBack}
            bookingFlowType={BookingType.Desk}
            guestUser={false}
            bookingHeaderType={BookingType.BehalfOf}
            isGroupBooking={true}
          />
        </FlowStep>

        <FlowStep step={BehalfOfBookingStep.SearchUser}>
          <UserContext.Provider
            value={{
              users: users || [],
            }}
          >
            <SearchUser
              type={type.caption}
              onNext={(user) => handleSearchUserNext(user)}
              onBack={handleBack}
              bookingFlowType={BookingType.Desk}
              selectionLimit={selectionLimit}
            />
          </UserContext.Provider>
        </FlowStep>

        <FlowStep step={BehalfOfBookingStep.SelectLocation}>
          <SelectLocation
            type={type.caption}
            onNext={handleSelectLocationNext}
            onBack={handleBack}
            bookingFlowType={BookingType.BehalfOf}
          />
        </FlowStep>

        <FlowStep step={BehalfOfBookingStep.ChooseAnotherLocation}>
          <LocationContext.Provider
            value={{
              pastLocations: locationData?.pastLocations,
            }}
          >
            <AnotherLocation
              type={type.caption}
              onNext={(location) =>
                handleNext(BehalfOfBookingStep.SelectFloor, location)
              }
              onBack={handleBack}
              bookingHeaderType={BookingType.BehalfOf}
            />
          </LocationContext.Provider>
        </FlowStep>

        <FlowStep step={BehalfOfBookingStep.SelectFloor}>
          <SelectFloor
            type={type.caption}
            onNext={(floor) => handleSelectFloorNext(floor)}
            onBack={handleBack}
            bookingHeaderType={BookingType.BehalfOf}
            isGroupBooking={true}
            users={users}
          />
        </FlowStep>

        <FlowStep step={BehalfOfBookingStep.ReserveBooking}>
          <ReserveBooking
            typeModel={type}
            onNext={() => handleBookingDone()}
            onBack={handleBack}
          />
        </FlowStep>

        <FlowStep step={BehalfOfBookingStep.CovidDeclaration}>
          <CovidDeclaration
            onNext={handleCovidDeclarationNext}
            onBack={handleBack}
          />
        </FlowStep>

        <FlowStep step={BehalfOfBookingStep.DoneBooking}>
          <DoneGroupBooking type={type.caption} onBack={handleBack} />
        </FlowStep>
      </>
    </FlowContext.Provider>
  );
};
