import { Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ConfirmationCard } from '../../../Confirmation/ConfirmationCard';
import { getDayName } from 'Helpers/DateHelper';
import {
  BookingModel,
  ConfirmationModel,
  saveBooking,
} from 'Services/BookingService';
import { BookingOptionModel } from 'Services/ConfigService';
import { BookingType } from 'Booking/BookingType';
import { Instructions } from 'Booking/CarSpaceBooking/Instructions';
import FlowContext from 'Booking/FlowContext';
import { NextButton } from 'Shared/NextButton';
import { GridSingleColumn } from 'Shared/GridSingleColumn';
import { Title } from 'Shared/Title';
import { ConfirmationCard as BOBOConfirmationCard } from 'Booking/BookOnBehalf/BookingOnBehalfColleague/ConfirmationCard/ConfirmationCard';
import { UserModel as User } from '../../../../Services/MeService';
import styled from 'styled-components';
import BookingContext from 'Booking/BookingContext';
import { LoadingWholePage } from 'Loading/LoadingWholePage';

export const ReserveBookingContent: React.FC<{
  typeModel: BookingOptionModel;
  onNext: (result: boolean) => void;
  user: User;
}> = ({ typeModel, onNext, user }) => {
  const bookingContext = useContext<{
    bookingModel: BookingModel;
  }>(FlowContext);

  const context = useContext(BookingContext);
  const [loading, setLoading] = useState(false);

  const reserveBooking = async (bookingModel: BookingModel) => {
    setLoading(true);
    const bookingConfirmation: ConfirmationModel = {
      userId: bookingModel.userId,
      bookedDate: bookingModel.bookedDate,
      subject: bookingModel.subject,
      description: bookingModel.description,
      resourceId: bookingModel.resourceId,
      duration: bookingModel.duration,
      allDay: bookingModel.allDay,
      revisionNumber: bookingModel.revisionNumber,
    };

    await saveBooking(bookingConfirmation)
      .then(() => {
        context.setBookingEntries({
          ...context.bookingEntries,
          isReloadRequired: true,
        });
        onNext(true);
      })
      .catch(() => onNext(false))
      .finally(() => setLoading(false));
  };

  const getLocation = () => {
    return `${bookingContext.bookingModel.buildingName}, ${bookingContext.bookingModel.city}`;
  };

  const location = getLocation();

  if (loading) {
    return <LoadingWholePage />;
  }

  return (
    <Grid container justifyContent="center" role="main">
      <GridSingleColumn>
        <Grid item>
          <Title text="Reserve this booking" />
        </Grid>
        {bookingContext.bookingModel && (
          <>
            <CardContainerGrid item>
              <ConfirmationCard
                typeModel={typeModel}
                dateTime={
                  getDayName(bookingContext.bookingModel.bookedDateObj) +
                  ' - ' +
                  bookingContext.bookingModel.bookedDateObj.toLocaleDateString(
                    'en-AU'
                  ) +
                  (bookingContext.bookingModel.allDay &&
                  bookingContext.bookingModel.resourceType !==
                    BookingType.SiteVisit
                    ? ' - All day'
                    : '')
                }
                resource={
                  typeModel.resourceType === BookingType.Desk
                    ? `${bookingContext.bookingModel.floorName}`
                    : null
                }
                location={location}
              />
            </CardContainerGrid>
            <CardContainerGrid item>
              <BOBOConfirmationCard
                resourceFeatureOptions={
                  bookingContext.bookingModel.resourceFeatureOptions
                }
                typeModel={typeModel}
                resource={
                  typeModel.resourceType === BookingType.Desk
                    ? `${bookingContext.bookingModel.floorName} - ${bookingContext.bookingModel.resourceName}`
                    : null
                }
                user={user}
              />
            </CardContainerGrid>
          </>
        )}
        {typeModel.resourceType === BookingType.CarSpace && (
          <Grid item>
            <Instructions />
          </Grid>
        )}
        <Grid
          container
          justifyContent="center"
          style={{ paddingTop: 15, paddingBottom: 24 }}
        >
          <NextButton
            text="Reserve booking"
            width={190}
            onClick={() => reserveBooking(bookingContext.bookingModel)}
          />
        </Grid>
      </GridSingleColumn>
    </Grid>
  );
};

const CardContainerGrid = styled(Grid)`
  width: 100%;
`;
