import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  useTheme as ut,
  useMediaQuery,
} from '@material-ui/core';
import styled from 'styled-components';
import { BookingEntry } from './BookingEntry';
import { AppointmentModel, getUserBookings } from 'Services/BookingService';
import { Loading } from 'Loading/Loading';
import { addMinutes, format } from 'date-fns';
import { BookingIcon } from './BookingIcon';
import BookingContext from './BookingContext';
import { BookingType } from './BookingType';
import { AppRoute } from 'Routes';
import { useHistory } from 'react-router-dom';
import { ShowMoreButton } from 'Shared/ShowMoreButton';
import { CenteredContentGrid } from 'Shared/CenteredContentGrid';
import { ErrorCard } from 'Error/ErrorCard';
import { getBookingsPollingDuration } from 'Configs/MeetingRoomConfig';
import { generateDurationString } from 'Helpers/Utils';
import { usePolling } from 'Helpers/CustomHooks';
import { getUserBookingsVersion as apiVersion } from 'Services/ApiVersionConfig';
import { encodeUtcDate } from 'Helpers/DateHelper';

export interface BookingEntryList {
  bookings: BookingModel[];
  isLoadingFailed: boolean;
  isReloadRequired: boolean;
}

export interface BookingModel {
  appointment: AppointmentModel;
  formattedDateTime: string;
}

export interface BookingViewParam {
  type: number;
  bookingId: number;
}

const BookingEntriesGrid = styled(Grid)`
  @media (max-width: 600px) {
    padding-top: 10px;
  }

  @media (min-width: 601px) {
    padding-top: 20px;
  }
`;

const ButtonText = styled(Typography)`
  text-transform: none;
`;

const MessageText = styled(ButtonText)`
  color: ${({ theme }) => theme.primaryGray};

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

export const Padding = styled.div`
  padding-top: 10px;
`;

export const UserBookingsContent: React.FC = () => {
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));
  const history = useHistory<BookingViewParam>();

  const initialBookingEntryCount = 4;

  const [showLessMode, setShowLessMode] = useState(false);
  const [effectCount, setEffectCount] = useState(0);

  const bookingContext = useContext(BookingContext);
  const bookingEntryList = bookingContext.bookingEntries?.bookings || [];

  const dateFormat = 'EEEE MMM dd';
  const timeFormat = 'h:mm a';

  const formatDate = (date: Date) => {
    return format(date, dateFormat);
  };

  const formatTime = (datetime: Date) => {
    return format(datetime, timeFormat);
  };

  const getFormattedDuration = (booking: AppointmentModel) => {
    const separator = ' -';

    if (booking.resource.type === BookingType.SiteVisit) {
      return '';
    }

    if (booking.allDay) {
      return `${separator} All day`;
    }

    const start = formatTime(booking.startDateTimeObj);
    const end = formatTime(
      addMinutes(
        booking.startDateTimeObj,
        booking.duration.hours * 60 + booking.duration.minutes
      )
    );

    if (booking.resource.type === BookingType.Room) {
      const duration = generateDurationString(
        `${booking.duration.hours}:${booking.duration.minutes}`
      );
      return `${separator} ${start} ${duration}`;
    }

    return `${separator} ${start} to ${end}`.toLowerCase();
  };

  const hasData = () => {
    return bookingEntryList.length > 0;
  };

  const handleExpand = () => {
    setShowLessMode(!showLessMode);
  };

  const getExpandButtonText = () => {
    return showLessMode
      ? 'Show fewer bookings'
      : `Show ${
          bookingEntryList.length - initialBookingEntryCount
        } more bookings`;
  };

  const getLocationText = (booking: AppointmentModel) => {
    let prefix = '';

    if (booking.resource.type === BookingType.Desk) {
      prefix = `${booking.resource.name}, ${booking.floorName}, `;
    } else if (booking.resource.type === BookingType.Room) {
      prefix = `${booking.floorName}, ${booking.resource.name}, `;
    }

    return `${prefix}${booking.location.buildingName}, ${booking.location.city}`;
  };

  const handleBookingEntrySelect = useCallback(
    (bookingId: number, type: BookingType, actionRequired: boolean) => {
      if (actionRequired) {
        history.push(AppRoute.AcceptBooking, {
          type: type,
          bookingId: bookingId,
        });
      } else {
        history.push(AppRoute.Booking, { type: type, bookingId: bookingId });
      }
    },
    []
  );

  const fetchUserBookings = async (polling?: boolean) => {
    const startDate = encodeUtcDate(new Date());
    return await getUserBookings(apiVersion, startDate)
      .then((res) => {
        const bookingModels: BookingModel[] = [];
        res?.map((x) => {
          if (x.status !== 'PENDING_ACCEPTANCE') {
            x.startDateTimeObj = new Date(`${x.startDateTime}Z`);
            bookingModels.push({
              formattedDateTime: `${formatDate(
                x.startDateTimeObj
              )}${getFormattedDuration(x)}`,
              appointment: x,
            });
          }
        });
        bookingContext.setBookingEntries({
          bookings: bookingModels,
          isLoadingFailed: false,
          isReloadRequired: false,
        });
      })
      .catch(() =>
        bookingContext.setBookingEntries({
          bookings: null,
          isLoadingFailed: true,
          isReloadRequired: true,
        })
      )
      .finally(() => {
        if (polling) setEffectCount(effectCount + 1);
      });
  };

  // -- Call Bookings API on render
  useEffect(() => {
    if (!(bookingContext.bookingEntries?.isReloadRequired ?? true)) {
      return;
    }
    fetchUserBookings();
  }, []);

  // -- Polling bookings API
  usePolling(async () => {
    fetchUserBookings(true);
  }, getBookingsPollingDuration);

  if (bookingContext.bookingEntries === null) {
    return <Loading />;
  }

  if (bookingContext.bookingEntries?.isLoadingFailed) {
    return (
      <Padding>
        <ErrorCard>
          {"Sorry, we couldn't establish if you have any bookings at this time"}
        </ErrorCard>
      </Padding>
    );
  }

  const getActionRequired = (booking?: BookingModel): boolean => {
    return booking
      ? booking.appointment.status === 'PENDING_CONFIRMATION'
      : false;
  };

  return (
    <>
      <BookingEntriesGrid
        container
        direction="row"
        spacing={isMobileView ? 2 : 3}
        role={bookingEntryList.length > 0 ? 'list' : null}
      >
        {bookingEntryList.map(
          (booking: BookingModel, i) =>
            (showLessMode || i < initialBookingEntryCount) && (
              <Grid key={i} item xs={12} md={6} role="listitem">
                <BookingEntry
                  subType={booking.appointment.subject}
                  dateTime={booking.formattedDateTime}
                  location={getLocationText(booking.appointment)}
                  onSelect={() =>
                    handleBookingEntrySelect(
                      booking.appointment.appointmentId,
                      booking.appointment.resource.type,
                      getActionRequired(booking)
                    )
                  }
                  actionRequired={getActionRequired(booking)}
                >
                  <BookingIcon
                    bookingType={booking.appointment.resource.type}
                  />
                </BookingEntry>
              </Grid>
            )
        )}
        {!hasData() && (
          <Grid item xs={12} md={12}>
            <MessageText>You don't have any bookings</MessageText>
          </Grid>
        )}
      </BookingEntriesGrid>
      {hasData() && bookingEntryList.length > initialBookingEntryCount && (
        <CenteredContentGrid>
          <ShowMoreButton
            showingLess={!showLessMode}
            handleExpand={handleExpand}
            text={getExpandButtonText()}
            width={227}
          />
        </CenteredContentGrid>
      )}
    </>
  );
};
