import { Dialog, DialogContent, Typography } from '@material-ui/core';
import styled, { useTheme } from 'styled-components';

export interface Props {
  labelOne: string;
  labelTwo: string;
  open: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const WarningDialog: React.FC<Props> = (props) => {
  const labelOne = props.labelOne;
  const labelTwo = props.labelTwo;
  const theme = useTheme();

  return (
    <Dialog
      open={props.open}
      aria-modal={true}
      PaperProps={{
        style: {
          backgroundColor: theme.background.primary,
          borderRadius: 10,
          width: 310,
          overflow: 'hidden',
          minWidth: 310,
          height: 295,
        },
      }}
    >
      <WarningDialogContent>
        <Typography
          component={'div'}
          gutterBottom
          align="center"
          style={{
            backgroundColor: `${({ theme }) => theme.background.primary}`,
            lineHeight: 1.9,

            outline: 'none',
          }}
          tabIndex={-1}
          ref={(node) => {
            node && node.focus();
          }}
        >
          <DialogContent
            style={{
              overflow: 'hidden',
              width: 240,
              marginLeft: -12,
              paddingTop: 0, //MUI has set it to 20, need to override it.
            }}
          >
            <FirstLabelStyle>{labelOne}</FirstLabelStyle>
            <SecondLabelStyle>{labelTwo}</SecondLabelStyle>
          </DialogContent>
        </Typography>
      </WarningDialogContent>
      {/*need to override MUI padding*/}
      <ButtonWrapper>
        <DialogButton onClick={props.onClick}>
          <DialogButtonText>OK</DialogButtonText>
        </DialogButton>
      </ButtonWrapper>
    </Dialog>
  );
};

const WarningDialogContent = styled(DialogContent)`
  background-color: ${({ theme }) => theme.background.primary};
  box-sizing: border-box;
  word-wrap: break-word;
  overflow: hidden;
`;

const DialogButtonText = styled(Typography)<{
  component?: React.ElementType;
}>`
  text-transform: none;
  color: ${({ theme }) => theme.typography.secondary};
  background-color: ${({ theme }) => theme.background.primary};
  text-align: center;
  line-height: normal;
  font-size: 18px;
  width: 30px;
`;

const DialogButton = styled.button`
  font-size: 18px;
  background-color: ${({ theme }) => theme.background.primary};
  outline: none;
  border: none;
  margin-bottom: 40px;
  width: 50px;
  :hover {
    cursor: pointer;
  }
  &:focus {
    border-radius: 50px;
    border: 2px solid ${({ theme }) => theme.palette.primary};
    margin-bottom: 38px;
    margin-left: -4px;
  }
`;

const FirstLabelStyle = styled.p`
  width: 50;
  word-wrap: break-word;
  margin-bottom: 30;
  padding-top: 9px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SecondLabelStyle = styled.p`
  width: 226;
  padding-top: 15px;
`;
