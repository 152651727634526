import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, useMediaQuery, useTheme as ut } from '@material-ui/core';
import { Title } from 'Shared/Title';
import { ContainerSingleColumn } from 'Shared/ContainerSingleColumn';
import NumberTileButtons from 'components/NumberTileButtons/NumberTileButtons';
import { PreferenceList } from './PreferenceList/PreferenceList';
import { FilterModel, getFilters } from 'Services/ResourceService';
import { NextButton } from 'Shared/NextButton';
import { BookingModel } from 'Services/BookingService';
import PreferenceContext from './PreferenceContext';
import { MeetingRoomConfig } from 'Configs/MeetingRoomConfig';
import { BookingType } from 'Booking/BookingType';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import { ErrorWholePage } from 'Error/ErrorWholePage';

interface Props {
  bookingType?: BookingType;
  onNext: (bookingModel?: BookingModel) => void;
}

export const PreferenceContent: React.FC<Props> = ({ bookingType, onNext }) => {
  const [preferenceContext, setContext] = useContext(PreferenceContext);

  const [seatingCapacity, setSeatingCapacity] = useState(
    preferenceContext?.seatingCapacity || MeetingRoomConfig.defaultCapacity
  );
  const [featureOptions, setFeatureOptions] = useState<Array<FilterModel>>(
    preferenceContext?.filter || []
  );
  const [loading, setLoading] = useState(false);
  const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>();
  const [retryCount, setRetryCount] = useState(0);
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));

  useEffect(() => {
    if (preferenceContext?.filter?.length) {
      return;
    }
    setLoading(true);
    getFilters(bookingType)
      .then((res) => {
        setFeatureOptions(res[0].resourceAttributes);
      })
      .catch(() => setIsLoadingFailed(true))
      .finally(() => setLoading(false));
  }, [retryCount]);

  const handleCapacitySelection = (selectedCapacity: number) => {
    setSeatingCapacity(selectedCapacity);
    setContext({ filter: featureOptions, seatingCapacity: selectedCapacity });
  };

  const handleFeatureSelection = (featureList: FilterModel[]) => {
    setFeatureOptions(featureList);
    setContext({ filter: featureList, seatingCapacity });
  };

  if (loading) {
    return <LoadingWholePage />;
  }

  if (isLoadingFailed) {
    return (
      <ErrorWholePage
        message="Sorry, we couldn’t load this page."
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }

  return (
    <ContainerSingleColumn role="main">
      <Title text="Choose meeting room preferences" />
      <HintText>Minimum Seating Capacity *</HintText>
      <NumberTileButtons
        name="numberTile"
        endLimit={10}
        defaultValue={seatingCapacity}
        onClick={handleCapacitySelection}
      />
      <PreferenceList
        featureOptions={featureOptions}
        handleSelection={handleFeatureSelection}
      />
      <ButtonContainer>
        <NextButton
          text="Next"
          width={isMobileView ? 129 : 142}
          onClick={() => {
            onNext({
              seatingCapacity,
              filter: featureOptions,
            });
          }}
        />
      </ButtonContainer>
    </ContainerSingleColumn>
  );
};

export const HintText = styled(Typography)`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 14px;
  margin-top: 35px;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    margin-top: 25px;
  }
`;

const ButtonContainer = styled(ContainerSingleColumn)`
  padding-top: 40px;
  text-align: center;
  @media (max-width: 600px) {
    padding-top: 55px;
  }
`;
