import { Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ConfirmationCard } from './ConfirmationCard';
import { getDayName } from 'Helpers/DateHelper';
import {
  BookingModel,
  ConfirmationModel,
  saveBooking,
} from 'Services/BookingService';
import { BookingOptionModel } from 'Services/ConfigService';
import { BookingType } from 'Booking/BookingType';
import { Instructions } from 'Booking/CarSpaceBooking/Instructions';
import FlowContext from 'Booking/FlowContext';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import { NextButton } from 'Shared/NextButton';
import { GridSingleColumn } from 'Shared/GridSingleColumn';
import {
  getAvailableCarSpaces,
  getAvailableSiteVisits,
} from 'Services/ResourceService';
import { NoAvailabiltyErrorCard } from 'Error/NoAvailabiltyErrorCard';
import BookingContext from 'Booking/BookingContext';
import { Title } from 'Shared/Title';
import _ from 'lodash';
import { CompletionContent } from 'Booking/Completion/CompletionContent';
import { OperationType } from 'Booking/Completion/OperationType';

export enum NextStepTrigger {
  DateSelect,
}

export const ConfirmationContent: React.FC<{
  typeModel: BookingOptionModel;
  onNext: (result: boolean) => void;
}> = ({ typeModel, onNext }) => {
  const bookingContext = useContext<{
    bookingModel: BookingModel;
  }>(FlowContext);
  const context = useContext(BookingContext);
  const [loading, setLoading] = useState(false);
  const [hasAvailableResource, setAvailableResource] = useState(true);
  const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>();
  const meetingRoomBooking = typeModel.resourceType === BookingType.Room;

  const confirmBooking = async () => {
    setLoading(true);
    if (typeModel.resourceType === BookingType.CarSpace) {
      getAvailableCarSpaces(
        bookingContext.bookingModel.buildingId,
        bookingContext.bookingModel.bookedDateObj
      )
        .then(async (res) => {
          if (
            res === null ||
            res.floors === null ||
            res.floors[0].resources === null
          ) {
            setAvailableResource(false);
          } else {
            _.remove(res.floors, (floor) => floor.resources.length === 0);

            const floorRandomValue = Math.floor(
              Math.random() * res.floors.length
            );
            const resourceRandomValue = Math.floor(
              Math.random() * res.floors[floorRandomValue].resources.length
            );
            const bookingModel: BookingModel = {
              ...bookingContext.bookingModel,
              resourceId:
                res.floors[floorRandomValue].resources[resourceRandomValue]
                  .resourceId,
            };

            if (bookingModel.resourceId !== null) {
              await callBooking(bookingModel);
            } else {
              setAvailableResource(false);
            }
          }
        })
        .catch(() => {
          setIsLoadingFailed(true);
        })
        .finally(async () => {
          setLoading(false);
        });
    } else if (typeModel.resourceType === BookingType.SiteVisit) {
      getAvailableSiteVisits(
        bookingContext.bookingModel.buildingId,
        bookingContext.bookingModel.bookedDateObj
      )
        .then(async (res) => {
          if (
            res === null ||
            res.floors === null ||
            res.floors[0].resources === null
          ) {
            setAvailableResource(false);
          } else {
            _.remove(res.floors, (floor) => floor.resources.length === 0);

            const floorRandomValue = Math.floor(
              Math.random() * res.floors.length
            );
            const resourceRandomValue = Math.floor(
              Math.random() * res.floors[floorRandomValue].resources.length
            );
            const bookingModel: BookingModel = {
              ...bookingContext.bookingModel,
              resourceId:
                res.floors[floorRandomValue].resources[resourceRandomValue]
                  .resourceId,
            };

            if (bookingModel.resourceId !== null) {
              await callBooking(bookingModel);
            } else {
              setAvailableResource(false);
            }
          }
        })
        .catch(() => {
          setIsLoadingFailed(true);
        })
        .finally(async () => {
          setLoading(false);
        });
    } else {
      await callBooking(bookingContext.bookingModel);
    }
  };

  const getResource = () => {
    if (typeModel.resourceType === BookingType.Desk || meetingRoomBooking) {
      return `${bookingContext.bookingModel.floorName} - ${bookingContext.bookingModel.resourceName}`;
    }
    return null;
  };

  const getLocation = () => {
    return `${bookingContext.bookingModel.buildingName}, ${bookingContext.bookingModel.city}`;
  };

  const getBookingStatus = (res: ConfirmationModel) => {
    if (res.subject === 'Room') {
      res.status === 'PENDING_ACCEPTANCE' || res.status === 'BOOKED'
        ? onNext(true)
        : onNext(false);
    } else onNext(true); // Default status
  };

  const callBooking = async (bookingModel: BookingModel) => {
    const bookingConfirmation: ConfirmationModel = {
      userId: bookingModel.userId,
      bookedDate: meetingRoomBooking
        ? bookingModel.bookingStartDate
        : bookingModel.bookedDate,
      subject: bookingModel.subject,
      description: bookingModel.description,
      resourceId: bookingModel.resourceId,
      duration: bookingModel.duration,
      allDay: bookingModel.allDay,
      revisionNumber: bookingModel.revisionNumber,
    };

    await saveBooking(bookingConfirmation)
      .then((res) => {
        context.setBookingEntries({
          ...context.bookingEntries,
          isReloadRequired: true,
        });
        getBookingStatus(res);
      })
      .catch(() => onNext(false))
      .finally(() => setLoading(false));
  };
  const location = getLocation();
  const resource = getResource();

  if (loading) {
    return <LoadingWholePage />;
  }

  if (isLoadingFailed) {
    return (
      <CompletionContent
        isOperationSuccessful={!isLoadingFailed}
        operationType={OperationType.CONFIRM}
      />
    );
  }

  if (!hasAvailableResource) {
    return (
      <NoAvailabiltyErrorCard
        bookingType={bookingContext.bookingModel.resourceType}
      />
    );
  }

  return (
    <Grid container justifyContent="center" role="main">
      <GridSingleColumn>
        <Grid item>
          <Title
            text={
              typeModel.resourceType !== BookingType.Room
                ? 'Confirm your booking'
                : 'Reserve this booking'
            }
          />
        </Grid>
        {bookingContext.bookingModel && (
          <Grid item>
            <ConfirmationCard
              typeModel={typeModel}
              dateTime={
                getDayName(bookingContext.bookingModel.bookedDateObj) +
                ' - ' +
                bookingContext.bookingModel.bookedDateObj.toLocaleDateString(
                  'en-AU'
                ) +
                (bookingContext.bookingModel.allDay &&
                bookingContext.bookingModel.resourceType !==
                  BookingType.SiteVisit
                  ? ' - All day'
                  : '')
              }
              resource={resource}
              resourceFeatureOptions={
                bookingContext.bookingModel.resourceFeatureOptions
              }
              location={location}
              bookingDateObj={bookingContext.bookingModel.bookedDateObj}
              bookingDuration={bookingContext.bookingModel.duration}
            />
          </Grid>
        )}
        {typeModel.resourceType === BookingType.CarSpace && (
          <Grid item>
            <Instructions />
          </Grid>
        )}
        <Grid
          container
          justifyContent="center"
          style={{ paddingTop: 15, paddingBottom: 24 }}
        >
          <NextButton
            text={
              typeModel.resourceType === BookingType.Room
                ? 'Request booking'
                : 'Confirm booking'
            }
            width={190}
            onClick={() => confirmBooking()}
          />
        </Grid>
      </GridSingleColumn>
    </Grid>
  );
};
