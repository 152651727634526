import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { AnotherLocationContent } from './AnotherLocationContent';
import { BookingModel } from 'Services/BookingService';
import { BookingType } from 'Booking/BookingType';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { generateFlowHeader } from 'Helpers/Utils';

export const AnotherLocation: React.FC<{
  type: string;
  onNext: (bookingModel?: BookingModel) => void;
  onBack: () => void;
  bookingHeaderType?: BookingType;
}> = ({ type, onNext, onBack, bookingHeaderType }) => {
  const heading = generateFlowHeader(bookingHeaderType, type);
  useDocumentTitle([heading, 'Select Another Location']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <AnotherLocationContent onNext={onNext} type={type} />
    </BaseContainer>
  );
};
