import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { DeskContent } from './DeskContent';
import { BookingModel } from 'Services/BookingService';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BookingType } from 'Booking/BookingType';
import { generateFlowHeader } from 'Helpers/Utils';

export const SelectDesk: React.FC<{
  type: string;
  onNext: (bookingModel?: BookingModel) => void;
  onBack: () => void;
  onEdit: () => void;
  bookingHeaderType?: BookingType;
  onViewFloorPlan?: (img) => void;
}> = ({ type, onNext, onBack, onEdit, bookingHeaderType, onViewFloorPlan }) => {
  const heading = generateFlowHeader(bookingHeaderType, type);
  useDocumentTitle([heading, 'Select Desk']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <nav>
        <DeskContent
          onNext={onNext}
          onEdit={onEdit}
          onViewFloorPlan={onViewFloorPlan}
        />
      </nav>
    </BaseContainer>
  );
};
