import React from 'react';
import { Container } from '@material-ui/core';
import { UserBookingsContent } from './UserBookingsContent';
import { Title } from 'Shared/Title';

export const UserBookings: React.FC = () => {
  return (
    <Container style={{ maxWidth: '94%', paddingLeft: 10, paddingRight: 10 }}>
      <Title text="Your Bookings" />
      <UserBookingsContent />
    </Container>
  );
};
