import React from 'react';
import { ButtonBase, Divider, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { LocationOn } from '@material-ui/icons';
export interface Props {
  subType: string;
  dateTime: string;
  location: string;
  onSelect: () => void;
  actionRequired?: boolean;
  duration?: string;
}

export const BookingEntry: React.FC<Props> = ({
  subType,
  dateTime,
  location,
  onSelect,
  children,
  actionRequired,
}) => {
  return (
    <BookingEntryButton
      disableRipple
      focusRipple={false}
      onClick={onSelect}
      component="div"
    >
      <Container>
        <UpperContainer>
          <IconContainer>{children}</IconContainer>
          <UpperText>
            <TypeText data-testid="type">{subType}</TypeText>
            <DateContainer data-testid="datetime">{dateTime}</DateContainer>
          </UpperText>
        </UpperContainer>
        <LowerContainer>
          <Divider style={{ width: '100%' }} />
          <LocationContainer>
            <LocationIcon />
            <LocationText data-testid="location">{location}</LocationText>
          </LocationContainer>
        </LowerContainer>
      </Container>
      {actionRequired && <ActionRequired>Action required</ActionRequired>}
    </BookingEntryButton>
  );
};

const ActionRequired = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  background: ${({ theme }) => theme.palette.redLight};
  color: ${({ theme }) => theme.primaryWhite};
  text-transform: none;
  border-radius: 2px;

  @media (max-width: 600px) {
    width: 100px;
    height: 25px;
    font-size: 12px;
    top: 8px;
  }

  @media (min-width: 601px) {
    width: 130px;
    height: 35px;
    font-size: 14px;
    top: 12px;
  }
`;

const BookingEntryButton = styled(ButtonBase)<{
  component?: React.ElementType;
}>`
  background-color: ${({ theme }) => theme.primaryWhite} !important;
  width: 100%;
  align-items: flex-start;
  border-radius: 4px;

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.secondary} !important;
    border: 2px solid ${(props) => props.theme.primaryBlack};
  }

  @media (max-width: 600px) {
    min-height: 92px;
    max-height: 110px;
  }

  @media (min-width: 601px) {
    min-height: 135px;
  }
`;

const ButtonText = styled(Typography)`
  text-transform: none;
`;

const TypeText = styled(ButtonText)`
  color: ${({ theme }) => theme.secondaryBlack};
  font-weight: bold;
  line-height: unset;

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
    padding-bottom: 4px;
  }
`;

const DateContainer = styled(ButtonText)`
  color: ${({ theme }) => theme.primaryGray};
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 600px) {
    font-size: 12px;
    margin-top: -2px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
    margin-top: -4px;
  }
`;

const LocationText = styled(ButtonText)`
  color: ${({ theme }) => theme.primaryGray};
  line-height: 18px;

  @media (max-width: 600px) {
    margin-top: -3px;
    font-size: 12px;
    padding-left: 10px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
    padding-top: 3px;
    padding-left: 14px;
  }
`;

const LocationIcon = styled(LocationOn)`
  color: ${({ theme }) => theme.primaryGray};

  @media (max-width: 600px) {
    height: 12px;
    width: 12px;
  }

  @media (min-width: 601px) {
    height: 24px;
    width: 24px;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const IconContainer = styled.div`
  @media (max-width: 600px) {
    padding: 7px 13px 0 15px;
  }

  @media (min-width: 601px) {
    padding: 8px 21px 0 40px;
  }
`;

const UpperText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 600px) {
    padding-top: 11px;
  }

  @media (min-width: 601px) {
    padding-top: 19px;
  }
`;

const UpperContainer = styled.div`
  display: flex;

  @media (max-width: 600px) {
    height: 55px;
  }

  @media (min-width: 601px) {
    height: 75px;
  }
`;

const LowerContainer = styled.div``;

const LocationContainer = styled.div`
  display: flex;

  @media (max-width: 600px) {
    padding-top: 10px;
    padding-left: 16px;
  }

  @media (min-width: 601px) {
    padding-top: 14px;
    padding-left: 45px;
  }
`;
