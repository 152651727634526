import React, { useEffect } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import styled, { css } from 'styled-components';

export interface Props {
  options: string[];
  selectedOption: string;
  onValueChange?: (value: string) => void;
}

const Select: React.FC<Props> = ({
  options,
  onValueChange,
  selectedOption,
}) => {
  const ref = React.useRef(null);

  const [selectedValue, setSelected] = React.useState(selectedOption);
  const [isOptionsVisible, setIsOptionsVisible] = React.useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      /* If the option list is open and the clicked target is not within the option list,then close the option list */
      if (!ref.current?.contains(e.target) && isOptionsVisible) {
        setIsOptionsVisible(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      /* Cleanup event listener */
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOptionsVisible]);

  const clickHandler = (e, option) => {
    if (e.keyCode == '13' || e.keyCode == '32') {
      setSelected(option);
      onValueChange(option);
      setIsOptionsVisible(!isOptionsVisible);
    }
  };

  return (
    <StyledSelect ref={ref} isActive={isOptionsVisible}>
      <StyledSelected>
        <div
          tabIndex={0}
          onKeyDown={() => setIsOptionsVisible(true)}
          onClick={() => setIsOptionsVisible(!isOptionsVisible)}
          role="main"
        >
          <span data-type="text">{selectedValue}</span>
        </div>

        <span onClick={() => setIsOptionsVisible(!isOptionsVisible)}>
          {isOptionsVisible ? <ExpandLessIcon /> : <ExpandMore />}
        </span>
      </StyledSelected>

      {isOptionsVisible && (
        <StyledOptions role="list" tabIndex={0}>
          {options.map((option, index) => (
            <StyledOption
              tabIndex={0}
              key={index}
              role="listitem"
              title={option}
              onKeyDown={(e) => clickHandler(e, option)}
              onClick={() => {
                setSelected(option);
                onValueChange(option);
                setIsOptionsVisible(!isOptionsVisible);
              }}
            >
              <span>{option}</span>
            </StyledOption>
          ))}
        </StyledOptions>
      )}
    </StyledSelect>
  );
};

const StyledSelect = styled('div')<{
  isActive?: boolean;
}>`
  height: 40px;
  width: 280px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #fff;
  border: ${(props) =>
    props.isActive ? '1px solid #0165d2' : '1px solid #7f7f7f'};
`;

const StyledOptions = styled.div`
  position: absolute;
  max-height: 180px;
  height: auto;
  overflow-y: auto;
  top: 45px;
  left: -0.5px;
  width: 100%;
  padding: 4px 0;
  background: #fff;
  border: 1px solid #7f7f7f;
`;

const StyledOption = styled.div(
  () => css`
    padding: 8px 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  `
);

const StyledSelected = styled.div(
  () => css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;

    div {
      height: 40px;
      display: flex;
      align-items: center;
      display: grid;
      grid-template-columns: '40px 1fr';

      span[data-type='text'] {
        padding-left: 12px;
      }
    }
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  `
);

const ExpandLessIcon = styled(ExpandLess)`
  color: #0165d2;
`;

export default Select;
