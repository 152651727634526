import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import styled, { useTheme } from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import { RefreshIcon } from 'Shared/ButtonStyles';
import { ActionButton } from 'Shared/ActionButton';
import InfoIcon from '@material-ui/icons/Info';

const Text = styled(Typography)`
  color: ${({ theme }) => theme.secondaryBlack};

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  color: ${({ theme }) => theme.palette.redLight};
  height: 48px;
  width: 48px;
  padding-top: 8px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.palette.primary};
  height: 48px;
  width: 48px;
  padding-top: 8px;
`;

const IconType = {
  default: <StyledErrorIcon />,
  error: <StyledErrorIcon />,
  info: <StyledInfoIcon />,
};

export const Error: React.FC<{
  message: string;
  iconType?: string;
  onRetry?: () => void;
}> = ({ message, onRetry, iconType }) => {
  const theme = useTheme();
  return (
    <Container>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} md={12}>
          {iconType ? IconType[iconType] : IconType.default}
        </Grid>
        <Grid item xs={12} md={12}>
          <Text align="center">{message}</Text>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        style={{ paddingTop: 36, paddingBottom: 24 }}
      >
        {onRetry && (
          <ActionButton
            text="Retry"
            onClick={onRetry}
            style={{
              width: 135,
              background: theme.palette.secondary,
            }}
          >
            <RefreshIcon />
          </ActionButton>
        )}
      </Grid>
    </Container>
  );
};
