import { Container } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerSingleColumn = styled(Container)`
  @media (max-width: 600px) {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: 601px) {
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 80px;
  }
`;
