export enum DeskBookingStep {
  CovidDeclaration,
  SelectLocation,
  ChooseAnotherLocation,
  SelectDate,
  SelectFilter,
  SelectFloor,
  SelectDesk,
  ConfirmBooking,
  DoneBooking,
  FloorPlan,
}

export enum MeetingRoomBookingStep {
  SelectLocation,
  ChooseAnotherLocation,
  SelectFloor,
  SelectDate,
  SelectStartTime,
  SelectEndTime,
  SelectCustomTime,
  SelectFilter,
  SelectRoom,
  ConfirmBooking,
  DoneBooking,
}

export enum SiteVisitBookingStep {
  CovidDeclaration,
  SelectLocation,
  ChooseAnotherLocation,
  SelectDate,
  ConfirmBooking,
  DoneBooking,
}

export enum CarSpaceBookingStep {
  CovidDeclaration,
  SelectLocation,
  ChooseAnotherLocation,
  SelectDate,
  ConfirmBooking,
  DoneBooking,
}

export enum BookingViewStep {
  ViewDetails,
  DoneCancellation,
  FloorPlan,
}

export enum AcceptBookingStep {
  ViewDetails,
  DoneCancellation,
  CovidDeclaration,
  DoneConfirmBooking,
}

export enum BehalfOfBookingStep {
  CovidDeclaration,
  SelectBookingType,
  SearchUser,
  SelectLocation,
  ChooseAnotherLocation,
  SelectDate,
  SelectFilter,
  SelectFloor,
  SelectDesk,
  ReserveBooking,
  DoneBooking,
  ConfirmUser,
  SelectBehalfOfType,
}
