import * as React from 'react';
import { UserModel } from 'Services/MeService';
import styled from 'styled-components';
import UserChip from './../UserChip/UserChip';

export interface Props {
  userData: UserModel[];
  onClick: (user: UserModel) => void;
  focus?: (focusedHtmlElement: HTMLButtonElement) => void;
}

export const UserChipContainer: React.FC<Props> = (props) => {
  const handleOnClick = (user: UserModel) => {
    props.onClick && props.onClick(user);
  };

  const createUserChips = (): React.ReactElement => {
    return (
      <>
        {props.userData.map((userData, iter) => (
          <UserChip
            key={iter}
            order={userData.name === 'You' ? 1 : 2}
            onClick={() => handleOnClick(userData)}
            focus={iter == props.userData.length - 1 ? props.focus : undefined}
          >
            {userData.name}
          </UserChip>
        ))}
      </>
    );
  };
  return <Wrapper>{createUserChips()}</Wrapper>;
};

export default UserChipContainer;

const Wrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 10px;
  background-color: ${({ theme }) => theme.primaryWhite};
  max-width: 100%;
  min-height: 74px;
  padding: 17px 21px 17px 21px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 16px 21px 14px 21px;
    min-height: 64px;
    gap: 5px 5px;
  }
`;
