import React from 'react';
import sanitizeHtml from 'sanitize-html';

const SanitizeHtml: React.FC<{
  html: string;
}> = ({ html }) => {
  const clean = sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      'b',
      'ul',
      'li',
      'i',
    ]),
  });
  return <span dangerouslySetInnerHTML={{ __html: clean }} />;
};
export default SanitizeHtml;
