import React, { FC } from 'react';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import { ConfirmUserContent } from './ConfirmUserContent';
import { UserModel as User } from '../../../../Services/MeService';

export const ConfirmUser: FC<{
  user: User;
  type: string;
  onNext: () => void;
  onBack: () => void;
  onChangeUser: () => void;
}> = ({ user, type, onNext, onBack, onChangeUser }) => {
  const heading = `Book on behalf of someone: ${type}`;
  useDocumentTitle([heading, 'Confirm User']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <ConfirmUserContent
        user={user}
        type={type}
        onNext={onNext}
        onChangeUser={onChangeUser}
      />
    </BaseContainer>
  );
};
