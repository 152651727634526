import { getDateParameterStringEnd } from './../Helpers/DateHelper';
import { BookingType } from 'Booking/BookingType';
import {
  getDateParameterStringStart,
  getDateAsString,
} from 'Helpers/DateHelper';
import { TimeSpan } from './BookingService';
import { getWithAuth } from './ServiceBase';
import { generateParamsString } from 'Helpers/Utils';

export interface FloorAvailabilityModel {
  floorId: number;
  floorName: string;
  numberOfBookingPeriodsAvailable: number;
  resources: ResourceAvailabilityModel[];
  roomNoMatch: boolean;
  seatingCapacity: number;
}

export interface BuildingAvailabilityModel {
  buildingId: number;
  floorName: string;
  numberOfBookingPeriodsAvailable: number;
  floors: FloorAvailabilityModel[];
}

export interface ResourceFeatureOptions {
  resourceId: number;
  featureOptionId: number;
  resourceFeatureOptionName: string;
  resourceFeatureOptionIcon: string;
  resourceFeatureDefaultSelected: boolean;
}

export interface ResourceAvailabilityModel {
  resourceId: number;
  resourceType: number;
  resourceFeatureOptions?: Array<ResourceFeatureOptions> | [];
  name: string;
  deskNumber?: number;
  startDateTime?: Date;
  duration?: TimeSpan;
  isRestricted?: boolean;
  seatingCapacity?: number;
}

export interface FilterModel {
  resourceAttributeId: number;
  resourceAttributeName: string;
  resourceAttributeNoteText: string;
  resourceAttributeIcon: string;
  isDefaultSelected: boolean;
}

export interface FilterGroupModel {
  resourceAttributeGroupId: number;
  resourceAttributeGroupName: string;
  resourceAttributes: Array<FilterModel>;
  isMultipleSelection: boolean;
}

export interface BuildingSummaryModel {
  buildingId: number;
  resourceType: number;
  availabilitySummary: [
    {
      startDate: string;
      endDate: string;
      duration: {
        ticks: number;
        days: number;
        hours: number;
        milliseconds: number;
        minutes: number;
        seconds: number;
        totalDays: number;
        totalHours: number;
        totalMilliseconds: number;
        totalMinutes: number;
        totalSeconds: number;
      };
      resourcesAvailableCount: number;
      resourcesBookedCount: number;
      availableBookingsCount: number;
      existingBookingsCount: number;
      resourcesAvailableWithFeatureSetCount: number;
    }
  ];
}

export interface MeetingRoomAvailabilityModel {
  floorId: number;
  floorName: string;
  floorNumber: string;
  numberOfBookingPeriodsAvailable: number;
  resources: ResourceAvailabilityModel[];
  roomNoMatch: boolean;
  totalResources: number;
  page: number;
  pageSize: number;
}

export interface MeetingRoomQueryStringParamModel {
  buildingId: number;
  floorId: number;
  startDate: string;
  endDate: string;
  selectedAttributeId?: string;
  imageFormat: string;
  resourceType: BookingType;
  meetingRoomCapacity: number;
  page?: number;
  restrictedResourcesOnly: boolean;
  afterNoMatch: boolean;
}

export const getAvailableMeetingRooms = async (
  params: MeetingRoomQueryStringParamModel
): Promise<MeetingRoomAvailabilityModel> => {
  const { buildingId, floorId, ...rest } = params;
  let url = `${process.env.REACT_APP_API_URI}/api/Bookings/${buildingId}/floors/${floorId}/availability?
`;
  url += generateParamsString({ ...rest });
  return getWithAuth<MeetingRoomAvailabilityModel>(url);
};

export const getAvailableDesks = async (
  buildingId: number,
  floorId: number,
  date: Date,
  selectedAttributeIds?: Array<number | string>,
  isNoMatch?: boolean
): Promise<FloorAvailabilityModel> => {
  const startDate = getDateParameterStringStart(date);
  const endDate = getDateParameterStringEnd(date);
  const selectedAttributesString =
    selectedAttributeIds && selectedAttributeIds.length
      ? `&selectedAttributeId=${selectedAttributeIds.join(',')}`
      : '';
  const deskUrl = `${process.env.REACT_APP_API_URI}/api/Bookings/${buildingId}/floors/${floorId}/availability?startDate=${startDate}&endDate=${endDate}&imageFormat=SVG${selectedAttributesString}&resourceType=${BookingType.Desk}&afterNoMatch=${isNoMatch}`;
  return getWithAuth<FloorAvailabilityModel>(deskUrl);
};

export const getAvailableCarSpaces = async (
  buildingId: number,
  date: Date
): Promise<BuildingAvailabilityModel> => {
  const startDate = getDateParameterStringStart(date);
  const endDate = getDateParameterStringEnd(date);
  const resourceUrl = `${process.env.REACT_APP_API_URI}/api/Bookings/${buildingId}/availability?startDate=${startDate}&endDate=${endDate}&resourceType=${BookingType.CarSpace}`;
  return getWithAuth<BuildingAvailabilityModel>(resourceUrl);
};

export const getAvailableSiteVisits = async (
  buildingId: number,
  date: Date
): Promise<BuildingAvailabilityModel> => {
  const startDate = getDateParameterStringStart(date);
  const endDate = getDateParameterStringEnd(date);
  const resourceUrl = `${process.env.REACT_APP_API_URI}/api/Bookings/${buildingId}/availability?startDate=${startDate}&endDate=${endDate}&resourceType=${BookingType.SiteVisit}`;
  return getWithAuth<BuildingAvailabilityModel>(resourceUrl);
};

export const getFilters = async (
  resourceType?: BookingType,
  resourceId = -1
): Promise<Array<FilterGroupModel>> => {
  let resourceUrl = `${process.env.REACT_APP_API_URI}/api/resource/attributes?`;
  const params = {
    imageFormat: 'SVG',
    resourceId: resourceId,
    resourceType: resourceType,
  };
  resourceUrl += generateParamsString(params);

  return getWithAuth<Array<FilterGroupModel>>(resourceUrl);
};

export const getAvailableResources = (
  buildingId: number,
  date: Date,
  bookingType: BookingType
): Promise<BuildingAvailabilityModel> => {
  const startDate = getDateParameterStringStart(date);
  const endDate = getDateParameterStringEnd(date);
  const availabilityUrl = `${process.env.REACT_APP_API_URI}/api/Bookings/${buildingId}/availability?startDate=${startDate}&endDate=${endDate}&resourceType=${bookingType}`;
  return getWithAuth(availabilityUrl);
};

export const getAvailableDays = (
  buildingId: number,
  startDate: Date,
  endDate: Date,
  resourceType?: BookingType
): Promise<BuildingSummaryModel> => {
  const startDateString = getDateAsString(startDate);
  const endDateString = getDateAsString(endDate);
  const apiUrl = `${process.env.REACT_APP_API_URI}/api/Buildings/${buildingId}/summary?startDate=${startDateString}&endDate=${endDateString}&resourceType=${resourceType}`;

  return getWithAuth<BuildingSummaryModel>(apiUrl);
};
