import React from 'react';
import styled, { css } from 'styled-components';
import { UserModel } from 'Services/MeService';
import { StatusCard } from '../StatusCard/StatusCard';
import { CheckCircle, Error } from '@material-ui/icons';

export interface BookingStatusModel {
  user: UserModel;
  status:
    | 'PENDING_CONFIRMATION'
    | 'MAX_BOOKINGS'
    | 'RESOURCE_CONFLICT'
    | 'UNKNOWN_ERROR'
    | 'BOOKED';
  resourceName: string;
}

export interface Props {
  successList: BookingStatusModel[];
  failureList: BookingStatusModel[];
}

export enum Status {
  SUCCESS = 'PENDING_CONFIRMATION',
}

const renderComponent = (
  list: BookingStatusModel[],
  icon: React.ReactElement,
  text: string
) => (
  <MessageWrapper>
    <IconWrapper>
      {icon}
      <Bookings>
        <MessageText>{text}</MessageText>
      </Bookings>
    </IconWrapper>

    <TextWrapper>
      {list.map((item, index) => (
        <StatusCard
          status={item.status}
          resourceName={item.resourceName}
          user={item.user}
          key={index}
        />
      ))}
    </TextWrapper>
  </MessageWrapper>
);

export const BookingStatus: React.FC<Props> = (props: Props) => {
  const { successList, failureList } = props;

  return (
    <ContainerSingleColumn>
      {!!failureList.length &&
        renderComponent(
          failureList,
          <FailIcon />,
          'We were unsuccessful with the following reservations'
        )}
      {!!successList.length && (
        <>
          {renderComponent(
            successList,
            <SuccessIcon />,
            'We were successful with the following reservations'
          )}

          <MessageTextWrapper>
            <MessageTextBold>What’s next?</MessageTextBold>
            <MessageText>
              Each colleague with a successful reservation will need to confirm
              this booking on myWorkplace app before it cancels out in
              <MessageTextBold> 24 hours</MessageTextBold> from now.
            </MessageText>
          </MessageTextWrapper>
        </>
      )}
    </ContainerSingleColumn>
  );
};

/**
 * Styles
 */
const ContainerSingleColumn = styled.div`
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 4px;
  padding: 30px 30px 45px;
  margin-top: 20px;

  @media (max-width: 600px) {
    padding: 20px 28px 44px 20px;
    width: auto;
  }
`;

const IconStyles = css`
  @media (max-width: 600px) {
    font-size: 24px !important;
  }
  font-size: 48px !important;
`;

const SuccessIcon = styled(CheckCircle)`
  ${IconStyles};
  color: ${({ theme }) => theme.palette.greenLight};
`;

const FailIcon = styled(Error)`
  ${IconStyles};
  color: ${({ theme }) => theme.palette.redLight};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    align-items: flex-start;
  }
`;

const MessageWrapper = styled.div`
  &:nth-child(2) {
    margin-top: 38px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 66px;
  padding-left: 5px;
  max-width: 500px;

  @media (max-width: 600px) {
    margin-left: 32px;
  }
`;
const Bookings = styled.div`
  @media (max-width: 600px) {
    padding-left: 14px;
  }
  padding-left: 23px;
`;
const MessageText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  line-height: normal;
  font-size: 18px;
`;

const TextStyles = css`
  color: ${({ theme }) => theme.primaryBlack};
  line-height: normal;
  font-size: 18px;
`;

const FlexStyles = css`
  display: flex;
  flex-direction: column;
`;

const MessageTextWrapper = styled.div`
  ${FlexStyles}
  margin-top: 20px;
  margin-left: 40px;
  padding-left: 30px;
  max-width: 460px;

  @media (max-width: 600px) {
    margin-left: 0;
    padding-left: 5px;
  }
`;

const MessageTextBold = styled.span`
  font-weight: 700;
  ${TextStyles}
`;
