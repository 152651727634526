import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export interface Props {
  onGoBack: () => void;
  floorPlan: { imageSrc: string; image: string };
}

export const FloorPlan: React.FC<Props> = ({ onGoBack, floorPlan }) => {
  const theme = useTheme();
  return (
    <BaseContainer>
      <Header>
        <TextWrapper>
          <HeaderText>Floorplan </HeaderText>
        </TextWrapper>
        <IconActionButton
          onClick={onGoBack}
          disableRipple
          disableFocusRipple
          aria-label="Close"
          role="button"
        >
          <Close style={{ fontSize: '28px', color: theme.primaryWhite }} />
        </IconActionButton>
      </Header>
      <ImageContainer>
        <Image src={floorPlan.imageSrc} alt={floorPlan.image} />
      </ImageContainer>
    </BaseContainer>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 100px;
  background-color: ${(props) => props.theme.primaryBlack};
  box-sizing: border-box;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  object-position: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 90px;
  background-color: ${(props) => props.theme.secondaryBlack};
  box-sizing: border-box;
`;

const TextWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-basis: 90%;
`;

const HeaderText = styled.span`
  color: ${(props) => props.theme.primaryWhite};
  font-size: 20px;
`;
const IconActionButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.primaryWhite} !important;
  }
`;
