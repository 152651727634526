import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { AppHeader } from 'AppHeader';
import { NewBooking } from 'Booking/NewBooking';
import { UserBookings } from 'Booking/UserBookings';
import { BaseContainer } from 'Shared/BaseContainer';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { AppEnvironment } from 'Configs/EnvironmentConfig';
import EnvironmentFooter from 'components/Footer/EnvironmentFooter';
import { MeModel } from 'Services/MeService';

export interface Props {
  tenantDetails: MeModel;
}

export const BookingMain: React.FC<Props> = ({ tenantDetails }) => {
  useDocumentTitle(['Home']);

  const [productionEnvironment] = useState(() =>
    process.env.REACT_APP_ENVIRONMENT === AppEnvironment.Production
      ? true
      : false
  );

  return (
    <Container>
      <GridContainer>
        <Grid container direction="column">
          <HeaderGrid container item>
            <AppHeader />
          </HeaderGrid>
          <MainContainer role="main">
            <NewBookingGrid item>
              <NewBooking />
            </NewBookingGrid>
            <Grid item>
              <Padding>
                <UserBookings />
              </Padding>
            </Grid>
          </MainContainer>
        </Grid>
      </GridContainer>
      <FooterContainer>
        {!productionEnvironment ? (
          <EnvironmentFooter
            label={`Tenant: ${tenantDetails?.tenant} | Environment: ${process.env.REACT_APP_ENVIRONMENT} | User Id: ${tenantDetails?.userId} | Email: ${tenantDetails?.email}`}
          />
        ) : (
          <EnvironmentFooter
            label={`User Id: ${tenantDetails?.userId} | Email: ${tenantDetails?.email}`}
          />
        )}
      </FooterContainer>
    </Container>
  );
};

const GridContainer = styled.div`
  min-height: 100%;
  position: relative;
  min-height: calc(100% - 70px);
`;

const FooterContainer = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  height: 20px;
  margin: 0;
  clear: both;
  width: 100%;
  position: relative;
`;

const Container = styled(BaseContainer)`
  height: 100%;
`;

const HeaderGrid = styled(Grid)`
  @media (max-width: 600px) {
    padding-top: 5px;
    height: 65px;
  }

  @media (min-width: 601px) {
    padding-top: 15px;
    height: 125px;
  }
`;

const NewBookingGrid = styled(Grid)`
  @media (max-width: 600px) {
    padding-top: 10px;
  }

  @media (min-width: 601px) {
    padding-top: 30px;
  }
`;

export const Padding = styled.div`
  @media (max-width: 600px) {
    padding-top: 34px;
  }

  @media (min-width: 601px) {
    padding-top: 54px;
  }
`;

const MainContainer = styled.div``;
