import { AuthError } from 'msal';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { AuthModule } from 'Security/Auth';
import { BookingModel, ConfirmationModel } from './BookingService';

const auth = new AuthModule();

export const getWithAuth = async <T>(url: string): Promise<T> => {
  return auth
    .getAccessToken()
    .then((token: string) => {
      return axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    })
    .catch((error: AuthError) => {
      console.log(error.message);
      throw new Error();
    });
};

export const putWithAuth = async <T>(url: string): Promise<T> => {
  return auth
    .getAccessToken()
    .then((token: string) => {
      return axios
        .put(
          url,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    })
    .catch((error: AuthError) => {
      console.log(error.message);
      throw new Error();
    });
};

export const postWithAuth = async <T>(
  url: string,
  model?: BookingModel | ConfirmationModel | ConfirmationModel[]
): Promise<T> => {
  return auth
    .getAccessToken()
    .then((token: string) => {
      return axios
        .post(url, model, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    })
    .catch((error: AuthError) => {
      console.log(error.message);
      throw new Error();
    });
};

export const getImageWithAuth = async <T>(
  url: string,
  fileName: string
): Promise<T | File> => {
  return auth
    .getAccessToken()
    .then((token: string) => {
      return axios
        .get(url, {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response: AxiosResponse) => {
          return new File([response.data], `${fileName}.jpg`);
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    })
    .catch((error: AuthError) => {
      console.log(error.message);
      throw new Error();
    });
};
