import React from 'react';
import styled from 'styled-components';
import { AppFooter } from 'AppFooter';
import { BookingStepHeader } from '../BookingStepHeader';
import { ArrowForwardIos } from '@material-ui/icons';
import { CovidDeclarationContent } from './CovidDeclarationContent';
import { BaseContainer } from 'Shared/BaseContainer';
import { useDocumentTitle } from 'Helpers/CustomHooks';

export const ArrowIcon = styled(ArrowForwardIos)`
  color: ${({ theme }) => theme.primaryWhite};
  height: 15px;
  width: 15px;
  padding-top: 8px;
`;

export const CovidDeclaration: React.FC<{
  onNext: () => void;
  onBack: () => void;
}> = ({ onNext, onBack }) => {
  useDocumentTitle(['Covid19 Declaration']);

  return (
    <BaseContainer>
      <BookingStepHeader heading="Covid19 Declaration" onBack={onBack} />
      <CovidDeclarationContent onNext={onNext} />
      <AppFooter />
    </BaseContainer>
  );
};
