import React, { useCallback } from 'react';
import {
  Container,
  Grid,
  useMediaQuery,
  useTheme as ut,
} from '@material-ui/core';
import styled from 'styled-components';
import { BookingType } from './BookingType';
import { useHistory } from 'react-router-dom';
import { AppRoute } from 'Routes';
import { NewBookingContent } from './NewBookingContent';
import { Title } from 'Shared/Title';

const BookingOptionsGrid = styled(Grid)`
  @media (max-width: 600px) {
    padding-top: 10px;
  }

  @media (min-width: 601px) {
    padding-top: 30px;
  }
`;

export const NewBooking: React.FC = () => {
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));
  const history = useHistory();

  const handleBookingOptionSelect = useCallback((type: BookingType) => {
    switch (type) {
      case BookingType.Desk:
        history.push(AppRoute.Desk);
        break;
      case BookingType.Room:
        history.push(AppRoute.MeetingRoom);
        break;
      case BookingType.SiteVisit:
        history.push(AppRoute.SiteVisit);
        break;
      case BookingType.CarSpace:
        history.push(AppRoute.CarPark);
        break;
      case BookingType.BehalfOfColleague:
        history.push(AppRoute.BookingBehalfOfColleague);
        break;
      case BookingType.BehalfOf:
        history.push(AppRoute.BookingBehalfOf);
        break;
    }
  }, []);

  return (
    <Container style={{ maxWidth: '94%', paddingLeft: 10, paddingRight: 10 }}>
      <Title text="What do you want to book?" />
      <BookingOptionsGrid
        container
        direction="row"
        spacing={isMobileView ? 2 : 3}
      >
        <NewBookingContent onBookingSelect={handleBookingOptionSelect} />
      </BookingOptionsGrid>
    </Container>
  );
};
