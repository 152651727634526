import { format } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const daysInWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const colon = '%3A';

export const isWeekday = (date: Date): boolean => {
  const day = date.getDay();
  return day !== 6 && day !== 0;
};

export const getDayName = (date: Date): string => {
  return daysInWeek[date.getDay()];
};

export const getLocalTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getDateAllDayParameterString = (
  dateObj: Date,
  startTime: boolean
): string => {
  // deep copy the date Object
  const newDate = new Date(dateObj);
  startTime ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 59);
  const date = newDate.getDate();
  // month = 0 Means January, thus + 1
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const dateString = [year, month, date];

  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const seconds = newDate.getSeconds();
  return dateString.join('-') + ` ${hours}${colon}${minutes}${colon}${seconds}`;
};

export const getDateAsString = (dateObj?: Date, separator = '-'): string => {
  const newDate = dateObj ?? new Date();

  // Pad month and day with leading zero
  // @see: https://stackoverflow.com/a/3605248
  const dd = ('0' + newDate.getDate()).slice(-2);
  const mm = ('0' + (newDate.getMonth() + 1)).slice(-2);
  const yyyy = newDate.getFullYear().toString();

  return [yyyy, mm, dd].join(separator);
};

export const getDateStringWithoutTimeStamp = (dateString: string): string =>
  dateString.split('T')[0];

export const getDateParameterStringStart = (dateObj: Date): string => {
  return getDateAllDayParameterString(dateObj, true);
};

export const getDateParameterStringEnd = (dateObj: Date): string => {
  return getDateAllDayParameterString(dateObj, false);
};

/**
 * Return eight business days starting today
 * Number of days span includes weekends unless includeWeekends is false.
 * E.g.:
 * Thu Fri [Sat] [Sun] Mon Tue Wed Thu
 *
 * @param numOfDays number number of days to include. Default 8
 * @param includeWeekends boolean include weekends. Default true
 * @returns array of dates
 */
export const getBusinessDays = (
  numOfDays = 8,
  includeWeekends = true
): Date[] => {
  const availableDays = [];
  const maxNumOfDays = includeWeekends ? numOfDays : numOfDays - 2;
  let date = new Date();

  while (availableDays.length < maxNumOfDays) {
    if (isWeekday(date) || includeWeekends) {
      availableDays.push(date);
    }

    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);

    date = tomorrow;
  }

  return availableDays;
};

/**
 * Return string formatted date in yyyy-MM-ddTHH:mm:ss
 *
 * @param date date object
 * @returns string formatted date
 */
export const getFormattedDateTime = (date: Date): string => {
  return `${format(date, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ss')}`;
};

/**
 *
 * @param num number to pad with zero
 * @param pad number of positions to pad with; defaults to 2
 * @returns zero padded string
 */
export const zeroPad = (num: number, pad = 2): string =>
  String(num).padStart(pad, '0');

/**
 *
 * @param date date object to encode to UTC string
 * @returns encoded UTC string e.g.
 */
export const encodeUtcDate = (date: Date): string => {
  let encodedOffset = '';
  // -- convert date to correct UTC string format
  const bookedDate = dayjs(new Date(date).setHours(0, 0, 0, 0))
    .local()
    .format();

  // -- deconstruct date string and encode "+" or "-"
  const dateNoOffset = bookedDate.slice(0, 19);
  const utcOffset = bookedDate.slice(19, 25);

  if (utcOffset[0] === '+') {
    encodedOffset = utcOffset.replace('+', '%2b');
  } else if (utcOffset[0] === '-') {
    encodedOffset = utcOffset.replace('-', '%2d');
  }

  return dateNoOffset + encodedOffset;
};
