import { Container, Typography } from '@material-ui/core';
import styled from 'styled-components';
import React, { useEffect, useState, FocusEvent } from 'react';
import { Padding } from 'Booking/BookingMain';
import { Title } from 'Shared/Title';
import { getRecentBookedUsers, UserModel, getUsers } from 'Services/MeService';
import { RecentUserList } from 'Booking/RecentUsers/RecentUserList';
import { UserModel as RecentUser } from '../../../Services/MeService';
import { Loading } from 'Loading/Loading';
import { UserSearchBar } from './UserSearchBar';
import { Filters } from './SearchOutcomes';

export enum SearchPrompt {
  empty = '',
  searchPrompt = 'Search by name',
  searchProgress = 'Searching...',
  searchNoMatch = `We didn't find any matches`,
  searchFailed = `Sorry, an error has occurred`,
}

export const SearchUserContent: React.FC<{
  onNext: (user: RecentUser) => void;
  type: string;
  loggedInEmail: string;
}> = ({ onNext, loggedInEmail }) => {
  const minCharsToStartSearch = 2;
  const [isLoaded, setIsLoaded] = useState(false);
  const [displayRecentUserList, setDisplayRecentUserList] = useState(true);
  const [recentUsers, setRecentUsers] = useState<UserModel[]>([]);
  const [prompt, setPrompt] = useState(SearchPrompt.empty);
  const [searchString, setSearchString] = useState<string>();
  const [searchResults, setSearchResults] = useState<UserModel[]>([]);

  const filterUsers = (users: RecentUser[]) =>
    users.filter((d) => d.email.toLowerCase() !== loggedInEmail.toLowerCase());

  useEffect(() => {
    getRecentBookedUsers()
      .then((res) => {
        const users = res['users'];
        if (!!users && users.length > 0) {
          const filteredList = filterUsers(users);
          setRecentUsers(filteredList);
        } else {
          setDisplayRecentUserList(false);
        }
      })
      .catch(() => {
        setDisplayRecentUserList(false);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [isLoaded]);

  // Perform a search only if necessary
  useEffect(() => {
    if (searchString === undefined) {
      return;
    }

    if (searchString.length < minCharsToStartSearch) {
      setPrompt(SearchPrompt.searchPrompt);
      setSearchResults([]);
    } else {
      setPrompt(SearchPrompt.searchProgress);

      getUsers(searchString)
        .then((res) => {
          const userList = filterUsers(res.users);

          setSearchResults(userList.slice(0, 10));

          if (userList.length > 0) {
            setPrompt(SearchPrompt.empty);
          } else {
            setPrompt(SearchPrompt.searchNoMatch);
          }
        })
        .catch(() => {
          setPrompt(SearchPrompt.searchFailed);
          setSearchResults([]);
        });
    }
  }, [searchString]);

  if (!isLoaded) {
    return <Loading />;
  }

  const onSearchFocus = (): void => {
    setDisplayRecentUserList(false);

    if (!searchResults.length) {
      setPrompt(SearchPrompt.searchPrompt);
    }
  };

  const onSearchBlur = (event: FocusEvent): void => {
    const groupName = event?.relatedTarget?.getAttribute('data-group-name');

    if (groupName !== 'user-search') {
      setDisplayRecentUserList(true);
    }

    if (!searchResults.length) {
      setPrompt(SearchPrompt.empty);
    }
  };

  const onSearchReset = (): void => {
    setDisplayRecentUserList(true);
    setSearchString('');
    setSearchResults([]);
  };

  const onChange = (userInput: string): void => {
    // Trim spaces and leading commas
    // then remove repeating allowable special characters
    // then remove all other special characters
    // and finally replace comma with space character.
    const newSearchString = userInput
      .replace(/(^[,\s]+)|([,\s]+$)/g, '')
      .replace(Filters.repeating, '$1')
      .replace(Filters.removable, '')
      .replace(Filters.replaceWithSpace, ' ')
      .split(' ')
      .filter((f) => f.length > 1)
      .join(' ');

    if (newSearchString !== searchString) {
      setSearchString(newSearchString);
    }
  };

  return (
    <Container role="main">
      <Padding />
      <Title text="Who are you booking for?" />
      <HintText>Search by name</HintText>
      <SearchBarSpacing>
        <UserSearchBar
          prompt={prompt}
          debounce={500}
          results={displayRecentUserList ? [] : searchResults}
          onChange={onChange}
          onFocus={onSearchFocus}
          onBlur={onSearchBlur}
          onReset={onSearchReset}
          onSelect={onNext}
        />
      </SearchBarSpacing>
      {isLoaded && !!recentUsers.length && displayRecentUserList && (
        <SearchBarSpacing>
          <RecentUserList
            heading="Recent people you have booked for"
            users={recentUsers}
            onNext={onNext}
            isGroupBooking={false}
          />
        </SearchBarSpacing>
      )}
    </Container>
  );
};

const SearchBarSpacing = styled.div`
  margin-bottom: 44px;
`;

export const HintText = styled(Typography)`
  color: ${({ theme }) => theme.primaryGray};

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 14px;
  }

  margin-top: 30px;
  margin-bottom: 5px;
`;
