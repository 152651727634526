import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthModule } from 'Security/Auth';
import { BookingMain } from 'Booking/BookingMain';
import CovidDeclarationContext from 'Booking/CovidDeclaration/CovidDeclarationContext';
import { DeskBookingFlow } from 'Booking/DeskBooking/DeskBookingFlow';
import AppContext, { AppConfig } from 'AppContext';
import { getBookingOptions } from 'Services/ConfigService';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import styled from 'styled-components';
import BookingContext from 'Booking/BookingContext';
import { BookingEntryList } from 'Booking/UserBookingsContent';
import { Container } from '@material-ui/core';
import { AppRoute } from 'Routes';
import { BookingViewFlow } from 'Booking/View/BookingViewFlow';
import { CarSpaceBookingFlow } from 'Booking/CarSpaceBooking/CarSpaceBookingFlow';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import { Login } from 'Security/Login';
import { SiteVisitBookingFlow } from 'Booking/SiteBooking/SiteVisitBookingFlow';
import { BookingOnBehalfColleagueFlow } from 'Booking/BookOnBehalf/BookingOnBehalfColleague/BookingOnBehalfColleagueFlow';
import { BookingOnBehalfGroupFlow } from 'Booking/BookOnBehalf/BookingOnBehalfGroup/BookingOnBehalfGroupFlow';
import { AcceptBookingFlow } from 'Booking/AcceptBooking/AcceptReservedBookingFlow';
import { SelectBehalfOfFlow } from 'Booking/BookOnBehalf/BehalfOfSelection/SelectBehalfOfFlow';
import { MeetingRoomBookingFlow } from 'Booking/MeetingRoomBooking/MeetingRoomBookingFlow';
import { getMe } from 'Services/MeService';

export const Padding = styled.div`
  padding-top: 250px;
`;
const App = (): JSX.Element => {
  const authModule = new AuthModule();
  const token = window.sessionStorage.getItem('msal.idtoken');

  const [isCovidDeclarationAgreed, dispatchCovidDeclarationAgree] = useReducer(
    () => {
      return true;
    },
    false
  );
  const [userBookingEntries, setUserBookingEntries] =
    useState<BookingEntryList>(null);

  const [appConfig, setAppConfig] = useState<{
    data: AppConfig;
    isLoadingFailed?: boolean;
  }>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [loginStatus, setLoginStatus] = useState(false);

  const login = async () => {
    authModule
      .getAccessToken()
      .then((result) => {
        if (result && result.length > 0) {
          setLoginStatus(true);
        }
      })
      .catch(() => {
        setLoginStatus(false);
      });
  };

  useEffect(() => {
    if (token) {
      login();
    }
  }, []);

  const handleLogin = useCallback((domainHint: string, loginHint: string) => {
    authModule.setDomainHint(domainHint, loginHint);
    login();
  }, []);

  const setFailedState = () => {
    setAppConfig({
      data: null,
      isLoadingFailed: true,
    });
  };

  useEffect(() => {
    if (!loginStatus) {
      return;
    }
    const fetchBookingOptions = async () => {
      return await getBookingOptions();
    };
    setIsLoading(true);

    const loggedInUser = async () => {
      return await getMe();
    };
    loggedInUser()
      .then((user) => {
        if (user === null || typeof user !== 'object') {
          return;
        } else {
          fetchBookingOptions()
            .then((res) => {
              if (res === null || typeof res !== 'object') {
                setFailedState();
              } else {
                setAppConfig({
                  data: {
                    ...appConfig,
                    bookingOptions: res.bookingOptions,
                    bookOnBehalfOptions: res.bookOnBehalfOptions,
                    bookOnBehalfColleagueOptions: res.bookOnBehalfOptions,
                    loggedInUser: user,
                  },
                });
              }
            })
            .catch(() => setFailedState());
        }
      })
      .catch(() => setFailedState())
      .finally(() => setIsLoading(false));
  }, [retryCount, loginStatus]);

  if (!loginStatus) {
    return (
      <BrowserRouter>
        <Login onLogin={handleLogin} />
      </BrowserRouter>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <LoadingWholePage />
      </Container>
    );
  }

  if (appConfig?.isLoadingFailed) {
    return (
      <ErrorWholePage
        message="An error occurred while loading app configuration!"
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }
  return (
    <AppContext.Provider
      value={{
        bookingOptions: appConfig?.data?.bookingOptions,
        bookOnBehalfOptions: appConfig?.data?.bookOnBehalfOptions,
        bookOnBehalfColleagueOptions:
          appConfig?.data?.bookOnBehalfColleagueOptions,
        loggedInUser: appConfig?.data.loggedInUser,
      }}
    >
      <BookingContext.Provider
        value={{
          bookingEntries: userBookingEntries,
          setBookingEntries: setUserBookingEntries,
        }}
      >
        <CovidDeclarationContext.Provider
          value={{
            isAgreed: isCovidDeclarationAgreed,
            agree: dispatchCovidDeclarationAgree,
          }}
        >
          <BrowserRouter>
            <Switch>
              <Route exact={true} path="/">
                <BookingMain tenantDetails={appConfig?.data.loggedInUser} />
              </Route>
              <Route exact={true} path={AppRoute.Desk}>
                <DeskBookingFlow />
              </Route>
              <Route exact={true} path={AppRoute.MeetingRoom}>
                <MeetingRoomBookingFlow />
              </Route>
              <Route exact={true} path={AppRoute.CarPark}>
                <CarSpaceBookingFlow />
              </Route>
              <Route exact={true} path={AppRoute.SiteVisit}>
                <SiteVisitBookingFlow />
              </Route>
              <Route exact={true} path={AppRoute.Booking}>
                <BookingViewFlow />
              </Route>
              <Route exact={true} path={AppRoute.BookingBehalfOf}>
                <SelectBehalfOfFlow />
              </Route>
              <Route exact={true} path={AppRoute.BookingBehalfOfColleague}>
                <BookingOnBehalfColleagueFlow />
              </Route>
              <Route exact={true} path={AppRoute.BookingBehalfOfGroup}>
                <BookingOnBehalfGroupFlow />
              </Route>
              <Route exact={true} path={AppRoute.AcceptBooking}>
                <AcceptBookingFlow />
              </Route>
            </Switch>
          </BrowserRouter>
        </CovidDeclarationContext.Provider>
      </BookingContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
