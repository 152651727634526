import React from 'react';
import {
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import styled, { css } from 'styled-components';
import FeaturesList from 'Shared/FeaturesList/FeaturesList';
import { ResourceFeatureOptions } from 'Services/ResourceService';
import BookingIcon from '../../../BookingIcon/BookingIcon';
import { BookingOptionModel } from 'Services/ConfigService';
import { BookingType } from '../../../BookingType';
import {
  ComputerRounded,
  LocalParking,
  People,
  TransferWithinAStation,
} from '@material-ui/icons';
import { UserModel } from '../../../../Services/MeService';

export interface Props {
  resourceFeatureOptions: Array<ResourceFeatureOptions>;
  resource: string;
  typeModel: BookingOptionModel;
  user?: UserModel;
}

export const ConfirmationCard: React.FC<Props> = (props) => {
  const { resourceFeatureOptions, resource, typeModel, user } = props;
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <>
      <DivSingleColumn container direction="row" alignItems="center">
        <IconTextGrid item>
          <BookingTypeIcon bookingType={typeModel.resourceType} />
          <TypeText aria-level={3}>{resource}</TypeText>
        </IconTextGrid>
      </DivSingleColumn>

      <Grid item>
        <StyledDivider />
      </Grid>

      <ContainerSingleColumn>
        <Grid container direction="column">
          {typeModel.resourceType == BookingType.Desk &&
            resourceFeatureOptions.length > 0 && (
              <>
                <DeskFeaturesContainer>
                  <FeaturesList
                    featureList={resourceFeatureOptions.map(
                      ({
                        resourceFeatureOptionName,
                        resourceFeatureOptionIcon,
                      }) => ({
                        icon: resourceFeatureOptionIcon,
                        title: resourceFeatureOptionName,
                      })
                    )}
                  />
                </DeskFeaturesContainer>

                <Grid item>
                  <StyledDivider />
                </Grid>
              </>
            )}

          <Grid item>
            <MessageText
              style={{
                paddingLeft: matches ? '15px' : '20px',
                paddingTop: matches ? '8px' : '20px',
                marginBottom: matches ? '0px' : '-8px',
              }}
            >
              Assigned to
            </MessageText>
          </Grid>

          <BookingDetails container item direction="row" alignItems="center">
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: matches ? '15px' : '23px',
                overflow: 'hidden',
              }}
            >
              <div>
                <BookingIcon user={user} />
              </div>
              <ColleagueDetails>
                <ColleagueName>{user.name}</ColleagueName>
                <ColleagueEmail>{user.email}</ColleagueEmail>
              </ColleagueDetails>
            </Grid>
          </BookingDetails>
        </Grid>
      </ContainerSingleColumn>
    </>
  );
};

ConfirmationCard.defaultProps = {
  resource: '',
  user: {
    userId: null,
    name: '',
    email: '',
  },
  resourceFeatureOptions: [],
  typeModel: {} as BookingOptionModel,
};

const BookingTypeIcon: React.FC<{
  bookingType: BookingType;
}> = ({ bookingType }) => {
  switch (bookingType) {
    case BookingType.Desk:
      return <DeskIcon data-testid="bobo-desk" />;
    case BookingType.CarSpace:
      return <CarParkIcon data-testid="bobo-car" />;
    case BookingType.Room:
      return <MeetingRoomIcon data-testid="bobo-room" />;
    case BookingType.SiteVisit:
      return <SiteVisitIcon data-testid="bobo-sitevisit" />;
    default:
      return null;
  }
};

const IconTextGrid = styled(Grid)`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    padding-left: 15px;
  }

  @media (min-width: 601px) {
    padding-left: 31px;
  }
`;

const IconBaseStyle = css`
  @media (max-width: 600px) {
    height: 24px;
    width: 24px;
  }

  @media (min-width: 601px) {
    height: 48px;
    width: 48px;
  }
`;

const DeskIcon = styled(ComputerRounded)`
  ${IconBaseStyle}
`;

const MeetingRoomIcon = styled(People)`
  ${IconBaseStyle}
`;

const SiteVisitIcon = styled(TransferWithinAStation)`
  ${IconBaseStyle}
`;

const CarParkIcon = styled(LocalParking)`
  ${IconBaseStyle}
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  height: 2px;
  background-color: #f2f2f2;
`;

const TypeText = styled(Typography)`
  font-weight: bold;

  @media (max-width: 600px) {
    font-size: 14px;
    padding-left: 15px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
    padding-left: 30px;
  }
`;

const TextBaseStyle = css`
  width: 100%;
  text-transform: none;
  color: ${({ theme }) => theme.secondaryBlack};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1;
  text-align: left;
  line-height: unset;
`;

const ColleagueDetails = styled.div`
  @media (max-width: 600px) {
    padding-left: 6px;
  }

  @media (min-width: 601px) {
    padding-left: 20px;
  }
  padding-right: 76px;
  overflow: hidden;
`;

const ColleagueName = styled(Typography)`
  ${TextBaseStyle}
  color: #393939;
  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const ColleagueEmail = styled(Typography)`
  ${TextBaseStyle}
  color: #393939;
  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 16px;
  }
`;

const MessageText = styled(Typography)`
  ${TextBaseStyle}
  color: #000000;
  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const ContainerSingleColumn = styled(Container)`
  background-color: ${({ theme }) => theme.primaryWhite} !important;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0px;
  max-width: 100%;
`;

const DivSingleColumn = styled(Grid)`
  background-color: ${({ theme }) => theme.primaryWhite} !important;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  @media (max-width: 600px) {
    height: 64px;
  }

  @media (min-width: 601px) {
    height: 72px;
  }
`;

const BookingDetails = styled(Grid)`
  @media (max-width: 600px) {
    height: 62px;
    margin-top: -2px;
  }

  @media (min-width: 601px) {
    height: 95px;
  }
`;

export const DeskFeaturesContainer = styled(Grid)`
  height: auto;
  @media (max-width: 600px) {
    margin-top: 9px;
  }

  @media (min-width: 601px) {
    margin-bottom: -3px;
    margin-top: 10px;
  }
`;

export default ConfirmationCard;
