import { BookingType } from 'Booking/BookingType';
import { LocationModel } from 'Services/LocationService';
import { getWithAuth } from './ServiceBase';
import { getDateAsString } from 'Helpers/DateHelper';
import { generateParamsString } from 'Helpers/Utils';

export interface FloorModel {
  floorId: number;
  floorName: string;
  buildingId: number;
  buildingName: string;
  location: LocationModel;
  bookingDate: Date;
  desksBooked: number;
  totalDesks: number;
  meetingRoomsPercentageBooked: number;
  carParksPercentageBooked: number;
  siteAttendance: number;
  isDisabled: boolean;
}

export interface FloorSummaryModel {
  floorId: number;
  floorName: string;
  floorNumber: string;
  resourceType: BookingType;
  startDate: string;
  endDate: string;
  duration: Record<string, unknown>;
  resourcesAvailableCount: number;
  resourcesBookedCount: number;
  availableBookingsCount: number;
  existingBookingsCount: number;
  resourcesAvailableWithFeatureSetCount: number;
}

export interface BuildingSummaryModel {
  buildingId: number;
  floors: Array<FloorSummaryModel>;
}

const bookingsAPIUrl = `${process.env.REACT_APP_API_URI}/api/Bookings/`;

export const getFloorsByBuildingId = (
  buildingId: number
): Promise<FloorModel[]> => {
  const floorsUrl = `${bookingsAPIUrl}${buildingId}/floors`;
  return getWithAuth<FloorModel[]>(floorsUrl);
};

export const getFloorsByBuildingIdInSummary = (
  buildingId: number,
  resourceType?: BookingType,
  date?: Date,
  selectedAttributeIds?: Array<number | string>
): Promise<BuildingSummaryModel> => {
  const parameters: string = [
    date ? `date=${getDateAsString(date)}` : null,
    selectedAttributeIds && selectedAttributeIds.length
      ? `selectedAttributeId=${selectedAttributeIds.join(',')}`
      : null,
    resourceType ? `resourceType=${resourceType}` : null,
  ]
    .filter(Boolean)
    .join('&');
  const buildingSummaryUrl = `${
    process.env.REACT_APP_API_URI
  }/api/Buildings/${buildingId}/floors/summary${
    parameters.length ? '?' + parameters : ''
  }`;
  return getWithAuth<BuildingSummaryModel>(buildingSummaryUrl);
};

export const getFloorsByBuildingIdAllDay = (
  buildingId: number,
  resourceType?: BookingType
): Promise<FloorModel[]> => {
  const floorsWithDatesUrl = `${bookingsAPIUrl}${buildingId}/floors?resourceType=${resourceType}`;
  return getWithAuth<FloorModel[]>(floorsWithDatesUrl);
};

export const getAccessibleFloorsSummary = (
  buildingId: number,
  date: Date,
  resourceType: BookingType
): Promise<BuildingSummaryModel> => {
  let accessibleFloorSummaryUrl = `${process.env.REACT_APP_API_URI}/api/Buildings/${buildingId}/floors/accessible-summary?`;
  const params = {
    date: getDateAsString(date),
    resourceType: resourceType,
  };
  accessibleFloorSummaryUrl += generateParamsString(params);
  return getWithAuth<BuildingSummaryModel>(accessibleFloorSummaryUrl);
};
