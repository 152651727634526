import React, { ReactElement, useContext } from 'react';
import AppContext from 'AppContext';
import BookingIcon, {
  IconStyling,
  WarningTypes,
} from 'Booking/BookingIcon/BookingIcon';
import styled, { css } from 'styled-components';
import { UserModel } from 'Services/MeService';

export interface Props {
  user?: UserModel;
  status?:
    | 'PENDING_CONFIRMATION'
    | 'MAX_BOOKINGS'
    | 'RESOURCE_CONFLICT'
    | 'UNKNOWN_ERROR'
    | 'BOOKED';
  resourceName?: string;
}

const iconStyle: IconStyling = {
  width: '33px',
  height: '32px',
  textSize: '14px',
  mobileWidth: '33px',
  mobileHeight: '32px',
  mobileTextSize: '14px',
};

const bookingStatus = {
  PENDING_CONFIRMATION: {
    message: 'is reserved',
    statusType: WarningTypes.SUCCESS,
  },

  MAX_BOOKINGS: {
    message: ' is not reserved',
    statusType: WarningTypes.WARNING,
  },

  RESOURCE_CONFLICT: {
    message: ' is not reserved',
    statusType: WarningTypes.WARNING,
  },

  UNKNOWN_ERROR: {
    message: ' is not reserved',
    statusType: WarningTypes.WARNING,
  },
  BOOKED: {
    message: ' is reserved',
    statusType: WarningTypes.SUCCESS,
  },
};

export const StatusCard: React.FC<Props> = (props: Props) => {
  const { userId: loggedInUserId, name: loggedInUserName } =
    useContext(AppContext).loggedInUser;
  const { user, status, resourceName } = props;

  let userName: ReactElement | string = user.name;
  if (parseInt(user.userId) === parseInt(loggedInUserId)) {
    userName = (
      <>
        <strong>You</strong> ({loggedInUserName})
      </>
    );
    user.name = loggedInUserName;
  }

  return (
    <CardWrapper>
      <BookingIcon
        user={user}
        styling={iconStyle}
        status={bookingStatus[status].statusType}
      />
      <LabelText>
        <UserInfo>{userName}</UserInfo>
        <InfoText>
          {`${resourceName} ${bookingStatus[status].message}`}
        </InfoText>
      </LabelText>
    </CardWrapper>
  );
};

const TextStyles = css`
  color: ${({ theme }) => theme.primaryBlack};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 16px;
  letter-spacing: 0.32px;
  text-transform: none;
  font-size: 12px;
`;

const CardWrapper = styled.div`
  display: flex;
  padding: 8px 0;
`;

const LabelText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 2px 0 0 12px;
`;

const UserInfo = styled.span`
  ${TextStyles};
`;

const InfoText = styled.span`
  ${TextStyles};
  font-weight: 700;
`;
