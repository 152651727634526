import { Grid, useMediaQuery, useTheme as ut } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { LocationCard } from './LocationCard';
import { getPastLocations, LocationModel } from 'Services/LocationService';
import { BookingModel } from 'Services/BookingService';
import { ErrorCard } from 'Error/ErrorCard';
import { Loading } from 'Loading/Loading';
import { NextStepTrigger } from './NextStepTrigger';
import { HintText } from './LocationContent';
import { LocationType } from './LocationType';
import AppContext from 'AppContext';
import { ErrorMessage } from 'Error/ErrorMessage';

export const PastLocations: React.FC<{
  onNext: (trigger: NextStepTrigger, bookingModel?: BookingModel) => void;
  onPastLocationsLoad: (locations: LocationModel[], isLoaded: boolean) => void;
  type: string;
}> = ({ onNext, onPastLocationsLoad, type }) => {
  const [isPastLocationsLoading, setIsPastLocationsLoading] = useState(true);
  const [myPastAddress, setMyPastAddress] = useState<{
    pastLocations: LocationModel[];
    isLoadingFailed?: boolean;
  }>(null);
  const appContext = useContext(AppContext);

  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));

  useEffect(() => {
    const bookingType = appContext.bookingOptions.find(
      (x) => x.caption === type
    );
    const pastLocation = async () => {
      return await getPastLocations(bookingType.resourceType);
    };
    pastLocation()
      .then((pastLocations) => {
        setMyPastAddress({ pastLocations: pastLocations });
        onPastLocationsLoad(pastLocations, true);
      })
      .catch(() => {
        setMyPastAddress({
          pastLocations: null,
          isLoadingFailed: true,
        });
        onPastLocationsLoad(null, true);
      })
      .finally(() => {
        setIsPastLocationsLoading(false);
      });
  }, []);

  if (isPastLocationsLoading) {
    return <Loading />;
  }

  if (myPastAddress.isLoadingFailed) {
    return (
      <div>
        <HintText>Locations you have booked in the past</HintText>
        <ErrorCard>
          {ErrorMessage.CANNOTGET} <strong>{'past booking locations'}</strong>.
        </ErrorCard>
      </div>
    );
  }

  if (!myPastAddress.pastLocations || myPastAddress.pastLocations.length == 0) {
    return null;
  }

  return (
    <>
      <HintText id="pastLocationsHint">
        Locations you have booked in the past
      </HintText>
      <Grid container spacing={isMobileView ? 2 : 3} role="list">
        {myPastAddress.pastLocations.map((location: LocationModel, i) => (
          <Grid item xs={12} md={6} key={i}>
            <LocationCard
              text={location.buildingName + ', ' + location.city}
              onSelect={() =>
                onNext(NextStepTrigger.LocationSelect, {
                  buildingId: location.buildingId,
                  buildingName: location.buildingName,
                  city: location.city,
                })
              }
              locationType={LocationType.PAST}
              ariaAttributes={{ 'aria-describedby': 'pastLocationsHint' }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
