import { Grid, useMediaQuery, useTheme as ut } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import {
  getDateAsString,
  getDateStringWithoutTimeStamp,
  getFormattedDateTime,
  getBusinessDays,
} from '../../Helpers/DateHelper';
import { DateCard } from './DateCard';
import { ContainerSingleColumn } from 'Shared/ContainerSingleColumn';
import {
  AppointmentModel,
  BookingModel,
  ResourceModel,
} from 'Services/BookingService';
import { Title } from 'Shared/Title';
import { getUserBookings } from 'Services/BookingService';
import FlowContext from 'Booking/FlowContext';
import { getAvailableDays } from 'Services/ResourceService';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import { Availability } from '../Availability';
import { BookingType } from 'Booking/BookingType';
import { getGuestUserData, Users } from 'Services/GuestUserService';
import { getMe } from 'Services/MeService';
import { isMeetingRoomBooking } from 'Helpers/Utils';

export interface BookingEntryList {
  bookings: BookingModel[];
  isLoadingFailed: boolean;
  isReloadRequired: boolean;
}

export interface DateItem {
  date: Date;
  availability: Availability;
}

export const DateContent: React.FC<{
  onNext: (bookingModel?: BookingModel) => void;
  bookingFlowType: number;
  guestUser?: boolean;
  isGroupBooking?: boolean;
}> = ({ onNext, bookingFlowType, guestUser, isGroupBooking }) => {
  const maxSiteVisitBookingsPerDay = 10;
  const bookingModel: BookingModel = useContext(FlowContext).bookingModel;
  const { buildingId, resourceType } = bookingModel;

  const [loading, setLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [dates, setDates] = useState<DateItem[]>([]);
  const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>();

  /**
   * Return already booked days in an array
   *
   * @param bookings existing bookings returned from API
   * @param businessDays array of business days
   * @returns array of already booked days
   */
  const getBookedDays = (bookings, businessDays) => {
    const daysBooked = [];
    bookings = bookings || [];
    const bookingsDates = bookings.map((x) => {
      const responseDate = new Date(x.startDateTime);
      const dateString = getDateAsString(responseDate);

      if (x.resource.type === bookingFlowType) {
        return dateString;
      }
    });

    bookingsDates.forEach((bookingDate) => {
      if (businessDays.find((date) => getDateAsString(date) === bookingDate)) {
        daysBooked.push(bookingDate);
      }
    });

    return daysBooked;
  };

  /**
   * Call API asynchronously
   *
   * @param startDate start date
   * @param endDate end date
   * @returns Promise object
   */
  const getDataFromApi = async (startDate, endDate) =>
    await Promise.all([
      getUserBookings(),
      getAvailableDays(buildingId, startDate, endDate, resourceType),
      getLoggedInUser(),
    ]);

  /**
   * Helper to determine availability based on input conditions
   *
   * @param resourcesAvailableCount API parameter
   * @param bookingCount number of existing bookings
   * @param bookingFlow current app UI flow (E.g.: Desk, Car Space)
   * @param bookingLimit max number of site visits allowed per date
   * @param date date from each day in the next business days
   * @param buildingId id for the building you are currently booking
   * @param userBookings existing user bookings returned from API
   * @returns availability according to {Availability} model
   */
  const getAvailability = (
    resourcesAvailableCount,
    bookingCount,
    bookingFlow,
    bookingLimit,
    date,
    buildingId,
    userBookings,
    userTenant = ''
  ) => {
    let availability: Availability = Availability.AVAILABLE;

    if (resourcesAvailableCount <= 0) {
      availability = Availability.NOTAVAILABLE;
    }

    if (bookingCount && bookingFlow !== BookingType.SiteVisit) {
      availability = Availability.ANOTHERBOOKING;
    }

    if (bookingCount && bookingFlow !== BookingType.SiteVisit && guestUser) {
      availability = Availability.BOBOANOTHERBOOKING;
    }

    if (bookingFlow === BookingType.SiteVisit) {
      // -- one site booking per building per day
      let numberOfBookings = 0;
      userBookings.forEach((booking) => {
        const bookingDate = new Date(booking.bookedDate);
        if (bookingDate.toDateString() === date.toDateString()) {
          if (
            buildingId === booking.location.buildingId &&
            booking.resource.type === BookingType.SiteVisit
          ) {
            availability = Availability.ANOTHERSITEBOOKING;
          }
          numberOfBookings++;
        }
        // -- max site visits per day for site visits
        if (
          numberOfBookings >= bookingLimit &&
          booking.resource.type === BookingType.SiteVisit
        ) {
          availability = Availability.MAXSITEVISITS;
        }
      });
    }

    // Enable overflow car parking for Telstra tenant.
    if (
      bookingFlow === BookingType.CarSpace &&
      resourcesAvailableCount <= 0 &&
      userTenant === 'Telstra'
    ) {
      availability = Availability.OVERFLOWPARKINGONLY;
    }

    return availability;
  };

  /**
   * Helper to structure data to pass in to {DateCard}
   *
   * @param businessDays six business days to iterate data
   * @param userBookings current bookings
   * @param availabilityResponse available days to book returned from API
   * @returns array of {DateItem}s
   */
  const setupData = (
    businessDays,
    userBookings,
    availabilityResponse,
    tenant
  ): Array<DateItem> => {
    const bookedDays = getBookedDays(userBookings, businessDays);
    const availableDates = availabilityResponse.availabilitySummary;

    const data = businessDays.map((date: Date) => {
      const existingBookings = bookedDays.find(
        (bookedDate) => getDateAsString(date) === bookedDate
      );
      const existingBookingsCount = existingBookings
        ? existingBookings.length
        : 0;
      const matchingDate = availableDates.find(
        (availableDate) =>
          getDateStringWithoutTimeStamp(availableDate.startDate) ===
          getDateAsString(date)
      );

      const availability: Availability = getAvailability(
        matchingDate.resourcesAvailableCount,
        existingBookingsCount,
        bookingFlowType,
        maxSiteVisitBookingsPerDay,
        date,
        buildingId,
        userBookings,
        tenant
      );

      const dataPoint: DateItem = {
        date,
        availability,
      };

      return dataPoint;
    });

    return data;
  };

  const handleOnRetry = () => {
    setRetryCount(retryCount + 1);
  };

  const getBuildingSummary = async (
    buildingId,
    startDate,
    endDate,
    resourceType
  ) => {
    return await getAvailableDays(buildingId, startDate, endDate, resourceType);
  };

  const getGuestAvailability = (user: Users, businessDays: Date[]) => {
    const userUnavailable = Object.values(user.users[0].availability).filter(
      (date) => date.isAvailable === false
    );

    const data = businessDays.map((date: Date) => {
      const matchingDate = userUnavailable.find((unAvailableDate) => {
        return (
          getDateStringWithoutTimeStamp(unAvailableDate.startDate) ===
          getDateAsString(date)
        );
      });

      if (matchingDate) {
        const resource: ResourceModel = {
          id: null,
          type: resourceType,
          name: null,
        };

        const appointment: AppointmentModel = {
          status: null,
          appointmentId: null,
          userId: null,
          location: null,
          subject: null,
          description: null,
          floorName: null,
          resource: resource,
          startDateTime: getDateAsString(date),
          startDateTimeObj: null,
          duration: null,
          allDay: null,
        };

        return appointment;
      }
    });

    const booked = data.filter((date) => {
      if (date) return date;
    });
    return booked;
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const getLoggedInUser = async () => {
    return await (
      await getMe()
    ).tenant;
  };

  useEffect(() => {
    setLoading(true);

    const businessDays = getBusinessDays();

    if (!isGroupBooking && !isMeetingRoomBooking(bookingFlowType)) {
      if (guestUser) {
        const startDate = new Date().toISOString().split('T')[0];
        const endDate = addDays(startDate, 7).toISOString().split('T')[0];
        getGuestUserData(
          undefined,
          bookingModel.userId,
          startDate,
          endDate,
          true,
          bookingFlowType
        )
          .then((res) => {
            return getGuestAvailability(res, businessDays);
          })
          .then((booked) => {
            getBuildingSummary(
              buildingId,
              businessDays[0],
              businessDays[businessDays.length - 1],
              resourceType
            ).then((days) => {
              const dates = setupData(businessDays, booked, days, '');
              setDates(dates);
            });
          })
          .catch(() => {
            setIsLoadingFailed(true);
          })
          .finally(() => setLoading(false));
      } else {
        getDataFromApi(businessDays[0], businessDays[businessDays.length - 1])
          .then((values) => {
            const dates = setupData(businessDays, ...values);
            setDates(dates);
          })
          .catch(() => setIsLoadingFailed(true))
          .finally(() => setLoading(false));
      }
    } else {
      const groupDates = businessDays.map((date) => {
        const groupDates: DateItem = {
          date: date,
          availability: isMeetingRoomBooking(bookingFlowType)
            ? null
            : Availability.AVAILABLE,
        };
        return groupDates;
      });
      setDates(groupDates);
      setLoading(false);
    }
  }, [retryCount]);

  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));

  if (loading) {
    return <LoadingWholePage />;
  }

  if (isLoadingFailed) {
    return (
      <ErrorWholePage
        message="Sorry, we couldn’t load this page."
        onRetry={handleOnRetry}
      />
    );
  }

  return (
    <ContainerSingleColumn role="main">
      <Title text="When do you want to book?" />
      <div role="list">
        {dates.map(({ date, availability }, i) => (
          <Grid
            key={i}
            item
            xs={12}
            md={12}
            style={{ marginTop: isMobileView ? '22px' : '20px' }}
            role="listitem"
          >
            <DateCard
              onSelect={() => {
                date.setHours(0, 0, 0, 0); //for MVP, all bookings are for All Day.
                onNext({
                  bookedDateObj: date,
                  bookedDate: getFormattedDateTime(date),
                });
              }}
              date={date}
              availability={availability}
              bookingFlowType={bookingFlowType}
              guestUser={guestUser}
              isGroupBooking={isGroupBooking}
            />
          </Grid>
        ))}
      </div>
    </ContainerSingleColumn>
  );
};
