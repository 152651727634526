import { Grid, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import * as React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { generateDurationString } from 'Helpers/Utils';

export interface Props {
  bookingDate: Date;
  bookingDuration: string;
}

export const TimeCard: React.FC<Props> = (props) => {
  const { bookingDate, bookingDuration } = props;
  dayjs.extend(duration);

  const generateMeetingTime = () => {
    const bookingTime = dayjs(bookingDate)
      .format('h:mm A')
      .toString()
      .toLocaleLowerCase();
    return `${bookingTime} ${generateDurationString(bookingDuration)}`;
  };

  return (
    <Container container item direction="row" alignItems="center">
      <DateTime container item xs={2} xl={1} justifyContent="center">
        <TimeIcon />
      </DateTime>
      <DateTime item xs={10} xl={11}>
        <MessageText style={{ paddingTop: 0, textAlign: 'left' }}>
          {generateMeetingTime()}
        </MessageText>
      </DateTime>
    </Container>
  );
};

export default TimeCard;

const Container = styled(Grid)`
  padding-bottom: 24px;
`;

const DateTime = styled(Grid)``;

const TimeIcon = styled(AccessTime)`
  @media (max-width: 600px) {
    height: 24px;
    width: 24px;
  }

  @media (min-width: 601px) {
    height: 48px;
    width: 48px;
  }
`;

const MessageText = styled(Typography)`
  text-transform: none;
  width: 100%;

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;
