/* eslint-disable no-dupe-else-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  UserAgentApplication,
  AuthResponse,
  Account,
  AuthenticationParameters,
  AuthError,
} from 'msal';
import { IdToken } from 'msal/lib-commonjs/IdToken';
import { MSAL_CONFIG } from './MSAL.config';
import jwt_decode from 'jwt-decode';

export class AuthModule {
  myMSALObj: UserAgentApplication;
  account: Account;
  loginRequest: AuthenticationParameters;
  silentProfileRequest: AuthenticationParameters;
  token: string;

  constructor(domainHint?: string, loginHint?: string) {
    this.myMSALObj = new UserAgentApplication(MSAL_CONFIG);
    this.setRequestObjects(domainHint, loginHint);
  }

  public setDomainHint(domainHint: string, loginHint: string): void {
    this.setRequestObjects(domainHint, loginHint);
  }

  private setRequestObjects(domainHint: string, loginHint: string): void {
    this.loginRequest = {
      scopes: [process.env.REACT_APP_SCOPE_PROFILE],
      extraQueryParameters: { domain_hint: domainHint },
      loginHint: loginHint,
    };
    this.silentProfileRequest = {
      scopes: [process.env.REACT_APP_SCOPE_PROFILE],
      extraQueryParameters: { domain_hint: domainHint },
      loginHint: loginHint,
    };
  }

  logout(): void {
    this.myMSALObj.logout();
  }
  async getAccessToken(): Promise<string> {
    // Use cached token from session storage.
    let token = window.sessionStorage.getItem('msal.idtoken');

    // Check if token has expired.
    if (token) {
      const decodedToken = jwt_decode(token);
      const expiry = decodedToken['exp'] * 1000; // convert to milliseconds
      const expiryPadding = 10 * 60 * 1000; // grab a new token 10 minutes before the expiry time
      if (expiry < new Date().valueOf() - expiryPadding) {
        token = null; // set to null; we will get a new token
      }
    }

    if (token) {
      this.token = token;
      return this.token;
    } else {
      // Clear storage caches
      sessionStorage.clear();

      this.myMSALObj.loginRedirect(this.loginRequest);
      return '';
    }
  }
}
