import { postWithAuth, getWithAuth, getImageWithAuth } from './ServiceBase';
import { LocationModel } from './LocationService';
import { FilterModel, ResourceFeatureOptions } from './ResourceService';
import { getDateParameterStringStart } from 'Helpers/DateHelper';
import { BookingType } from 'Booking/BookingType';
import { Availability } from 'Booking/Availability';
import { UserModel } from './MeService';
import { generateParamsString } from 'Helpers/Utils';

export interface ResourceModel {
  id: number;
  type: number;
  name: string;
  featureOptions?: Array<ResourceFeatureOptions>;
  seatingCapacity?: number;
}

export interface TimeSpan {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface AppointmentModel {
  floorId?: number;
  status:
    | 'PENDING_CONFIRMATION'
    | 'PENDING_ACCEPTANCE'
    | 'BOOKED'
    | 'CANCELLED';
  bookerFirstName?: string;
  bookerLastName?: string;
  appointmentId: number;
  userId: string;
  location: LocationModel;
  subject: string;
  description: string;
  floorName: string;
  resource: ResourceModel;
  startDateTime: string; //date time string in resource timezone
  startDateTimeObj: Date; //date time in resource timezone
  duration: TimeSpan;
  allDay: boolean;
  creationDateTime?: string;
}

export interface BookingModel {
  user?: UserModel;
  userId?: string;
  buildingId?: number;
  buildingName?: string;
  city?: string;
  bookedDate?: string;
  bookedDateObj?: Date;
  bookingEndDate?: string;
  bookingStartDate?: string;
  filter?: Array<FilterModel>;
  floorId?: number;
  floorName?: string;
  resourceId?: number;
  resourceName?: string;
  resourceFeatureOptions?: Array<ResourceFeatureOptions>;
  allDay?: boolean;
  subject?: string;
  description?: string;
  revisionNumber?: number;
  duration?: string;
  selectedFilter?: Record<string, unknown>;
  resetFlag?: boolean;
  resourceType?: BookingType;
  resourceAvailability?: Availability;
  /* TODO - Check the key when API is ready*/
  seatingCapacity?: number;
}

export interface ConfirmationModel {
  userId: string;
  bookedDate: string;
  subject: string;
  description: string;
  resourceId: number;
  duration: string;
  allDay: boolean;
  revisionNumber: number;
  status?: 'PENDING_ACCEPTANCE' | 'BOOKED' | 'CANCELLED';
}

export interface GroupConfirmationModel {
  bookerUserId: number;
  bookerFirstName: string;
  bookerLastName: string;
  appointmentId: number;
  userId: number;
  bookedDate: string;
  location: LocationModel;
  floorId: number;
  floorName: string;
  appointmentType: string;
  subject: string;
  description: string;
  resource: ResourceModel;
  creationDateTime: string;
  lastModifiedDateTime: string;
  startDateTime: string;
  duration: TimeSpan;
  allDay: boolean;
  isCanceled: boolean;
  revisionNumber: number;
  bookeeFirstName?: string;
  bookeeLastName?: string;
  status?:
    | 'PENDING_CONFIRMATION'
    | 'MAX_BOOKINGS'
    | 'RESOURCE_CONFLICT'
    | 'UNKNOWN_ERROR'
    | 'BOOKED';
}

interface CancelBookingResult {
  appointmentId: number;
  isCanceled: boolean;
}

/**
 * getUserBookings
 * @param startDate
 * @param endDate
 * @param resourceType
 * @param imageFormat // -- accepted params: "None", "SVG", "Base64"
 * @param includeCancelled
 */
export const getUserBookings = async (
  apiVersion?: string,
  startDate?: string,
  endDate?: string,
  resourceType?: BookingType,
  imageFormat?: string,
  includeCancelled?: boolean
): Promise<AppointmentModel[]> => {
  let userBookingsUrl = `${process.env.REACT_APP_API_URI}/api/Me/bookings?`;
  const params: string[] = [];

  if (startDate) {
    params.push(`startDate=${startDate}`);
  } else {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const start = getDateParameterStringStart(today);
    params.push(`startDate=${start}`);
  }

  if (endDate) params.push(`endDate=${endDate}`);

  if (resourceType) params.push(`resourceType=${resourceType}`);

  if (imageFormat) {
    params.push(`imageFormat=${imageFormat}`);
  } else {
    params.push(`imageFormat=SVG`);
  }

  if (includeCancelled) {
    params.push(`includeCancelled=${includeCancelled}`);
  } else {
    params.push(`includeCancelled=false`);
  }

  apiVersion && params.push(`api-version=${apiVersion}`);

  const urlParams = params.join('&');
  userBookingsUrl += urlParams;

  return getWithAuth(userBookingsUrl);
};

export const saveBooking = (
  confirmationModel: ConfirmationModel
): Promise<ConfirmationModel> => {
  const confirmBookingUrl = `${process.env.REACT_APP_API_URI}/api/Bookings`;
  return postWithAuth(confirmBookingUrl, confirmationModel);
};

export const cancelBooking = (
  bookingId: number
): Promise<CancelBookingResult> => {
  const cancelUrl = `${process.env.REACT_APP_API_URI}/api/Bookings/${bookingId}/cancel`;
  return postWithAuth(cancelUrl);
};

export const confirmReservedBooking = (
  bookingId: number
): Promise<CancelBookingResult> => {
  const confirmUrl = `${process.env.REACT_APP_API_URI}/api/Bookings/${bookingId}/confirm`;
  return postWithAuth(confirmUrl);
};

export const reserveGroupBooking = (
  apiVersion: string,
  confirmationModel: ConfirmationModel[]
): Promise<GroupConfirmationModel[]> => {
  let reserveBookingUrl = `${process.env.REACT_APP_API_URI}/api/bookings/bulk-create?`;

  const params = {
    'api-version': apiVersion,
  };

  reserveBookingUrl += generateParamsString(params);

  return postWithAuth(reserveBookingUrl, confirmationModel);
};

export const getFloorPlan = (
  floorId: number,
  imageName: string
): Promise<File> => {
  const confirmBookingUrl = `${process.env.REACT_APP_API_URI}/api/buildings/floor-plan/${floorId}`;

  return getImageWithAuth(confirmBookingUrl, imageName);
};
