import {
  Container,
  Grid,
  Select,
  useMediaQuery,
  useTheme as ut,
} from '@material-ui/core';
import { useTheme } from 'styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { LocationCard } from './LocationCard';
import { getLocations, LocationModel } from '../../Services/LocationService';
import { Padding } from 'Booking/BookingMain';
import { BookingModel } from 'Services/BookingService';
import styled from 'styled-components';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import AppContext from 'AppContext';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import { Title } from 'Shared/Title';
import LocationContext from './LocationContext';
import { LocationType } from './LocationType';
import _ from 'lodash';

export const ErrorPadding = styled.div`
  padding-top: 150px;
`;

export const AnotherLocationContent: React.FC<{
  onNext: (bookingModel?: BookingModel) => void;
  type?: string;
}> = ({ onNext, type }) => {
  const [allLocations, setAllLocations] = useState<{
    content: LocationModel[];
    isLoadingFailed?: boolean;
  }>();
  const [allStates, setAllStates] = useState<string[]>();
  const [selectedState, setSelectedState] = useState('0');
  const [visibleLocations, setVisibleLocations] = useState<LocationModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const theme = useTheme();
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));

  const appContext = useContext(AppContext);
  const locationContext = useContext(LocationContext);

  useEffect(() => {
    const bookingType = appContext.bookingOptions.find(
      (x) => x.caption === type
    );
    const location = async () => {
      return await getLocations(bookingType.resourceType);
    };
    const mapLocationType = (locations: LocationModel[]) => {
      const mappedLocations: LocationModel[] = [];

      const pastLocations = _.intersectionBy(
        locationContext.pastLocations,
        locations,
        (location) => location.buildingId
      );
      pastLocations.map((location) => {
        location.locationType = LocationType.PAST;
        mappedLocations.push(location);
      });

      const newLocations = _.differenceBy(
        locations,
        mappedLocations,
        (location) => location.buildingId
      );
      newLocations.map((location) => {
        location.locationType = LocationType.NORMAL;
        mappedLocations.push(location);
      });

      return mappedLocations;
    };
    location()
      .then((allLocations) => {
        setAllLocations({ content: mapLocationType(allLocations) });
      })
      .catch(() => {
        setAllLocations({
          content: null,
          isLoadingFailed: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [retryCount]);

  useEffect(() => {
    if (!allLocations?.content) {
      return;
    }
    const distinctStates = Array.from(
      allLocations.content
        .reduce((p, c) => p.add(c.state), new Set<string>())
        .keys()
    );
    setAllStates(distinctStates);
    setVisibleLocations(allLocations.content);
  }, [allLocations]);

  const handleChange = (event) => {
    const filteredLocations = allLocations?.content?.filter((location) =>
      event.target.value !== '0'
        ? location.state === event.target.value
        : location.state !== event.target.value
    );
    setSelectedState(event.target.value);
    setVisibleLocations(filteredLocations);
  };

  if (isLoading) {
    return <LoadingWholePage />;
  }

  if (allLocations) {
    if (allLocations.isLoadingFailed) {
      return (
        <ErrorWholePage
          message={'Sorry, we couldn’t load this page.'}
          iconType={'error'}
          onRetry={() => setRetryCount(retryCount + 1)}
        />
      );
    } else if (!allLocations.content || allLocations.content.length == 0) {
      return (
        <ErrorWholePage
          message={'Sorry, there are no other locations to choose from.'}
          iconType={'info'}
        />
      );
    }
  }

  return (
    !!visibleLocations.length && (
      <Container
        style={{ maxWidth: '90%', paddingLeft: 10, paddingRight: 10 }}
        role="main"
      >
        <Padding />
        <Title text="Choose another location" />
        <Grid container spacing={isMobileView ? 2 : 3}>
          <Grid item xs={12} md={12}>
            <Select
              native
              id="select"
              variant="outlined"
              style={{
                width: 223,
                marginTop: 20,
                marginBottom: !isMobileView ? 35 : 10,
                fontSize: !isMobileView ? 16 : 14,
                height: 48,
                backgroundColor: theme.primaryWhite,
              }}
              value={selectedState}
              onChange={handleChange}
              inputProps={{
                name: 'state',
                'aria-label': 'Choose another location',
              }}
            >
              <option key="-1" value="0">
                All States
              </option>
              {allStates?.map((_item, i) => (
                <option key={i} value={_item}>
                  {_item}
                </option>
              ))}
            </Select>
          </Grid>
          {visibleLocations.map((location, i) => (
            <Grid item xs={12} md={6} key={i}>
              <LocationCard
                text={location.buildingName + ', ' + location.city}
                locationType={location.locationType}
                onSelect={() =>
                  onNext({
                    buildingId: location.buildingId,
                    buildingName: location.buildingName,
                    city: location.city,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  );
};
