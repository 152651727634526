import { useEffect, useRef, useState } from 'react';
import { getFloorPlan } from 'Services/BookingService';

/**
 * Set HTML page title
 *
 * @param titles - Array of titles. Array order should be in generic to specific.
 * E.g.: `useDocumentTitle(['Desk Booking', 'Select Location'])`
 * @param separator - Title separator. Default ' - '.
 */
export const useDocumentTitle = (
  titles: Array<string>,
  separator = ' - '
): void => {
  const appTitle = 'myWorkplace';
  const newDocumentTitle = [appTitle, ...titles].reverse().join(separator);

  useEffect(() => {
    const currentDocumentTitle = document.title;

    if (currentDocumentTitle !== newDocumentTitle) {
      document.title = newDocumentTitle;
    }
  }, [newDocumentTitle]);
};

/**
 * useInterval - Hook to make API calls recursively for polling
 * @param callback - Function to poll
 * @param delay - initial wait time in seconds before retrying
 * @param enabled - optional param, pass false to cancel polling
 * @example Recursively call getDates every 60s:
 * useInterval(getDates, 60);
 */
export const usePolling = <CallbackType>(
  callback: () => Promise<CallbackType>,
  delay: number,
  enabled = true
): void => {
  const savedCallback = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current?.();
    };

    let id = null;

    if (enabled && delay !== null) {
      id = setInterval(tick, delay * 1000);
    }

    return () => clearInterval(id);
  }, [delay, enabled]);
};

export const useImage = (buildingId: number, floorId: number): string => {
  const [imageSrc, setImageSrc] = useState('');
  const floorPlanImage = `${buildingId}-${floorId}`;

  useEffect(() => {
    getFloorPlan(floorId, floorPlanImage)
      .then((res) => {
        if (res) {
          const url = window.URL.createObjectURL(res);
          setImageSrc(url);
        } else {
          setImageSrc('');
        }
      })

      .catch(() => {
        setImageSrc('');
      });
  }, []);

  return imageSrc;
};
