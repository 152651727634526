import React from 'react';
import { BookingType } from 'Booking/BookingType';
import { generateFlowHeader } from 'Helpers/Utils';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BaseContainer } from 'Shared/BaseContainer';
import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { SelectRoomContent } from './SelectRoomContent';
import { BookingModel } from 'Services/BookingService';

interface Props {
  type: string;
  onNext: (meetingRoom: BookingModel) => void;
  onBack: () => void;
  onEdit: () => void;
  bookingHeaderType?: BookingType;
}

export const SelectRoom: React.FC<Props> = (props) => {
  const { type, onBack, onNext, bookingHeaderType, onEdit } = props;
  const heading = generateFlowHeader(bookingHeaderType, type);
  useDocumentTitle([heading, 'Choose a meeting room']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <SelectRoomContent onNext={onNext} onEdit={onEdit} />
    </BaseContainer>
  );
};
