import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme as ut,
} from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import { Padding } from 'Booking/BookingMain';
import { Title } from 'Shared/Title';
import { BookingOption } from 'Booking/BookingOption';
import { BookingType } from 'Booking/BookingType';
import { BookingIcon } from 'Booking/BookingIcon';

export const SelectBehalfOfTypeContent: React.FC<{
  onNext: (isGroupBooking: boolean) => void;
}> = ({ onNext }) => {
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));

  return (
    <Container
      style={{
        maxWidth: '90%',
        paddingLeft: 10,
        paddingRight: 10,
      }}
      role="main"
    >
      <Padding />
      <Title text="What do you want to do?" />
      <BookingOptionsGrid
        container
        direction="row"
        spacing={isMobileView ? 2 : 3}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginTop: isMobileView ? '10px' : '20px' }}
        >
          <BookingOption
            caption={'Book for a group'}
            description={'Book desks for a group of up to 10 people.'}
            type={BookingType.Desk}
            onBookingSelect={() => onNext(true)}
            $isDescriptionVisible={true}
          >
            <BookingIcon bookingType={BookingType.BehalfOf} />
          </BookingOption>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginTop: isMobileView ? '10px' : '20px' }}
        >
          <BookingOption
            caption={'Book for an individual'}
            description={'Reserve any available desk for a colleague.'}
            type={BookingType.Desk}
            onBookingSelect={() => onNext(false)}
            $isDescriptionVisible={true}
          >
            <BookingIcon bookingType={BookingType.BehalfOfColleague} />
          </BookingOption>
        </Grid>
      </BookingOptionsGrid>
    </Container>
  );
};
const BookingOptionsGrid = styled(Grid)`
  @media (max-width: 600px) {
    padding-top: 10px;
  }
  @media (min-width: 601px) {
    padding-top: 30px;
  }
`;
export const HintText = styled(Typography)`
  color: ${({ theme }) => theme.primaryGray};
  @media (max-width: 600px) {
    font-size: 12px;
  }
  @media (min-width: 601px) {
    font-size: 14px;
  }
  margin-top: 30px;
  margin-bottom: 5px;
`;
