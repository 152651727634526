import React from 'react';
import { useTheme } from 'styled-components';
import { ActionButton } from './ActionButton';
import { NextIcon } from './ButtonStyles';

export const NextButton: React.FC<{
  text: string;
  width: number;
  onClick: () => void;
  isDisabled?: boolean;
}> = ({ text, width, onClick, isDisabled }) => {
  const theme = useTheme();
  const disabledStyles = isDisabled
    ? {
        cursor: 'auto',
        boxShadow: 'none',
        border: 'none',
        ':focus': {
          boxShadow: 'none',
        },
      }
    : {};

  return (
    <ActionButton
      text={text}
      onClick={onClick}
      style={{
        width: width,
        backgroundColor: isDisabled
          ? theme.palette.disabledBlue
          : theme.palette.secondary,
        ...disabledStyles,
      }}
    >
      <NextIcon />
    </ActionButton>
  );
};
