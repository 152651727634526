export enum AppRoute {
  Desk = '/desk',
  MeetingRoom = '/meeting-room',
  CarPark = '/car-park',
  SiteVisit = '/site-visit',
  Booking = '/booking',
  AcceptBooking = '/accept-booking',
  BookingBehalfOfColleague = '/book-on-behalf-colleague',
  BookingBehalfOf = '/book-on-behalf',
  BookingBehalfOfGroup = '/book-on-behalf-group',
}
