import React, { useContext, useState } from 'react';
import { SiteVisitBookingStep } from '../BookingSteps';
import { BookingType } from '../BookingType';
import { SelectLocation } from '../Location/SelectLocation';
import { useHistory } from 'react-router-dom';
import CovidDeclarationContext from '../CovidDeclaration/CovidDeclarationContext';
import { CovidDeclaration } from '../CovidDeclaration/CovidDeclaration';
import { SelectDate } from 'Booking/Date/SelectDate';
import { AnotherLocation } from 'Booking/Location/AnotherLocation';
import AppContext from 'AppContext';
import { Confirmation } from 'Booking/Confirmation/Confirmation';
import { BookingModel } from 'Services/BookingService';
import { DoneBooking } from 'Booking/DoneBooking/DoneBooking';
import FlowContext from 'Booking/FlowContext';
import { FlowStep } from 'Booking/FlowStep';
import { NextStepTrigger } from 'Booking/Location/NextStepTrigger';
import { LocationModel } from 'Services/LocationService';
import LocationContext from 'Booking/Location/LocationContext';

export interface SiteVisitBookingStepData {
  currentStep: SiteVisitBookingStep;
  pastSteps: SiteVisitBookingStep[];
}

export const SiteVisitBookingFlow: React.FC = () => {
  const history = useHistory();

  const appContext = useContext(AppContext);
  const covidDeclarationContext = useContext(CovidDeclarationContext);

  const [stepData, setStepData] = useState({
    currentStep: covidDeclarationContext.isAgreed
      ? SiteVisitBookingStep.SelectLocation
      : SiteVisitBookingStep.CovidDeclaration,
    pastSteps: [],
  });
  const [result, setResult] = useState<boolean>(false);
  const [bookingModel, setBookingModel] = useState<BookingModel>({
    resourceType: BookingType.SiteVisit,
  });
  const [locationData, setLocationData] = useState<{
    pastLocations: LocationModel[];
  }>(null);

  const updateStepData = (
    nextStep?: SiteVisitBookingStep,
    avoidCurrentStepRemember = false
  ) => {
    if (nextStep === undefined || nextStep === null) {
      const previousStep = stepData.pastSteps.pop();
      if (previousStep === undefined) {
        history.push('/'); //no previous step, so go to home page.
      } else {
        setStepData({
          currentStep: previousStep,
          pastSteps: stepData.pastSteps,
        });
      }
    } else {
      if (!avoidCurrentStepRemember) {
        stepData.pastSteps.push(stepData.currentStep);
      }
      setStepData({ currentStep: nextStep, pastSteps: stepData.pastSteps });
    }
  };

  const handleSelectLocationNext = (
    trigger: NextStepTrigger,
    SiteVisitBookingModel?: BookingModel,
    pastLocations?: LocationModel[]
  ) => {
    switch (trigger) {
      case NextStepTrigger.LocationSelect:
        updateStepData(SiteVisitBookingStep.SelectDate);
        if (SiteVisitBookingModel) {
          setBookingModel({
            ...bookingModel,
            buildingId: SiteVisitBookingModel.buildingId,
            buildingName: SiteVisitBookingModel.buildingName,
            city: SiteVisitBookingModel.city,
          });
        }
        break;
      case NextStepTrigger.ChooseAnotherLocationSelect:
        setLocationData({ pastLocations });
        updateStepData(SiteVisitBookingStep.ChooseAnotherLocation);
        break;
    }
  };

  const handleCovidDeclationNext = () => {
    covidDeclarationContext.agree();
    updateStepData(SiteVisitBookingStep.SelectLocation, true);
  };

  const handleBookingDone = (result?: boolean) => {
    setResult(result);
    updateStepData(SiteVisitBookingStep.DoneBooking);
  };

  const type = appContext.bookingOptions.find(
    (x) => x.resourceType === BookingType.SiteVisit
  );

  const handleNext = (
    nextStep: SiteVisitBookingStep,
    SiteVisitBookingModel?: BookingModel
  ) => {
    switch (nextStep) {
      case SiteVisitBookingStep.SelectDate:
        if (SiteVisitBookingModel) {
          setBookingModel({
            ...bookingModel,
            buildingId: SiteVisitBookingModel.buildingId,
            buildingName: SiteVisitBookingModel.buildingName,
            city: SiteVisitBookingModel.city,
          });
        }
        break;
      case SiteVisitBookingStep.ConfirmBooking:
        if (SiteVisitBookingModel) {
          setBookingModel({
            ...bookingModel,
            bookedDateObj: SiteVisitBookingModel.bookedDateObj,
            bookedDate: SiteVisitBookingModel.bookedDate,
            allDay: true,
            revisionNumber: 0,
            duration: '00:00:00',
            subject: type.caption,
            description: '',
          });
        }
        break;
    }
    updateStepData(nextStep);
  };

  const handleBack = () => {
    updateStepData();
  };

  return (
    <FlowContext.Provider
      value={{
        currentStep: stepData.currentStep,
        bookingModel,
      }}
    >
      <>
        <FlowStep step={SiteVisitBookingStep.CovidDeclaration}>
          <CovidDeclaration
            onNext={handleCovidDeclationNext}
            onBack={handleBack}
          />
        </FlowStep>
        <FlowStep step={SiteVisitBookingStep.SelectLocation}>
          <SelectLocation
            type={type.caption}
            onNext={handleSelectLocationNext}
            onBack={handleBack}
          />
        </FlowStep>
        <FlowStep step={SiteVisitBookingStep.ChooseAnotherLocation}>
          <LocationContext.Provider
            value={{
              pastLocations: locationData?.pastLocations,
            }}
          >
            <AnotherLocation
              type={type.caption}
              onNext={(location) =>
                handleNext(SiteVisitBookingStep.SelectDate, location)
              }
              onBack={handleBack}
            />
          </LocationContext.Provider>
        </FlowStep>
        <FlowStep step={SiteVisitBookingStep.SelectDate}>
          <SelectDate
            type={type.caption}
            onNext={(date) =>
              handleNext(SiteVisitBookingStep.ConfirmBooking, date)
            }
            onBack={handleBack}
            bookingFlowType={BookingType.SiteVisit}
          />
        </FlowStep>
        <FlowStep step={SiteVisitBookingStep.ConfirmBooking}>
          <Confirmation
            typeModel={type}
            onNext={(result) => handleBookingDone(result)}
            onBack={handleBack}
          />
        </FlowStep>
        <FlowStep step={SiteVisitBookingStep.DoneBooking}>
          <DoneBooking
            type={type.caption}
            bookingResult={result}
            onBack={handleBack}
          />
        </FlowStep>
      </>
    </FlowContext.Provider>
  );
};
