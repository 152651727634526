import { createContext, Dispatch } from 'react';
import { FilterModel } from 'Services/ResourceService';

export interface FeatureOptions {
  filter: FilterModel[];
  seatingCapacity: number;
}

const preferenceContext =
  createContext<[FeatureOptions, Dispatch<FeatureOptions>]>(null);

export default preferenceContext;
