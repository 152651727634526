import React from 'react';
import { ActionButton } from './ActionButton';
import { NextIcon } from './ButtonStyles';

export const BookingButton: React.FC<{
  label: string;
  colour: string;
  width?: number;
  onSelect: () => void;
}> = ({ label, colour, width, onSelect }) => {
  return (
    <ActionButton
      text={label}
      onClick={onSelect}
      style={{
        width: width ? width : 181,
        background: `${colour}`,
        paddingLeft: '35px',
      }}
    >
      <NextIcon />
    </ActionButton>
  );
};
