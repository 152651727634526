import { BookingStepHeader } from 'Booking/BookingStepHeader';
import React, { useContext, useEffect, useState } from 'react';
import { BaseContainer } from 'Shared/BaseContainer';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import {
  BookingModel,
  ConfirmationModel,
  GroupConfirmationModel,
  reserveGroupBooking,
} from 'Services/BookingService';
import { OperationType } from 'Booking/Completion/OperationType';
import FlowContext from 'Booking/FlowContext';
import BookingContext from 'Booking/BookingContext';
import { CompletionContent } from 'Booking/Completion/CompletionContent';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import { reserveGroupBookingVersion as apiVersion } from 'Services/ApiVersionConfig';

export const DoneGroupBooking: React.FC<{
  type: string;
  onBack: () => void;
}> = ({ type, onBack }) => {
  const bookingContext = useContext<{
    bookingModel: BookingModel[];
  }>(FlowContext);
  const bookingModels: BookingModel[] = bookingContext.bookingModel;

  const context = useContext(BookingContext);

  const [loading, setLoading] = useState(true);
  const [bookingStatus, setBookingStatus] = useState<boolean>();
  const [header, setHeader] = useState(`Book for a group: ${type}`);
  const [bookingConfirmations, setBookingConfirmations] = useState<
    GroupConfirmationModel[]
  >([]);

  const heading = header;
  useDocumentTitle([`Book for a group: ${type}`, heading]);

  useEffect(() => {
    const bookingConfirmations: ConfirmationModel[] = bookingModels.map(
      (model) => {
        return {
          userId: model.user.userId,
          bookedDate: model.bookedDate,
          subject: model.subject,
          description: model.description,
          resourceId: model.resourceId,
          duration: model.duration,
          allDay: model.allDay,
          revisionNumber: model.revisionNumber,
        };
      }
    );

    reserveGroupBooking(apiVersion, bookingConfirmations)
      .then((confirmation) => {
        context.setBookingEntries({
          ...context.bookingEntries,
          isReloadRequired: true,
        });
        setBookingStatus(true);
        setBookingConfirmations(confirmation);
        const bookingProblem = confirmation.find((model) => {
          return (
            model.status !== 'BOOKED' && model.status !== 'PENDING_CONFIRMATION'
          );
        });

        bookingProblem
          ? setHeader('Booking Problem!')
          : setHeader('Booking Reserved!');
      })
      .catch(() => {
        setBookingStatus(false);
        setHeader('Booking Problem!');
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <BaseContainer>
      <BookingStepHeader
        heading={heading}
        onBack={onBack}
        isBackButtonHidden={true}
      />

      {loading ? (
        <LoadingWholePage />
      ) : (
        <CompletionContent
          isOperationSuccessful={bookingStatus}
          operationType={!bookingStatus && OperationType.ERROR}
          bookingConfirmations={bookingConfirmations}
        />
      )}
    </BaseContainer>
  );
};
