import { Grid, useMediaQuery, useTheme as ut } from '@material-ui/core';
import AppContext from 'AppContext';
import { Availability } from 'Booking/Availability';
import FlowContext from 'Booking/FlowContext';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import React, { useContext, useEffect, useState } from 'react';
import { BookingModel } from 'Services/BookingService';
import {
  FloorSummaryModel,
  getAccessibleFloorsSummary,
  getFloorsByBuildingIdInSummary,
} from 'Services/FloorService';
import { ContainerSingleColumn } from 'Shared/ContainerSingleColumn';
import { Title } from 'Shared/Title';
import { NoMatchesCard } from '../../Shared/NoMatchesCard';
import { useTheme as styledTheme } from 'styled-components';
import { WarningCard } from 'Shared/WarningCard';
import EditFilter from '../Filter/EditFilter';
import { FloorCard } from './FloorCard';
import {
  FloorAvailabilityModel,
  getAvailableDesks,
} from 'Services/ResourceService';
import { UserModel } from 'Services/MeService';
import { isMeetingRoomBooking } from 'Helpers/Utils';

export const FloorContent: React.FC<{
  onNext: (
    bookingModel?: BookingModel,
    floorModel?: FloorAvailabilityModel
  ) => void;
  type: string;
  onEdit?: () => void;
  isGroupBooking?: boolean;
  users?: UserModel[];
}> = ({ onNext, onEdit, type, isGroupBooking, users }) => {
  const appContext = useContext(AppContext);
  const floorBookingModel: BookingModel = useContext(FlowContext).bookingModel;
  const bookingModel: BookingModel = isGroupBooking
    ? floorBookingModel[0]
    : floorBookingModel;
  let selectedFilterIds: Array<number>;
  let selectedFilterNames: Array<string>;

  if (!isGroupBooking && !isMeetingRoomBooking(type)) {
    selectedFilterIds = bookingModel.filter.map(
      ({ resourceAttributeId: filterId }) => filterId
    );
    selectedFilterNames = bookingModel.filter.map(
      ({ resourceAttributeName: filterName }) => filterName
    );
  }

  const [floors, setFloors] = useState<Array<FloorSummaryModel>>();
  const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>();
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [isNoMatchVisible, setIsNoMatchVisible] = useState(false);
  const [isNoMatchClosed, setIsNoMatchClosed] = useState(false);

  const infoText = [
    {
      boldText: 'No floors',
      text: '. matching your filter selection',
    },
    {
      boldText: 'No matches',
      text: ' indicate that there are other desks available not matching your desk features on that floor.',
    },
    {
      text: 'Sorry, there are no matching results. Please modify the filter.',
    },
  ];

  const theme = styledTheme();

  useEffect(() => {
    setLoading(true);
    const bookingType = appContext.bookingOptions.find(
      (x) => x.caption === type
    );

    const floorSummary = isGroupBooking
      ? getAccessibleFloorsSummary(
          bookingModel.buildingId,
          bookingModel.bookedDateObj,
          bookingType.resourceType
        )
      : isMeetingRoomBooking(type)
      ? getFloorsByBuildingIdInSummary(
          bookingModel.buildingId,
          bookingType.resourceType
        )
      : getFloorsByBuildingIdInSummary(
          bookingModel.buildingId,
          bookingType.resourceType,
          bookingModel.bookedDateObj,
          selectedFilterIds
        );

    floorSummary
      .then((res) => {
        setFloors(res.floors ? hideCarParkingFromFloors(res.floors) : []);
      })
      .catch(() => setIsLoadingFailed(true))
      .finally(() => setLoading(false));
  }, [retryCount]);

  const hideCarParkingFromFloors = (floors: FloorSummaryModel[]) => {
    const filteredFloors = [];

    floors.forEach((floor: FloorSummaryModel) => {
      const {
        resourcesAvailableCount,
        resourcesBookedCount,
        availableBookingsCount,
        existingBookingsCount,
        resourcesAvailableWithFeatureSetCount,
      } = floor;
      if (
        !(
          resourcesAvailableCount === 0 &&
          resourcesBookedCount === 0 &&
          availableBookingsCount === 0 &&
          existingBookingsCount === 0 &&
          resourcesAvailableWithFeatureSetCount === 0
        )
      ) {
        filteredFloors.push(floor);
      }
    });
    return filteredFloors;
  };
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));

  if (loading) {
    return <LoadingWholePage />;
  }

  if (isLoadingFailed) {
    return (
      <ErrorWholePage
        message="Sorry, we couldn’t load this page."
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }

  const onClickClose = () => {
    setIsNoMatchClosed(true);
  };

  const getAvailability = (floor: FloorSummaryModel): Availability => {
    let availability = Availability.AVAILABLE;

    if (isGroupBooking && floor.availableBookingsCount < users.length) {
      availability = Availability.NOTAVAILABLE;
    } else {
      if (floor.availableBookingsCount == 0) {
        availability = Availability.NOTAVAILABLE;
      } else if (floor.resourcesAvailableWithFeatureSetCount == 0) {
        availability = Availability.NOMATCHES;

        // Show no match message if it's not visible already
        !isNoMatchVisible && setIsNoMatchVisible(true);
      }
    }

    return isMeetingRoomBooking(type) ? null : availability;
  };

  const filterFloor = (floorModel: FloorAvailabilityModel) => {
    const selectedUsersCount = users.length;

    const filteredItems = floorModel.resources.filter(
      ({ isRestricted }) => isRestricted !== true
    );

    floorModel.resources =
      filteredItems.length >= selectedUsersCount
        ? filteredItems.filter((_, index) => index <= selectedUsersCount)
        : [];

    return floorModel;
  };

  const getDesks = async (
    bookingModel: BookingModel,
    floor: FloorSummaryModel
  ) => {
    if (isGroupBooking) {
      setLoading(true);
      await getAvailableDesks(
        bookingModel.buildingId,
        floor.floorId,
        bookingModel.bookedDateObj,
        [],
        false
      )
        .then((res) => {
          const availableDesks = filterFloor(res);
          availableDesks.resources.length
            ? onNext(availableDesks)
            : setIsLoadingFailed(true);
        })
        .catch(() => setIsLoadingFailed(true))
        .finally(() => setLoading(false));
    } else {
      onNext({
        floorId: floor.floorId,
        floorName: floor.floorName,
        resourceAvailability: getAvailability(floor),
      });
    }
  };

  const onSelect = (floor: FloorSummaryModel) => {
    !isMeetingRoomBooking(type)
      ? getDesks(bookingModel, floor)
      : onNext({
          floorId: floor.floorId,
          floorName: floor.floorName,
          resourceAvailability: null,
        });
  };

  if (floors.length === 0 && isMeetingRoomBooking(type)) {
    return (
      <ErrorWholePage
        message={
          'No meeting rooms are available at selected location. Please choose another location.'
        }
        iconType={'info'}
      />
    );
  }

  return (
    <ContainerSingleColumn role="main">
      <Title text="Choose a level" />
      {!isGroupBooking && !isMeetingRoomBooking(type) && (
        <EditFilter
          onEdit={onEdit}
          selectedList={selectedFilterNames}
          label="Desk features"
        />
      )}
      {/*
       Only display NoMatchesCard
       if no matches found and
       it hasn't been closed before
       */}
      {isNoMatchVisible && !isNoMatchClosed && (
        <NoMatchesCard
          boldText={infoText[1].boldText}
          text={infoText[1].text}
          closable={true}
          showCard={true}
          onClickCloseCard={onClickClose}
        />
      )}
      {floors.length === 0 && (
        <WarningCard text={infoText[2].text} iconColour={theme.palette.red} />
      )}
      <div role="list">
        {floors.map((floor) => {
          return (
            <Grid
              key={floor.floorId}
              item
              xs={12}
              md={12}
              style={{ marginTop: isMobileView ? '10px' : '20px' }}
              role="listitem"
            >
              <FloorCard
                floorName={floor.floorName}
                floorId={floor.floorId}
                availability={getAvailability(floor)}
                onSelect={() => onSelect(floor)}
              />
            </Grid>
          );
        })}
      </div>
    </ContainerSingleColumn>
  );
};
