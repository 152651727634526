import { BehalfOfBookingStep } from '../../BookingSteps';
import React, { useCallback, useState } from 'react';
import { BookingType } from '../../BookingType';
import { useHistory } from 'react-router-dom';
import { BookingModel } from 'Services/BookingService';
import FlowContext from 'Booking/FlowContext';
import { FlowStep } from 'Booking/FlowStep';
import { SelectBehalfOfType } from './SelectBehalfOfType';
import { AppRoute } from 'Routes';

export interface BehalfOfBookingStepData {
  currentStep: BehalfOfBookingStep;
  pastSteps: BehalfOfBookingStep[];
}

export const SelectBehalfOfFlow: React.FC = () => {
  const history = useHistory();

  const [stepData, setStepData] = useState<BehalfOfBookingStepData>({
    currentStep: BehalfOfBookingStep.SelectBehalfOfType,
    pastSteps: [],
  });

  const bookingModel: BookingModel = {
    resourceType: BookingType.Desk,
    filter: null,
    selectedFilter: null,
    resetFlag: false,
  };

  const updateStepData = (
    nextStep?: BehalfOfBookingStep,
    avoidCurrentStepRemember = false
  ) => {
    if (nextStep === undefined || nextStep === null) {
      const previousStep = stepData.pastSteps.pop();
      if (previousStep === undefined) {
        history.push('/'); //no previous step, so go to home page.
      } else {
        setStepData({
          currentStep: previousStep,
          pastSteps: stepData.pastSteps,
        });
      }
    } else {
      if (!avoidCurrentStepRemember) {
        stepData.pastSteps.push(stepData.currentStep);
      }
      setStepData({ currentStep: nextStep, pastSteps: stepData.pastSteps });
    }
  };

  const handleBookingOptionSelect = useCallback((type: BookingType) => {
    switch (type) {
      case BookingType.BehalfOfColleague:
        history.push(AppRoute.BookingBehalfOfColleague);
        break;
      case BookingType.BehalfOf:
        history.push(AppRoute.BookingBehalfOfGroup);
        break;
    }
  }, []);

  const handleBookingTypeNext = (isGroupBooking: boolean) => {
    if (isGroupBooking) {
      handleBookingOptionSelect(BookingType.BehalfOf);
    } else {
      handleBookingOptionSelect(BookingType.BehalfOfColleague);
    }
  };

  const handleBack = () => {
    updateStepData();
  };

  return (
    <FlowContext.Provider
      value={{
        currentStep: stepData.currentStep,
        bookingModel,
      }}
    >
      <>
        <FlowStep step={BehalfOfBookingStep.SelectBehalfOfType}>
          <SelectBehalfOfType
            onNext={(isGroupBooking: boolean) =>
              handleBookingTypeNext(isGroupBooking)
            }
            onBack={handleBack}
          />
        </FlowStep>
      </>
    </FlowContext.Provider>
  );
};
