import { BookingType } from 'Booking/BookingType';
import { getWithAuth } from './ServiceBase';
import { generateParamsString } from '../Helpers/Utils';

export interface MeModel {
  appName: string;
  customer: {
    customerName: string;
  };
  email: string;
  name: string;
  tenant: string;
  userId: string; // Incorrect type - ToDo
}

interface DateString {
  [key: string]: UserAvailability;
}

export interface UserAvailability {
  startDate: string;
  endDate: string;
  isAvailable: boolean;
}

export interface UserModel {
  userId: string;
  name: string;
  email: string;
  alreadyHasDeskBooking?: boolean; // TODO: dummy flag introduced, need to update after API integration
  availability?: DateString;
}

export interface BookedUserModel {
  userId: number;
  name: string;
  email: string;
}

export const getMe = async (): Promise<MeModel> => {
  const meUrl = `${process.env.REACT_APP_API_URI}/api/Me`;
  return getWithAuth<MeModel>(meUrl);
};

export const getRecentBookedUsers = async (
  apiVersion?: string,
  startDate?: string,
  endDate?: string,
  includeAvailability?: boolean,
  resourceType?: BookingType
): Promise<{
  users: Array<UserModel>;
}> => {
  let recentBookedUsersUrl = `${process.env.REACT_APP_API_URI}/api/Me/booked-users?`;

  const params = {
    availabilityStartDate: startDate,
    availabilityEndDate: endDate,
    includeAvailability: includeAvailability,
    availabilityResourceType: resourceType,
    'api-version': apiVersion,
  };

  recentBookedUsersUrl += generateParamsString(params);

  return getWithAuth<{ users: Array<UserModel> }>(recentBookedUsersUrl);
};

export const getUsers = async (
  filter?: string,
  id?: string,
  availabilityStartDate?: string,
  availabilityEndDate?: string,
  includeAvailability?: boolean,
  availabilityResourceType?: BookingType
): Promise<{ users: Array<UserModel> }> => {
  const searchUsersUrl = `${process.env.REACT_APP_API_URI}/api/Users`;
  const parameters: string = [
    filter ? `filter=${filter}` : null,
    id ? `id=${id}` : null,
    availabilityStartDate
      ? `availabilityStartDate=${availabilityStartDate}`
      : null,
    availabilityEndDate ? `availabilityEndDate=${availabilityEndDate}` : null,
    includeAvailability ? `includeAvailability=${includeAvailability}` : null,
    availabilityResourceType
      ? `availabilityResourceType=${availabilityResourceType}`
      : null,
  ]
    .filter(Boolean)
    .join('&');
  const searchUsersUrlWithParameters = `${searchUsersUrl}${
    parameters.length ? '?' + parameters : ''
  }`;

  return getWithAuth<{ users: Array<UserModel> }>(searchUsersUrlWithParameters);
};
