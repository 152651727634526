import styled, { useTheme } from 'styled-components';
import { LocationOn } from '@material-ui/icons';
import React, { AriaAttributes } from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
  AddressText,
  ChevronRight,
  BookingOptionButton,
  ButtonIconSize,
} from 'Shared/ButtonStyles';
import { LocationType } from './LocationType';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  startIcon: {
    '@media (min-width: 601px)': {
      paddingLeft: '30px',
    },
    '@media (max-width: 600px)': {
      marginLeft: '0px',
    },
  },
  endIcon: {
    '@media (min-width: 601px)': {
      paddingRight: '30px',
    },
    '@media (max-width: 600px)': {
      marginRight: '0px',
    },
  },
}));

const LocationIcon = styled(LocationOn)`
  ${ButtonIconSize}
`;

export const LocationCard: React.FC<{
  text: string;
  disabled?: boolean;
  onSelect: () => void;
  locationType?: LocationType;
  ariaAttributes?: AriaAttributes;
}> = ({
  text,
  disabled,
  onSelect,
  locationType = LocationType.NORMAL,
  ariaAttributes = {},
}) => {
  const theme = useTheme();

  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');

  const locationColour = new Map<number, string>([
    [LocationType.PAST, theme.primaryBlack],
    [LocationType.NORMAL, theme.primaryGray],
  ]);

  return (
    <div>
      <BookingOptionButton
        aria-describedby={ariaAttributes['aria-describedby']}
        variant="text"
        fullWidth
        disableRipple
        disableFocusRipple
        disabled={disabled}
        onClick={() => onSelect()}
        classes={{ startIcon: classes.startIcon, endIcon: classes.endIcon }}
        startIcon={
          locationType !== LocationType.NONE && (
            <LocationIcon style={{ color: locationColour.get(locationType) }} />
          )
        }
        endIcon={
          <ChevronRight style={{ color: locationColour.get(locationType) }} />
        }
      >
        <AddressText
          style={{
            color: locationColour.get(locationType),
            marginLeft: matches ? '6px' : '22px',
          }}
        >
          {text}
        </AddressText>
      </BookingOptionButton>
    </div>
  );
};
