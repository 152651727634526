import React from 'react';
import styled, { css } from 'styled-components';
import {
  ComputerRounded,
  LocalParking,
  People,
  TransferWithinAStation,
  Person,
  GroupWork,
} from '@material-ui/icons';
import { BookingType } from './BookingType';

const IconBaseStyle = css`
  color: ${({ theme }) => theme.palette.primary};

  @media (max-width: 600px) {
    height: 24px;
    width: 24px;
    padding-top: 4px;
  }

  @media (min-width: 601px) {
    height: 48px;
    width: 48px;
    padding-top: 8px;
  }
`;

const DeskIcon = styled(ComputerRounded)`
  ${IconBaseStyle}
`;

const MeetingRoomIcon = styled(GroupWork)`
  ${IconBaseStyle}
`;

const SiteVisitIcon = styled(TransferWithinAStation)`
  ${IconBaseStyle}
`;

const CarParkIcon = styled(LocalParking)`
  ${IconBaseStyle}
`;

const PeopleIcon = styled(People)`
  ${IconBaseStyle}
`;

const PersonIcon = styled(Person)`
  ${IconBaseStyle}
`;

export const BookingIcon: React.FC<{
  bookingType: BookingType;
}> = ({ bookingType }) => {
  switch (bookingType) {
    case BookingType.Desk:
      return <DeskIcon />;
    case BookingType.CarSpace:
      return <CarParkIcon />;
    case BookingType.Room:
      return <MeetingRoomIcon />;
    case BookingType.SiteVisit:
      return <SiteVisitIcon />;
    case BookingType.BehalfOf:
      return <PeopleIcon />;
    case BookingType.BehalfOfColleague:
      return <PersonIcon />;
    default:
      return null;
  }
};
