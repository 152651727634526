import { Grid } from '@material-ui/core';
import React from 'react';

export const CenteredContentGrid: React.FC = ({ children }) => {
  return (
    <Grid
      direction="row"
      container
      justifyContent="center"
      style={{ paddingTop: 24, paddingBottom: 24 }}
      alignItems="center"
    >
      {children}
    </Grid>
  );
};
