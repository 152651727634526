import React, { FC, ReactNode, MouseEvent } from 'react';
import styled from 'styled-components';

export type ButtonType = 'button' | 'submit' | 'reset';

export interface Props {
  children?: ReactNode;
  name?: string;
  type?: ButtonType;
  onClick?: (event: MouseEvent) => void;
}

/**
 * Render an HTML button as a link.
 *
 * Refer to https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button for more details.
 * @param props Props
 */
const LinkButton: FC<Props> = (props: Props) => {
  const { children, name, type, onClick } = props;

  return (
    <Button type={type ?? 'button'} name={name} onClick={onClick}>
      {children}
    </Button>
  );
};

export default LinkButton;

const Button = styled.button`
  border: 0 none;
  appearance: none;
  display: inline-block;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: ${({ theme }) => theme.palette.secondary};
  text-align: center;
  text-decoration: none;
  white-space: inherit;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: ${({ theme }) => theme.primaryGray};
    box-shadow: none;
  }
`;
