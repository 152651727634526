import { Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { getDayName, zeroPad } from 'Helpers/DateHelper';
import { ConfirmationCard } from 'Booking/Confirmation/ConfirmationCard';
import BookingContext from 'Booking/BookingContext';
import { cancelBooking } from 'Services/BookingService';
import { addMinutes, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { BookingOptionModel } from 'Services/ConfigService';
import { Instructions } from 'Booking/CarSpaceBooking/Instructions';
import { BookingType } from 'Booking/BookingType';
import { BookingButton } from 'Shared/BookingButton';
import { CancelConfirm } from 'Booking/View/CancelConfirm';
import { GridSingleColumn } from 'Shared/GridSingleColumn';
import { Loading } from 'Loading/Loading';
import { Title } from 'Shared/Title';

export const Padding = styled.div`
  padding-top: 200px;
`;

export const AcceptReservedBookingContent: React.FC<{
  bookingId: number;
  typeModel: BookingOptionModel;
  onConfirm: (confirm: boolean) => void;
  onCancel: (result: boolean) => void;
}> = ({ bookingId, typeModel, onConfirm, onCancel }) => {
  const history = useHistory();
  const theme = useTheme();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const bookingContext = useContext(BookingContext);
  const booking = bookingContext.bookingEntries?.bookings?.find(
    (x) => x.appointment.appointmentId === bookingId
  )?.appointment;

  if (booking === undefined) {
    history.push('/');
    return null;
  }

  const timeFormat = 'h:mm a';

  const formatTime = (datetime: Date) => {
    return format(datetime, timeFormat);
  };

  const getFormattedDuration = (booking) => {
    const separator = ' -';

    if (booking.resource.type === BookingType.SiteVisit) {
      return '';
    }

    if (booking.allDay) {
      return `${separator} All day`;
    }

    const start = formatTime(booking.startDateTimeObj);
    const end = formatTime(
      addMinutes(
        booking.startDateTimeObj,
        booking.duration.hours * 60 + booking.duration.minutes
      )
    );

    return `${separator} ${start} to ${end}`.toLowerCase();
  };

  const handleCancelConfirmYes = () => {
    setOpenConfirm(false);
    setIsLoading(true);
    const cancel = async () => {
      return await cancelBooking(booking.appointmentId);
    };
    cancel()
      .then((res) => {
        bookingContext.setBookingEntries({
          ...bookingContext.bookingEntries,
          isReloadRequired: res.isCanceled,
        });
        onCancel(res.isCanceled);
      })
      .catch(() => onCancel(false));
  };

  const canBeCancelled = () => {
    // Business rule not required for MVP, but will be needed post MVP
    // return booking.startDateTimeLocal > new Date();
    return true;
  };

  const getResourceText = () => {
    if (booking.resource.type === BookingType.Desk) {
      return `${booking.floorName} - ${booking.resource.name}`;
    }
    return null;
  };

  // -- Returns booking expiry string (default is 24hours (1440 mins))
  const getBookingExpiry = (minsToExpiry = 1440) => {
    const createdDate = new Date(booking.creationDateTime);
    const expiryDate = new Date(createdDate.getTime() + minsToExpiry * 60000);

    // Date in dash separated format
    const expiryDateString = `${expiryDate.getFullYear()}-${zeroPad(
      expiryDate.getMonth() + 1
    )}-${zeroPad(expiryDate.getDate())}`;

    // Time in colon separated format
    const expiryTimeString = `${zeroPad(expiryDate.getHours())}:${zeroPad(
      expiryDate.getMinutes()
    )}:${zeroPad(expiryDate.getSeconds())}`;

    const locale = Intl.NumberFormat().resolvedOptions().locale;

    const timeString12hr = new Date(
      `${expiryDateString}T${expiryTimeString}Z`
    ).toLocaleTimeString(locale, {
      timeZone: 'UTC',
      hourCycle: 'h12',
      hour: 'numeric',
      minute: 'numeric',
    });

    const bookingExpiryString = `${timeString12hr}, ${expiryDateString
      .split('-')
      .reverse()
      .join('/')}`.toUpperCase();

    return bookingExpiryString;
  };

  if (isLoading) {
    return (
      <Padding>
        <Loading />
      </Padding>
    );
  }

  return (
    <Grid container justifyContent="center" role="main">
      <GridSingleColumn>
        <Grid item>
          <Title text="Confirm your booking" />
        </Grid>
        <Grid item>
          <ConfirmationCard
            typeModel={typeModel}
            dateTime={
              getDayName(booking.startDateTimeObj) +
              ' - ' +
              booking.startDateTimeObj.toLocaleDateString('en-AU') +
              getFormattedDuration(booking)
            }
            resource={getResourceText()}
            location={`${booking.location.buildingName}, ${booking.location.city}`}
            reservedBy={`${booking.bookerFirstName} ${booking.bookerLastName}`}
            resourceFeatureOptions={booking.resource.featureOptions}
          />
        </Grid>
        {typeModel.resourceType === BookingType.CarSpace && (
          <Grid item>
            <Instructions />
          </Grid>
        )}
        <BookingExpiryContainer>
          <ConfirmBookingExpiryText>
            Confirm this booking before it cancels in 24 hours at{' '}
            <strong>{getBookingExpiry()}</strong>
          </ConfirmBookingExpiryText>
        </BookingExpiryContainer>
        <Grid container justifyContent="center" style={{ paddingBottom: 24 }}>
          <BookingButton
            label="Confirm booking"
            colour={theme.palette.selectedBlue}
            width={210}
            onSelect={() => onConfirm(true)}
          />
        </Grid>
        {canBeCancelled() && (
          <Grid
            container
            justifyContent="center"
            style={{ paddingTop: 15, paddingBottom: 24 }}
          >
            <CancelButton onClick={() => setOpenConfirm(true)}>
              Cancel booking
            </CancelButton>
          </Grid>
        )}
      </GridSingleColumn>
      <CancelConfirm
        onYes={handleCancelConfirmYes}
        onNo={() => setOpenConfirm(false)}
        open={openConfirm}
        cancelReserved={true}
      />
    </Grid>
  );
};

const ConfirmBookingExpiryText = styled.div``;

const BookingExpiryContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  @media (max-width: 600px) {
    padding: 23px 60px 21px 60px;
    font-size: 14px;
  }

  @media (min-width: 601px) {
    padding: 31px 20px 40px 20px;
    font-size: 18px;
  }
`;

const CancelButton = styled.button`
  color: ${({ theme }) => theme.palette.redLight};
  background: none;
  border: none;
  line-height: normal;
  font-size: 14px;
  margin-top: -10px;
  :hover {
    cursor: pointer;
  }
`;
