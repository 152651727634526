import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { FilterContent } from './FilterContent';
import { BookingModel } from 'Services/BookingService';
import { useDocumentTitle } from 'Helpers/CustomHooks';

export const SelectFilter: React.FC<{
  type: string;
  onNext: () => void;
  onFilterChange: (bookingModel?: BookingModel) => void;
  onBack: () => void;
  bookOnBehalfOf?: boolean;
}> = ({ type, onNext, onFilterChange, onBack, bookOnBehalfOf }) => {
  const heading = bookOnBehalfOf
    ? `Book on behalf of someone: ${type}`
    : `Book a ${type}`;
  useDocumentTitle([heading, 'Select Desk Features']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <FilterContent onNext={onNext} onFilterChange={onFilterChange} />
    </BaseContainer>
  );
};
