import styled from 'styled-components';
import { Error } from 'Error/Error';

export const ErrorPadding = styled.div`
  padding-top: 250px;
`;

export const ErrorWholePage: React.FC<{
  message: string;
  iconType?: string;
  onRetry?: () => void;
}> = ({ message, onRetry, iconType }) => {
  return (
    <>
      <ErrorPadding>
        <Error message={message} onRetry={onRetry} iconType={iconType}></Error>
      </ErrorPadding>
    </>
  );
};
