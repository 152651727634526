import * as React from 'react';
import styled from 'styled-components';
import { Close } from '@material-ui/icons';

export interface Props {
  order?: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  focus?: (focusedHtmlElement: HTMLButtonElement) => void;
}

export const UserChip: React.FC<Props> = (props) => {
  return (
    <Wrapper order={props.order}>
      <Label>{props.children}</Label>
      <CloseButton
        aria-label={'Remove ' + props.children}
        onClick={props.onClick}
        ref={(node) => {
          props.focus && props.focus(node);
        }}
      >
        <Close />
      </CloseButton>
    </Wrapper>
  );
};

export default UserChip;

const Wrapper = styled.li<{ order: number }>`
  display: inline-flex;
  max-width: 100%;
  height: 41px;
  padding: 2px 2px 2px 2px;
  border-radius: 35px;
  background-color: ${({ theme }) => theme.palette.primary};
  box-sizing: border-box;
  overflow: hidden;
  order: ${(props): number => props.order};

  @media (max-width: 600px) {
    height: 34px;
  }
`;

const Label = styled.p`
  user-select: none;
  padding: 12px 7px 12px 23px;
  margin: 0px;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  text-align: left;
  line-height: 16px;
  letter-spacing: 0.32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  @media (max-width: 600px) {
    padding: 8px 19px 8px 19px;
    align-items: center;
  }
`;

const CloseButton = styled.button`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.primaryWhite};
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin-right: 21px;
  align-self: center;
  padding: 0px;
  background: transparent;
  border: 0;
  cursor: pointer;
`;
