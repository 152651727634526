import { BookingStepHeader } from 'Booking/BookingStepHeader';
import React from 'react';
import { BaseContainer } from 'Shared/BaseContainer';
import { CompletionContent } from 'Booking/Completion/CompletionContent';
import { OperationType } from 'Booking/Completion/OperationType';
import { useDocumentTitle } from 'Helpers/CustomHooks';

export const DoneCancel: React.FC<{
  cancelResult: boolean;
  onBack: () => void;
}> = ({ cancelResult, onBack }) => {
  const heading = cancelResult ? 'Booking Cancelled' : 'Cancellation Failed';
  useDocumentTitle([heading]);

  return (
    <BaseContainer>
      <BookingStepHeader
        heading={heading}
        onBack={onBack}
        isBackButtonHidden={true}
      />
      <CompletionContent
        isOperationSuccessful={cancelResult}
        operationType={OperationType.CANCEL}
      />
    </BaseContainer>
  );
};
