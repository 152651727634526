import { Grid, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { getDayName } from 'Helpers/DateHelper';
import { ConfirmationCard } from 'Booking/Confirmation/ConfirmationCard';
import BookingContext from 'Booking/BookingContext';
import { cancelBooking } from 'Services/BookingService';
import { addMinutes, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { BookingOptionModel } from 'Services/ConfigService';
import { Instructions } from 'Booking/CarSpaceBooking/Instructions';
import { BookingType } from 'Booking/BookingType';
import { BookingButton } from 'Shared/BookingButton';
import { CancelConfirm } from './CancelConfirm';
import { GridSingleColumn } from 'Shared/GridSingleColumn';
import { Loading } from 'Loading/Loading';
import { Title } from 'Shared/Title';
import { ResourceFeatureOptions } from 'Services/ResourceService';
import { getPersonInfo } from 'Helpers/Utils';
import { useImage } from 'Helpers/CustomHooks';
import { Nav } from 'Booking/DeskBooking/Desk/DeskContent';

export const Padding = styled.div`
  padding-top: 200px;
`;

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 8px',
  },
}));

export const UserBookingContent: React.FC<{
  bookingId: number;
  typeModel: BookingOptionModel;
  onCancel: (result: boolean) => void;
  onViewFloorPlan?: (imageSrc) => void;
}> = ({ bookingId, typeModel, onCancel, onViewFloorPlan }) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const bookingContext = useContext(BookingContext);
  const booking = bookingContext.bookingEntries?.bookings?.find(
    (x) => x.appointment.appointmentId === bookingId
  )?.appointment;

  const floorPlanImage = `${booking?.location?.buildingId}-${booking?.floorId}`;
  const url = useImage(booking?.location.buildingId, booking?.floorId);

  useEffect(() => {
    setImageSrc(url);
  }, [url]);

  if (booking === undefined) {
    history.push('/');
    return null;
  }

  const timeFormat = 'h:mm a';

  const formatTime = (datetime: Date) => {
    return format(datetime, timeFormat);
  };

  const getFormattedDuration = (booking) => {
    const separator = ' -';

    if (
      booking.resource.type === BookingType.SiteVisit ||
      booking.resource.type === BookingType.Room
    ) {
      return '';
    }

    if (booking.allDay) {
      return `${separator} All day`;
    }

    const start = formatTime(booking.startDateTimeObj);
    const end = formatTime(
      addMinutes(
        booking.startDateTimeObj,
        booking.duration.hours * 60 + booking.duration.minutes
      )
    );

    return `${separator} ${start} to ${end}`.toLowerCase();
  };

  const handleConfirmYes = () => {
    setOpenConfirm(false);
    setIsLoading(true);
    const cancel = async () => {
      return await cancelBooking(booking.appointmentId);
    };
    cancel()
      .then((res) => {
        bookingContext.setBookingEntries({
          ...bookingContext.bookingEntries,
          isReloadRequired: res.isCanceled,
        });
        onCancel(res.isCanceled);
      })
      .catch(() => onCancel(false));
  };

  const canBeCancelled = () => {
    // Business rule not required for MVP, but will be needed post MVP
    // return booking.startDateTimeLocal > new Date();
    return true;
  };

  const getResourceText = () => {
    if (
      booking.resource.type === BookingType.Desk ||
      booking.resource.type === BookingType.Room
    ) {
      return `${booking.floorName} - ${booking.resource.name}`;
    }
    return null;
  };

  const generateBookingDuration = () => {
    return booking.resource.type === BookingType.Room
      ? `${booking?.duration?.hours}:${booking?.duration?.minutes}`
      : '';
  };

  const getFeatureOptions = (): ResourceFeatureOptions[] => {
    const roomOptions: ResourceFeatureOptions[] = [
      getPersonInfo(booking.resource.seatingCapacity),
    ];

    booking.resource.featureOptions &&
      booking.resource.featureOptions.forEach((option) => {
        roomOptions.push(option);
      });

    return booking.resource.type === BookingType.Room
      ? roomOptions
      : booking.resource.featureOptions;
  };

  if (isLoading) {
    return (
      <Padding>
        <Loading />
      </Padding>
    );
  }

  return (
    <Grid container justifyContent="center" role="main">
      <GridSingleColumn>
        <Grid
          className={classes.container}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Title text="Your booking" />
          </Grid>
          <Grid item>
            {imageSrc && (
              <Nav
                onClick={() => onViewFloorPlan({ imageSrc, floorPlanImage })}
              >
                View Floorplan
              </Nav>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <ConfirmationCard
            typeModel={typeModel}
            dateTime={
              getDayName(booking.startDateTimeObj) +
              ' - ' +
              booking.startDateTimeObj.toLocaleDateString('en-AU') +
              getFormattedDuration(booking)
            }
            resource={getResourceText()}
            location={`${booking.location.buildingName}, ${booking.location.city}`}
            resourceFeatureOptions={getFeatureOptions()}
            bookingDateObj={booking.startDateTimeObj}
            bookingDuration={generateBookingDuration()}
          />
        </Grid>
        {typeModel.resourceType === BookingType.CarSpace && (
          <Grid item>
            <Instructions />
          </Grid>
        )}
        {canBeCancelled() && (
          <Grid
            container
            justifyContent="center"
            style={{ paddingTop: 15, paddingBottom: 24 }}
          >
            <BookingButton
              label="Cancel booking"
              width={190}
              colour={theme.palette.redLight}
              onSelect={() => setOpenConfirm(true)}
            />
          </Grid>
        )}
      </GridSingleColumn>
      <CancelConfirm
        onYes={handleConfirmYes}
        onNo={() => setOpenConfirm(false)}
        open={openConfirm}
      />
    </Grid>
  );
};
