import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import {
  BookingOptionButton,
  AddIcon,
  ChevronRight,
} from './../../Shared/ButtonStyles';
import { makeStyles } from '@material-ui/styles';
import { WarningDialog } from 'components/WarningDialog/WarningDialog';
const useStyles = makeStyles(() => ({
  startIcon: {
    display: 'block',
    '@media (min-width: 601px)': {
      marginLeft: '21px',
      marginRight: '31px',
    },
    '@media (max-width: 600px)': {
      marginLeft: '13px',
      marginRight: '12px',
    },
  },
  endIcon: {
    display: 'block',
    marginRight: '14px',
    marginTop: '8px',
  },
}));

export interface Props {
  label: string;
  onSelect: () => void;
  isGroupBooking?: boolean;
  alreadyHasDeskBooking: boolean;
}

export const RecentUserCard: React.FC<Props> = ({
  label,
  onSelect,
  children,
  isGroupBooking,
  alreadyHasDeskBooking,
}) => {
  const classes = useStyles();
  const alreadyHasDeskBookingText = 'Already has desk booking';

  const iconType: ReactNode = isGroupBooking ? (
    !alreadyHasDeskBooking && (
      <AddIcon fontSize="medium" data-testid="addIcon" />
    )
  ) : (
    <ChevronRight fontSize="medium" data-testid="chevronRight" />
  );
  const [displayDialog, setDisplayDialog] = useState(false);

  const warningDialogRender = (
    <WarningDialog
      labelOne={`You can not add this person to desk booking because they
      already have a desk booked.`}
      labelTwo={`A user can not have more than one desk on any given day.`}
      onClick={() => {
        setDisplayDialog(false);
      }}
      open={displayDialog}
    ></WarningDialog>
  );
  const handleClick = () => {
    if (isGroupBooking && alreadyHasDeskBooking) {
      setDisplayDialog(true);
    } else {
      onSelect();
    }
  };
  return (
    <>
      <BookingOptionButton
        variant="text"
        fullWidth
        disableRipple
        disableFocusRipple
        onClick={() => handleClick()}
        style={{ paddingLeft: '0px' }}
        classes={{
          startIcon: classes.startIcon,
          endIcon: classes.endIcon,
        }}
        startIcon={<span>{children}</span>}
        endIcon={<span>{iconType}</span>}
        $disabledBackground={isGroupBooking && alreadyHasDeskBooking}
      >
        <LabelText>
          <div>{label}</div>
          {isGroupBooking && alreadyHasDeskBooking && (
            <InfoText>{alreadyHasDeskBookingText}</InfoText>
          )}
        </LabelText>
      </BookingOptionButton>
      <> {displayDialog && warningDialogRender}</>
    </>
  );
};

const LabelText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1;
  text-align: left;
  text-transform: none;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const InfoText = styled.div`
  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 16px;
  }
  font-weight: 700;
`;
