import React, { ReactElement, useContext } from 'react';
import FlowContext from './FlowContext';

interface FlowContextData<T> {
  currentStep: T;
}

export function FlowStep<T>({
  step,
  children,
}: {
  step: T;
} & { children?: React.ReactNode }): ReactElement {
  const flowContext = useContext<FlowContextData<T>>(FlowContext);
  if (flowContext.currentStep !== step) {
    return null;
  }
  return <div>{children}</div>;
}
