import React, { FC } from 'react';
import { Typography, Grid, Link } from '@material-ui/core';
import styled from 'styled-components';

const EditFilter: FC<{
  onEdit: () => void;
  selectedList: Array<string>;
  label?: string;
}> = ({ onEdit, selectedList, label }) => (
  <Grid container aria-label="Edit Filter">
    <Text>
      <strong>{label}: </strong>
      {selectedList && selectedList.length > 0 && selectedList.join(', ')}
      &nbsp;
      <Link
        component="button"
        variant="body1"
        onClick={() => onEdit()}
        data-testid="onEditFilter"
        aria-label="Edit filters"
        underline="none"
      >
        Edit
      </Link>
    </Text>
  </Grid>
);
const Text = styled(Typography)`
  @media (max-width: 600px) {
    font-size: 14px;
    margin-top: 10px;
    align: 'left';
  }

  @media (min-width: 601px) {
    font-size: 16px;
    margin-top: 20px;
    align: 'left';
  }
`;

export default EditFilter;
