import React, { useContext, useState } from 'react';
import { MeetingRoomBookingStep } from '../BookingSteps';
import { BookingType } from '../BookingType';
import { SelectLocation } from '../Location/SelectLocation';
import { useHistory } from 'react-router-dom';
import { AnotherLocation } from 'Booking/Location/AnotherLocation';
import { SelectDate } from 'Booking/Date/SelectDate';
import AppContext from 'AppContext';
import { BookingModel } from 'Services/BookingService';
import FlowContext from 'Booking/FlowContext';
import { FlowStep } from 'Booking/FlowStep';
import { NextStepTrigger } from 'Booking/Location/NextStepTrigger';
import { LocationModel } from 'Services/LocationService';
import LocationContext from 'Booking/Location/LocationContext';
import { SelectFloor } from 'Booking/Floor/SelectFloor';
import { FilterModel, FloorAvailabilityModel } from 'Services/ResourceService';
import { SelectTime } from 'Booking/Time/SelectTime';
import { SelectPreference } from './MeetingRoomPreference/SelectPreference';
import * as meetingRoomConfig from '../../Configs/MeetingRoomConfig';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { StartTime } from 'Booking/Time/TimeContent';
import { SelectRoom } from './SelectRoom/SelectRoom';
import PreferenceContext from './MeetingRoomPreference/PreferenceContext';
import { Confirmation } from 'Booking/Confirmation/Confirmation';
import { DoneBooking } from 'Booking/DoneBooking/DoneBooking';

export interface MeetingRoomBookingStepData {
  currentStep: MeetingRoomBookingStep;
  pastSteps: MeetingRoomBookingStep[];
}

export const MeetingRoomBookingFlow: React.FC = () => {
  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  const history = useHistory();
  const appContext = useContext(AppContext);

  const [result, setResult] = useState<boolean>(false);

  const [stepData, setStepData] = useState<MeetingRoomBookingStepData>({
    currentStep: MeetingRoomBookingStep.SelectLocation,
    pastSteps: [],
  });

  const [bookingModel, setBookingModel] = useState<BookingModel>({
    resourceType: BookingType.Room,
    filter: null,
    selectedFilter: null,
    resetFlag: false,
  });

  const [locationData, setLocationData] = useState<{
    pastLocations: LocationModel[];
  }>(null);

  const [selectedEndTime, setSelectedEndTime] = useState<StartTime>(null);
  const [selectedCustomEndTime, setSelectedCustomEndTime] =
    useState<StartTime>(null);

  const [preferences, setPreferences] = useState<{
    filter: FilterModel[];
    seatingCapacity: number;
  }>();

  const updateStepData = (
    nextStep?: MeetingRoomBookingStep,
    avoidCurrentStepRemember = false
  ) => {
    if (nextStep === undefined || nextStep === null) {
      const previousStep = stepData.pastSteps.pop();
      if (previousStep === undefined) {
        history.push('/'); //no previous step, so go to home page.
      } else {
        setStepData({
          currentStep: previousStep,
          pastSteps: stepData.pastSteps,
        });
      }
    } else {
      if (!avoidCurrentStepRemember) {
        stepData.pastSteps.push(stepData.currentStep);
      }
      setStepData({ currentStep: nextStep, pastSteps: stepData.pastSteps });
    }
  };

  const handleSelectLocationNext = (
    trigger: NextStepTrigger,
    meetingRoomBookingModel?: BookingModel,
    pastLocations?: LocationModel[]
  ) => {
    switch (trigger) {
      case NextStepTrigger.LocationSelect:
        updateStepData(MeetingRoomBookingStep.SelectFloor);
        if (meetingRoomBookingModel) {
          setBookingModel({
            ...bookingModel,
            buildingId: meetingRoomBookingModel.buildingId,
            buildingName: meetingRoomBookingModel.buildingName,
            city: meetingRoomBookingModel.city,
          });
        }
        break;
      case NextStepTrigger.ChooseAnotherLocationSelect:
        setLocationData({ pastLocations });
        updateStepData(MeetingRoomBookingStep.ChooseAnotherLocation);
        break;
    }
  };

  const type = appContext.bookingOptions.find(
    (x) => x.resourceType === BookingType.Room
  );

  const handleNext = (
    nextStep: MeetingRoomBookingStep,
    meetingRoomBookingModel?: BookingModel
  ) => {
    switch (nextStep) {
      case MeetingRoomBookingStep.SelectFloor:
        // -- TODO - update when adding level select view
        setBookingModel(meetingRoomBookingModel);
        break;

      case MeetingRoomBookingStep.SelectStartTime:
        if (meetingRoomBookingModel) {
          setBookingModel({
            ...bookingModel,
            bookedDateObj: meetingRoomBookingModel.bookedDateObj,
            bookedDate: meetingRoomBookingModel.bookedDate,
          });
        }
        break;

      case MeetingRoomBookingStep.SelectEndTime:
        setSelectedEndTime(
          generateInitialEndTime(
            meetingRoomBookingModel.bookedDate,
            meetingRoomConfig.listIncrementSizeMinutes
          )
        );

        setSelectedCustomEndTime(
          generateInitialEndTime(meetingRoomBookingModel.bookedDate, 5)
        );
        if (meetingRoomBookingModel) {
          setBookingModel({
            ...bookingModel,
            bookedDateObj: meetingRoomBookingModel.bookedDateObj,
            bookingStartDate: meetingRoomBookingModel.bookedDate,
          });
        }
        break;

      case MeetingRoomBookingStep.SelectCustomTime:
        break;

      case MeetingRoomBookingStep.SelectFilter:
        setBookingModel({
          ...bookingModel,
          duration: calculateDuration(meetingRoomBookingModel.bookedDate),
          bookingEndDate: meetingRoomBookingModel.bookedDate,
        });
        break;

      case MeetingRoomBookingStep.SelectRoom:
        setBookingModel({
          ...bookingModel,
          seatingCapacity: meetingRoomBookingModel.seatingCapacity, // TODO - When API is ready
          filter: meetingRoomBookingModel.filter, // TODO - When API is ready
        });

        break;

      case MeetingRoomBookingStep.ConfirmBooking:
        setBookingModel({
          ...bookingModel,
          subject: 'Meeting Room',
          allDay: false,
          revisionNumber: 0,
          description: '',
          resourceId: meetingRoomBookingModel.resourceId,
          resourceName: meetingRoomBookingModel.resourceName,
          resourceFeatureOptions:
            meetingRoomBookingModel.resourceFeatureOptions,
        });

        break;
      // -- TODO Add other next view step cases here
    }
    updateStepData(nextStep);
  };

  const handleBack = () => {
    updateStepData();
  };

  const calculateDuration = (selectedEndTIme: string) => {
    const startTime = dayjs(bookingModel.bookedDateObj);
    const endTime = dayjs(selectedEndTIme);
    const duration = dayjs
      .duration(endTime.diff(startTime))
      .format('YYYY-MM-DDTHH:mm:ss')
      .split('T')[1];
    return duration;
  };

  const handleSelectFloorNext = async (floorModel?: FloorAvailabilityModel) => {
    if (floorModel) {
      setBookingModel({
        ...bookingModel,
        floorId: floorModel.floorId,
        floorName: floorModel.floorName,
      });
    }
    updateStepData(MeetingRoomBookingStep.SelectDate);
  };

  const generateInitialEndTime = (
    dateString: string,
    incrementSize: number
  ): StartTime => {
    return dayjs(dateString)
      .add(incrementSize, 'minutes')
      .utc(true)
      .toISOString()
      .split(/[T.]/)[1] as StartTime;
  };

  const handleEditFilter = () => {
    setStepData({
      currentStep: MeetingRoomBookingStep.SelectFilter,
      pastSteps: stepData.pastSteps.slice(
        0,
        stepData.pastSteps.indexOf(MeetingRoomBookingStep.SelectFilter)
      ),
    });
  };

  const handleBookingDone = (bookingResult?: boolean) => {
    setResult(bookingResult);
    updateStepData(MeetingRoomBookingStep.DoneBooking);
  };

  return (
    <FlowContext.Provider
      value={{
        currentStep: stepData.currentStep,
        bookingModel,
      }}
    >
      <>
        <FlowStep step={MeetingRoomBookingStep.SelectLocation}>
          <SelectLocation
            type={type.caption}
            onNext={handleSelectLocationNext}
            onBack={handleBack}
          />
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.ChooseAnotherLocation}>
          <LocationContext.Provider
            value={{
              pastLocations: locationData?.pastLocations,
            }}
          >
            <AnotherLocation
              type={type.caption}
              onNext={(location) =>
                handleNext(MeetingRoomBookingStep.SelectFloor, location)
              }
              onBack={handleBack}
            />
          </LocationContext.Provider>
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.SelectFloor}>
          <SelectFloor
            type={type.caption}
            onNext={(floor) => handleSelectFloorNext(floor)}
            onBack={handleBack}
            bookingHeaderType={BookingType.Room}
            isGroupBooking={false}
          />
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.SelectDate}>
          <SelectDate
            type={type.caption}
            onNext={(date) =>
              handleNext(MeetingRoomBookingStep.SelectStartTime, date)
            }
            onBack={handleBack}
            bookingFlowType={BookingType.Room}
            guestUser={false}
          />
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.SelectStartTime}>
          <SelectTime
            type={type.caption}
            onNext={(date) =>
              handleNext(MeetingRoomBookingStep.SelectEndTime, date)
            }
            onBack={handleBack}
            bookingHeaderType={BookingType.Room}
            startListTime={meetingRoomConfig.startListTime}
            listDurationHours={meetingRoomConfig.startTimeDuration}
            incrementSizeMinutes={meetingRoomConfig.listIncrementSizeMinutes}
            customTimeLabel="Choose a custom start time"
            displaySubLabel={false}
            customMinTime="00:00"
            customMaxTime={20.05}
            headingText={'What time do you need the room?'}
          />
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.SelectEndTime}>
          <SelectTime
            type={type.caption}
            onNext={(date) =>
              handleNext(MeetingRoomBookingStep.SelectFilter, date)
            }
            onBack={handleBack}
            bookingHeaderType={BookingType.Room}
            startListTime={selectedEndTime}
            listDurationHours={meetingRoomConfig.endTimeDuration}
            incrementSizeMinutes={meetingRoomConfig.listIncrementSizeMinutes}
            customTimeLabel="Choose a custom end time"
            displaySubLabel={true}
            customMinTime={selectedCustomEndTime}
            customMaxTime={24}
            headingText={'What time would the booking end?'}
          />
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.SelectFilter}>
          <PreferenceContext.Provider value={[preferences, setPreferences]}>
            <SelectPreference
              type={type.caption}
              onNext={(features: BookingModel) =>
                handleNext(MeetingRoomBookingStep.SelectRoom, features)
              }
              onBack={handleBack}
              bookingHeaderType={BookingType.Room}
            />
          </PreferenceContext.Provider>
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.SelectRoom}>
          <SelectRoom
            type={type.caption}
            onNext={(meetingRoom: BookingModel) =>
              handleNext(MeetingRoomBookingStep.ConfirmBooking, meetingRoom)
            }
            onBack={handleBack}
            bookingHeaderType={BookingType.Room}
            onEdit={handleEditFilter}
          />
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.ConfirmBooking}>
          <Confirmation
            typeModel={type}
            onNext={(result) => handleBookingDone(result)}
            onBack={handleBack}
          />
        </FlowStep>

        <FlowStep step={MeetingRoomBookingStep.DoneBooking}>
          <DoneBooking
            typeModel={type}
            type={type.caption}
            bookingResult={result}
            onBack={handleBack}
          />
        </FlowStep>
      </>
    </FlowContext.Provider>
  );
};
