import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { ConfirmationCard } from '../../../Confirmation/ConfirmationCard';
import { getDayName } from 'Helpers/DateHelper';
import { BookingModel } from 'Services/BookingService';
import { BookingOptionModel } from 'Services/ConfigService';
import FlowContext from 'Booking/FlowContext';
import { NextButton } from 'Shared/NextButton';
import { GridSingleColumn } from 'Shared/GridSingleColumn';
import { Title } from 'Shared/Title';
import { ConfirmationCard as BOBOConfirmationCard } from '../../BookingOnBehalfGroup/ConfirmationCard/ConfirmationCard';
import styled from 'styled-components';

export const ReserveBookingContent: React.FC<{
  typeModel: BookingOptionModel;
  onNext: () => void;
}> = ({ typeModel, onNext }) => {
  const bookingContext = useContext<{
    bookingModel: BookingModel[];
  }>(FlowContext);
  const [bookingModel] = bookingContext.bookingModel;
  const bookingModels: BookingModel[] = bookingContext.bookingModel;

  const getLocation = () => {
    return `${bookingModel.buildingName}, ${bookingModel.city}`;
  };

  const location = getLocation();

  return (
    <Grid container justifyContent="center" role="main">
      <GridSingleColumn>
        <Grid item>
          <Title text="Reserve bookings" />
        </Grid>
        {bookingModel && (
          <>
            <CardContainerGrid item>
              <ConfirmationCard
                typeModel={typeModel}
                dateTime={
                  getDayName(bookingModel.bookedDateObj) +
                  ' - ' +
                  bookingModel.bookedDateObj.toLocaleDateString('en-AU') +
                  (bookingModel.allDay ? ' - All day' : '')
                }
                resource={`${bookingModel.floorName}`}
                location={location}
              />
            </CardContainerGrid>
            {bookingModels.map((model, index) => (
              <CardContainerGrid item style={{ paddingTop: 14 }} key={index}>
                <BOBOConfirmationCard
                  resourceFeatureOptions={model.resourceFeatureOptions}
                  typeModel={typeModel}
                  resource={`${model.floorName} - ${model.resourceName}`}
                  user={model.user}
                />
              </CardContainerGrid>
            ))}
          </>
        )}

        <Grid
          container
          justifyContent="center"
          style={{ paddingTop: 15, paddingBottom: 24 }}
        >
          <NextButton
            text="Reserve booking"
            width={190}
            onClick={() => onNext()}
          />
        </Grid>
      </GridSingleColumn>
    </Grid>
  );
};

const CardContainerGrid = styled(Grid)`
  width: 100%;
`;
