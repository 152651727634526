import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { BookingOption } from './BookingOption';
import { BookingOptionModel } from 'Services/ConfigService';
import AppContext from 'AppContext';
import { BookingIcon } from './BookingIcon';
import { BookingType } from './BookingType';

export const NewBookingContent: React.FC<{
  onBookingSelect: (type: BookingType) => void;
}> = ({ onBookingSelect }) => {
  const appContext = useContext(AppContext);

  const behalfOfOptions: BookingOptionModel = {
    caption: 'Book for others',
    description: 'Book for others',
    resourceType: BookingType.BehalfOf,
  };

  const renderGrid = (option: BookingOptionModel, i) => (
    <Grid key={i} item xs={12} md={6}>
      <nav>
        <BookingOption
          caption={option.caption}
          description={option.description}
          type={option.resourceType}
          onBookingSelect={onBookingSelect}
        >
          <BookingIcon bookingType={option.resourceType} />
        </BookingOption>
      </nav>
    </Grid>
  );

  const getBookingOption = (bookingType: number) => {
    let bookingOption: BookingOptionModel = null;
    appContext?.bookingOptions?.forEach((option: BookingOptionModel) => {
      if (option.resourceType === bookingType) {
        bookingOption = option;
      }
    });
    return bookingOption;
  };

  // -- Create booking option list order
  const BookingOptions = [
    {
      name: 'Desk',
      options: getBookingOption(BookingType.Desk),
    },
    {
      name: 'CarSpace',
      options: getBookingOption(BookingType.CarSpace),
    },
    {
      name: 'SiteVisit',
      options: getBookingOption(BookingType.SiteVisit),
    },
    {
      name: 'BehalfOf',
      options: appContext?.bookOnBehalfOptions?.bookingOptions.length
        ? behalfOfOptions
        : null,
    },
    {
      name: 'Room',
      options: getBookingOption(BookingType.Room),
    },
  ];

  return (
    <>
      {BookingOptions.map((op, i) => op.options && renderGrid(op.options, i))}
    </>
  );
};
