import React, { FC, useState } from 'react';
import styled from 'styled-components';

export interface Props {
  name?: string;
  endLimit: number;
  containerWidth?: string;
  defaultValue?: number;
  onClick?: (selectedValue: number) => void;
}

const NumberTileButtons: FC<Props> = (props: Props) => {
  const { name, endLimit, defaultValue, containerWidth, onClick } = props;
  const numberTiles = [];
  const [selectedNumber, setSelectedNumber] = useState(defaultValue || 0);

  const onClickHandler = (selectedValue: number) => {
    setSelectedNumber(selectedValue);
    onClick(selectedValue);
  };

  for (let i = 1; i <= endLimit; i++) {
    numberTiles.push(
      <ButtonWrapper key={`${name}-${i}`}>
        <StyledButton
          type="button"
          name={`${name}-${i}`}
          onClick={() => onClickHandler(i)}
          selected={i === selectedNumber}
        >
          {i}
          {i === endLimit ? '+' : ''}
        </StyledButton>
      </ButtonWrapper>
    );
  }

  return (
    <NumberTileWrapper
      width={containerWidth}
      aria-label="minimum seating capacity"
    >
      {numberTiles}
    </NumberTileWrapper>
  );
};

export default NumberTileButtons;

const StyledButton = styled.button<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected
      ? props.theme.palette.selectedBlue
      : props.theme.primaryWhite};
  color: ${(props) =>
    props.selected ? props.theme.primaryWhite : props.theme.secondaryBlack};
  text-align: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
  font-size: 20px;
  border: none;
  border-radius: 26px;
`;

const NumberTileWrapper = styled.div<{ width: string }>`
  width: ${(props): string => (props.width ? props.width : '100%')};
  display: flex;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:nth-child(1) {
    padding-left: 0;
  }

  @media (max-width: 413px) {
    padding: 1vw;
  }

  @media (min-width: 414px) {
    padding: 10px;
  }
`;
