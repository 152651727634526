import { Grid, useMediaQuery, useTheme as ut } from '@material-ui/core';
import { ContainerSingleColumn } from 'Shared/ContainerSingleColumn';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { BookingModel } from 'Services/BookingService';
import { LoadingWholePage } from 'Loading/LoadingWholePage';
import FlowContext from 'Booking/FlowContext';
import {
  FilterModel,
  getAvailableDesks,
  ResourceAvailabilityModel,
} from 'Services/ResourceService';
import { ErrorWholePage } from 'Error/ErrorWholePage';
import { ShowMoreButton } from 'Shared/ShowMoreButton';
import { CenteredContentGrid } from 'Shared/CenteredContentGrid';
import { NoAvailabiltyErrorCard } from 'Error/NoAvailabiltyErrorCard';
import { BookingType } from 'Booking/BookingType';
import { Title } from 'Shared/Title';
import EditFilter from '../../Filter/EditFilter';
import { NoMatchesCard } from '../../../Shared/NoMatchesCard';
import { Availability } from 'Booking/Availability';
import { ResourceCard } from 'Shared/ResourceCard/ResourceCard';
import styled from 'styled-components';
import { useImage } from 'Helpers/CustomHooks';

const AutoFocus = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <span tabIndex={-1} ref={ref}></span>;
});

export const DeskContent: React.FC<{
  onNext: (bookingModel?: BookingModel) => void;
  onEdit?: () => void;
  onViewFloorPlan?: (imageSrc) => void;
}> = ({ onNext, onEdit, onViewFloorPlan }) => {
  const bookingValue = useContext(FlowContext);
  const [imageSrc, setImageSrc] = useState('');
  const [desks, setDesks] = useState<ResourceAvailabilityModel[]>([]);
  const [loading, setLoading] = useState(true);
  const bookingModel: BookingModel = useContext(FlowContext).bookingModel;
  const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>();
  const [retryCount, setRetryCount] = useState(0);
  const [showingLess, setShowingLess] = useState(true);

  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));
  // If desktop, show 8 results; if mobile, show 4 results
  const initialDesksNumber = isMobileView ? 4 : 8;
  const placeholderRef = React.useRef<HTMLDivElement>();
  const [selectedList, setSelFilterList] = useState([]);
  const [isNoMatchVisible, setIsNoMatchVisible] = useState(false);

  const filterId: Array<FilterModel['resourceAttributeId']> = [];
  const filterName: Array<FilterModel['resourceAttributeName']> = [];
  bookingModel.filter.forEach((resourceFeature) => {
    filterId.push(resourceFeature.resourceAttributeId);
    filterName.push(resourceFeature.resourceAttributeName);
  });

  const infoText =
    'No matches found for your selected desk features. Showing all other desks.';

  const floorPlanImage = `${bookingValue.bookingModel.buildingId}-${bookingValue.bookingModel.floorId}`;
  const url = useImage(
    bookingValue.bookingModel.buildingId,
    bookingValue.bookingModel.floorId
  );

  useEffect(() => {
    setImageSrc(url);
  }, [url]);

  useEffect(() => {
    setLoading(true);
    setShowingLess(true);
    setSelFilterList(filterName);
    bookingModel.resourceAvailability == Availability.NOMATCHES
      ? setIsNoMatchVisible(true)
      : null;
    getAvailableDesks(
      bookingValue.bookingModel.buildingId,
      bookingValue.bookingModel.floorId,
      bookingValue.bookingModel.bookedDateObj,
      filterId ? filterId : [],
      bookingModel.resourceAvailability == Availability.NOMATCHES ? true : false
    )
      .then((res) => {
        if (res === null || res.resources === null) {
          setDesks([]);
        } else {
          setDesks(res.resources);
        }
      })
      .catch(() => setIsLoadingFailed(true))
      .finally(() => setLoading(false));
  }, [retryCount]);

  useEffect(() => {
    placeholderRef.current && placeholderRef.current.focus();
  }, [showingLess]);

  const handleExpand = () => {
    setShowingLess(!showingLess);
  };

  const getExpandButtonText = () => {
    return !showingLess
      ? 'Show fewer desks'
      : `Show ${desks?.length - initialDesksNumber} more desks`;
  };

  if (loading) {
    return <LoadingWholePage />;
  }

  if (isLoadingFailed) {
    return (
      <ErrorWholePage
        message="Sorry, we couldn’t load this page."
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }

  if (desks.length === 0) {
    return (
      <NoAvailabiltyErrorCard
        bookingType={BookingType.Desk}
      ></NoAvailabiltyErrorCard>
    );
  }
  return (
    <ContainerSingleColumn role="main">
      <LinkWrapper>
        <Title text="Choose a desk" />

        {imageSrc && (
          <Nav onClick={() => onViewFloorPlan({ imageSrc, floorPlanImage })}>
            View Floorplan
          </Nav>
        )}
      </LinkWrapper>
      <EditFilter
        onEdit={onEdit}
        selectedList={selectedList}
        label="Desk features"
      />

      {/*
       Only display NoMatchesCard
       if no matches found
       */}
      {isNoMatchVisible && <NoMatchesCard text={infoText} showCard={true} />}
      <div role="list">
        {desks
          .slice(0, showingLess ? initialDesksNumber : desks.length)
          .map((desk, i) => {
            return (
              <Fragment key={i}>
                {initialDesksNumber > 0 && i === initialDesksNumber && (
                  <AutoFocus ref={placeholderRef} />
                )}
                <Grid
                  item
                  key={desk.resourceId}
                  xs={12}
                  md={12}
                  style={{
                    marginTop: isMobileView ? '10px' : '20px',
                  }}
                  role="listitem"
                >
                  <ResourceCard
                    style={{
                      paddingLeft: isMobileView ? '18px' : '30px',
                      paddingTop: isMobileView ? '16px' : '24px',
                      paddingBottom: '17px',
                      minHeight: isMobileView ? '48px' : '64px',
                    }}
                    level={bookingValue.bookingModel.floorName}
                    resource={desk}
                    onSelect={() =>
                      onNext({
                        resourceId: desk.resourceId,
                        resourceName: desk.name,
                        resourceFeatureOptions: desk.resourceFeatureOptions,
                      })
                    }
                  />
                </Grid>
              </Fragment>
            );
          })}
      </div>
      {desks.length > initialDesksNumber && showingLess && (
        <CenteredContentGrid>
          <ShowMoreButton
            showingLess
            handleExpand={handleExpand}
            text={getExpandButtonText()}
            width={227}
          />
        </CenteredContentGrid>
      )}
    </ContainerSingleColumn>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Nav = styled.button`
  font-size: 18px;
  border: 0 none;
  appearance: none;
  display: inline-block;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: ${({ theme }) => theme.palette.secondary};
  text-align: center;
  text-decoration: none;
  white-space: inherit;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: ${({ theme }) => theme.primaryGray};
    box-shadow: none;
  }
`;
