import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import BookingIcon from './../../Booking/BookingIcon/BookingIcon';
import LinkButton from './../LinkButton/LinkButton';
import { UserModel } from 'Services/MeService';

export interface Props {
  user: UserModel;
  onChangeUser: () => void;
}

/**
 * ComponentName component
 * @param props Props
 */
const UserDetails: FC<Props> = (props: Props) => {
  const { name, email } = props.user;
  const { onChangeUser } = props;

  return (
    <Container>
      <Icon user={props.user} />
      <Title>{name}</Title>
      <Email>{email}</Email>
      <LinkButtonContainer>
        <LinkButton onClick={onChangeUser}>Change Selection</LinkButton>
      </LinkButtonContainer>
    </Container>
  );
};

UserDetails.defaultProps = {
  user: {} as UserModel,
};

export default UserDetails;

/**
 * Component styles
 */
const Dimensions = {
  mobile: {
    width: 300,
    padding: 10,
  },
  desktop: {
    width: 500,
    padding: 15,
  },
};

const mobPadding = `${Dimensions.mobile.padding * 2}px`;
const mobBottomPadding = `${Dimensions.mobile.padding * 4}px`;
const TextStyles = css`
  text-align: center;
`;

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.primaryWhite};
  border: none;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    max-height: 238px;
    padding: ${mobPadding} ${mobPadding} ${mobBottomPadding};
  }

  @media (min-width: 601px) {
    min-width: ${Dimensions.desktop.width}px;
    max-height: 256px;
    padding: ${Dimensions.desktop.padding * 2}px;
  }
`;

const Icon = styled(BookingIcon)`
  padding-top: 26px;
  padding-bottom: 20px;
`;

const Title = styled.span`
  font-weight: bold;
  margin-top: 20px;
  word-break: break-word;

  ${TextStyles};

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const Email = styled.span`
  margin-top: 27px;
  flex-wrap: wrap;
  word-break: break-word;

  ${TextStyles};

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (min-width: 601px) {
    font-size: 14px;
  }
`;

const LinkButtonContainer = styled.div`
  margin-top: 36px;
`;
