import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Typography, Grid, IconButton } from '@material-ui/core';
import { Person } from '@material-ui/icons';

const AppHeaderGrid = styled(Grid)`
  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 10px;
  }

  @media (min-width: 601px) {
    padding-left: 30px;
    padding-right: 20px;
  }
`;

const ProfileAvatar = styled(Person)`
  color: ${({ theme }) => theme.palette.primary};

  @media (max-width: 600px) {
    height: 36px !important;
    width: 36px !important;
  }

  @media (min-width: 601px) {
    height: 72px !important;
    width: 72px !important;
  }
`;

export const AppHeader: React.FC<{
  isProfileIconVisible?: boolean; //For MVP.
}> = ({ isProfileIconVisible }) => {
  const theme = useTheme();
  return (
    <AppHeaderGrid container direction="row" alignItems="center" role="banner">
      <Grid container justifyContent="flex-start" item xs={10} md={10}>
        <Typography variant={'h1'} style={{ color: theme.typography.primary }}>
          <strong>my</strong>Workplace
        </Typography>
      </Grid>
      {isProfileIconVisible && (
        <Grid container justifyContent="flex-end" item xs={2} md={2}>
          <IconButton style={{ padding: 5 }}>
            <ProfileAvatar />
          </IconButton>
        </Grid>
      )}
    </AppHeaderGrid>
  );
};
