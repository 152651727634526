import { Dialog, DialogContent } from '@material-ui/core';
import styled, { useTheme } from 'styled-components';
import { ActionButton } from 'Shared/ActionButton';
import { useRef, useState } from 'react';
import { BookingModel } from 'Services/BookingService';
import Select from 'components/Select/Select';
import dayjs from 'dayjs';

export interface Props {
  label: string;
  open: boolean;
  defaultTime: string;
  onClick?: (bookingModel?: BookingModel) => void;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  timeArray: string[];
  selectedDate: string;
}

export const CustomTimePicker: React.FC<Props> = (props) => {
  const {
    label,
    open,
    onClick,
    onClose,
    timeArray,
    defaultTime,
    selectedDate,
  } = props;
  const theme = useTheme();
  const buttonRef = useRef<HTMLDivElement>();

  const dateTime = selectedDate.split('T')[0] + ' ' + defaultTime;
  const formattedTime = dayjs(dateTime).format('H:mm').toString();
  const [selectedDateTime, setSelectedDateTime] = useState<string>(
    dayjs(selectedDate).format(`YYYY-MM-DDT${formattedTime}`)
  );

  const onTimeChange = (time: string) => {
    const dateTime = selectedDate.split('T')[0] + ' ' + time;
    const formattedTime = dayjs(dateTime).format('H:mm').toString();

    setSelectedDateTime(
      dayjs(selectedDate).format(`YYYY-MM-DDT${formattedTime}`)
    );

    buttonRef.current.focus();
  };

  return (
    <Dialog
      open={open}
      aria-modal={true}
      aria-label={label}
      PaperProps={{
        style: {
          backgroundColor: theme.background.primary,
          borderRadius: 10,
          width: 470,
          overflow: 'hidden',
          minWidth: 375,
          minHeight: 488,
          fontSize: 18,
        },
      }}
    >
      <Title
        style={{
          overflow: 'hidden',
          paddingTop: 32,
          textAlign: 'center',
          fontWeight: 700,
          fontSize: 18,
        }}
      >
        {label}
      </Title>
      <DialogContent
        style={{
          overflow: 'hidden',
          fontSize: 16,
        }}
      >
        <TimeInputWrapper>
          <Select
            onValueChange={(time) => onTimeChange(time)}
            selectedOption={defaultTime}
            options={timeArray}
          />
        </TimeInputWrapper>
      </DialogContent>
      <ButtonWrapper>
        <div ref={buttonRef} tabIndex={0}>
          <ActionButton
            text="Set time"
            onClick={() =>
              onClick({
                bookedDateObj: dayjs(selectedDateTime).toDate(),
                bookedDate: dayjs(selectedDateTime).toISOString(),
              })
            }
            style={{
              width: 135,
              backgroundColor: theme.palette.secondary,
            }}
          />
        </div>
      </ButtonWrapper>
      <ButtonWrapper>
        <CancelButton role="button" tabIndex={0} onClick={onClose}>
          Cancel
        </CancelButton>
      </ButtonWrapper>
    </Dialog>
  );
};

const Title = styled.p``;

const TimeInputWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.button`
  color: ${({ theme }) => theme.palette.primary};
  font-size: 18px;
  background-color: ${({ theme }) => theme.background.primary};
  outline: none;
  border: none;
  margin-bottom: 40px;
  :hover {
    cursor: pointer;
  }
  &:focus {
    border-radius: 50px;
    outline: 2px solid ${({ theme }) => theme.palette.primary};
    outline-offset: -2px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
