// -- Configs for meeting room time selection
export const startListTime = '08:30:00';
export const startTimeDuration = 9;
export const listIncrementSizeMinutes = 30;
export const endTimeDuration = 3.5;
export const getBookingsPollingDuration = 60;

export const MeetingRoomConfig = {
  defaultCapacity: 1,
};
