import {
  Container,
  Typography,
  useMediaQuery,
  useTheme as ut,
} from '@material-ui/core';
import styled from 'styled-components';
import React, { useEffect, useState, FocusEvent, useContext } from 'react';
import { Padding } from 'Booking/BookingMain';
import { Title } from 'Shared/Title';
import { getRecentBookedUsers, UserModel, getUsers } from 'Services/MeService';
import { RecentUserList } from 'Booking/RecentUsers/RecentUserList';
import { UserModel as RecentUser } from '../../../Services/MeService';
import { Loading } from 'Loading/Loading';
import { NextButton } from 'Shared/NextButton';
import { UserSearchBar } from './UserSearchBar';
import { Filters } from './SearchOutcomes';
import UserChipContainer from '../../../components/UserChipContainer/UserChipContainer';
import UserSelectionWarning from './UserSelectionWarning/UserSelectionWarning';
import AppContext from 'AppContext';
import FlowContext from 'Booking/FlowContext';
import { BookingModel } from 'Services/BookingService';
import { getDateAsString } from '../../../Helpers/DateHelper';
import { BookingType } from '../../BookingType';
import { CurrentUser } from './CurrentUser/CurrentUser';
import { getUserBookings } from 'Services/BookingService';
import { bookedUsersVersion } from 'Services/ApiVersionConfig';
import UserContext from './SelectUsers/UserContext';
import { ErrorWholePage } from 'Error/ErrorWholePage';

export enum SearchPrompt {
  empty = '',
  searchPrompt = 'Search by name',
  searchProgress = 'Searching...',
  searchNoMatch = `We didn't find any matches`,
  searchFailed = `Sorry, an error has occurred`,
}

export const SearchUserContent: React.FC<{
  onNext: (users: UserModel[]) => void;
  type: string;
  bookingFlowType: BookingType;
  selectionLimit: number;
}> = ({ onNext, bookingFlowType, type, selectionLimit }) => {
  const minCharsToStartSearch = 2;
  const isMobileView = useMediaQuery(ut().breakpoints.down('xs'));
  let focus: HTMLButtonElement;

  const bookingContext = useContext<{
    bookingModel: BookingModel;
  }>(FlowContext);
  const bookingModel = bookingContext?.bookingModel[0];

  const { userId: loggedInUserId, email: loggedInUserEmail } =
    useContext(AppContext).loggedInUser;
  const me = [
    {
      userId: loggedInUserId,
      name: 'You',
      email: loggedInUserEmail,
    },
  ];

  const userContext = useContext(UserContext);
  const messageText = `No more people can be added as you have reached the maximum of ${selectionLimit}`;
  const [isLoaded, setIsLoaded] = useState(false);
  const [displayRecentUserList, setDisplayRecentUserList] = useState(true);
  const [recentUsers, setRecentUsers] = useState<UserModel[]>([]);
  const [prompt, setPrompt] = useState(SearchPrompt.empty);
  const [searchString, setSearchString] = useState<string>();
  const [searchResults, setSearchResults] = useState<UserModel[]>([]);
  const [clonedRecentList, setClonedRecentList] = useState<UserModel[]>([]);
  const [selectedUsersList, setSelectedUsersList] = useState<UserModel[]>(
    userContext?.users
  );
  const [searchFocus, setSearchFocus] = useState(false);
  const [loggedInUserHasBookings, setLoggedInUserHasBookings] = useState(false);
  const [isCurrentUserBookingsLoaded, setCurrentUserBookingsLoaded] =
    useState(false);
  const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>();
  const [retryCount, setRetryCount] = useState(0);
  const [focusIsSet, setFocusIsSet] = useState<boolean>();

  const filterUsers = (users: RecentUser[]) => {
    return users.filter((d) => {
      return d.email !== loggedInUserEmail;
    });
  };

  const setMe = (hasBookings: boolean) => {
    !selectedUsersList.length && !hasBookings && setSelectedUsersList(me);
  };

  useEffect(() => {
    const fetchUserBookings = async () => {
      return await getUserBookings(
        '',
        bookingModel.bookedDate,
        bookingModel.bookedDate
      );
    };
    const recentBookedUsers = async () => {
      return await getRecentBookedUsers(
        bookedUsersVersion,
        getDateAsString(bookingModel?.bookedDateObj),
        getDateAsString(bookingModel?.bookedDateObj),
        true,
        BookingType.Desk
      );
    };

    fetchUserBookings()
      .then((bookings) => {
        const hasBookings =
          bookings.filter((d) => d.subject === type).length > 0;
        setLoggedInUserHasBookings(hasBookings);
        setMe(hasBookings);
        setCurrentUserBookingsLoaded(true);

        recentBookedUsers()
          .then((res) => {
            const users = res['users'];
            if (!!users && users.length > 0) {
              const filteredList = filterUsers(users);
              setClonedRecentList(filteredList);

              const recentList = filteredList.filter(
                (recentUser) =>
                  !selectedUsersList.find(
                    ({ userId }) => recentUser.userId === userId
                  )
              );
              setRecentUsers(recentList);
            } else {
              setDisplayRecentUserList(false);
            }
          })
          .catch(() => {
            setDisplayRecentUserList(false);
          });
      })
      .catch(() => {
        setIsLoadingFailed(true);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [retryCount]);

  // Perform a search only if necessary
  useEffect(() => {
    if (searchString === undefined) {
      return;
    }

    if (searchString.length < minCharsToStartSearch) {
      setPrompt(SearchPrompt.searchPrompt);
      setSearchResults([]);
    } else {
      setPrompt(SearchPrompt.searchProgress);

      getUsers(
        searchString,
        undefined,
        getDateAsString(bookingModel?.bookedDateObj),
        getDateAsString(bookingModel?.bookedDateObj),
        true,
        bookingFlowType
      )
        .then((res) => {
          const userList = filterUsers(res.users);

          setSearchResults(userList.slice(0, 10));

          if (userList.length > 0) {
            setPrompt(SearchPrompt.empty);
          } else {
            setPrompt(SearchPrompt.searchNoMatch);
          }
        })
        .catch(() => {
          setPrompt(SearchPrompt.searchFailed);
          setSearchResults([]);
        });
    }
  }, [searchString]);

  useEffect(() => {
    if (focusIsSet == true) {
      focus?.focus();
      setFocusIsSet(false);
    }
  }, [focusIsSet]);

  if (!isLoaded) {
    return <Loading />;
  }

  const onSearchFocus = (): void => {
    setDisplayRecentUserList(false);
    setSearchFocus(true);
    if (!searchResults.length) {
      setPrompt(SearchPrompt.searchPrompt);
    }
  };

  const onSearchBlur = (event: FocusEvent): void => {
    const groupName = event?.relatedTarget?.getAttribute('data-group-name');

    if (groupName !== 'user-search') {
      setDisplayRecentUserList(true);
      setSearchFocus(false);
    }

    if (!searchResults.length) {
      setPrompt(SearchPrompt.empty);
    }
  };

  const onSearchReset = (): void => {
    setDisplayRecentUserList(true);
    setSearchFocus(false);
    setSearchString('');
    setSearchResults([]);
  };

  const onChange = (userInput: string): void => {
    // Trim spaces and leading commas
    // then remove repeating allowable special characters
    // then remove all other special characters
    // and finally replace comma with space character.
    const newSearchString = userInput
      .replace(/(^[,\s]+)|([,\s]+$)/g, '')
      .replace(Filters.repeating, '$1')
      .replace(Filters.removable, '')
      .replace(Filters.replaceWithSpace, ' ')
      .split(' ')
      .filter((f) => f.length > 1)
      .join(' ');

    if (newSearchString !== searchString) {
      setSearchString(newSearchString);
    }
  };

  const onSelect = (userInput: UserModel, currentUser?: boolean): void => {
    if (currentUser) {
      setSelectedUsersList((prevUsersState) => [userInput, ...prevUsersState]);
    } else {
      setSelectedUsersList((prevUsersState) => [...prevUsersState, userInput]);

      const filteredRecentUsers = recentUsers.filter(
        (d) => d.email?.toLowerCase() !== userInput.email?.toLowerCase()
      );
      setRecentUsers(filteredRecentUsers);
    }
    //  Clear search input and search results
    onSearchReset();

    //Change focus.
    setFocusIsSet(true);
  };

  const setFocused = (focusedHtmlElement: HTMLButtonElement) => {
    focus = focusedHtmlElement;
  };

  const onRemove = (removedUser: UserModel): void => {
    const filteredList = selectedUsersList.filter(
      (d: UserModel) =>
        d.email?.toLowerCase() !== removedUser.email?.toLowerCase()
    );
    const isRecentUser =
      clonedRecentList.findIndex(
        (user: UserModel) =>
          user.email.toLowerCase() === removedUser.email?.toLowerCase()
      ) > -1;

    setSelectedUsersList(filteredList);
    isRecentUser &&
      setRecentUsers((prevUsersState) => [...prevUsersState, removedUser]);
    setSearchString('');
  };

  const isFlowDisabled =
    selectedUsersList.filter(
      (user: UserModel) => user.userId !== loggedInUserId
    ).length < 1;

  const showCurrentUser =
    selectedUsersList.findIndex((user) => user.userId === loggedInUserId) < 0 &&
    !searchFocus &&
    isCurrentUserBookingsLoaded;

  if (isLoadingFailed) {
    return (
      <ErrorWholePage
        message="Sorry, we couldn’t load this page."
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }

  return (
    <Container role="main">
      <Padding />
      <Title text="Who you want to book for" />

      <UserChipHolder>
        <UserChipContainer
          userData={selectedUsersList}
          onClick={(user) => onRemove(user)}
          focus={setFocused}
        />
      </UserChipHolder>

      {selectedUsersList.length >= selectionLimit ? (
        <UserSelectionWarning message={messageText} />
      ) : (
        <>
          <HintText>Search by name</HintText>
          <SearchBarSpacing>
            <UserSearchBar
              prompt={prompt}
              debounce={500}
              results={displayRecentUserList ? [] : searchResults}
              selectedUsersList={selectedUsersList}
              onChange={onChange}
              onFocus={onSearchFocus}
              onBlur={onSearchBlur}
              onReset={onSearchReset}
              onSelect={onSelect}
              resetInput={searchString ? false : true}
            />
          </SearchBarSpacing>

          {showCurrentUser && (
            <>
              <HintText>Add yourself to booking</HintText>
              <SearchBarSpacing>
                <CurrentUser
                  onClick={(data) => onSelect(data, true)}
                  alreadyHasDeskBooking={loggedInUserHasBookings}
                />
              </SearchBarSpacing>
            </>
          )}

          {isLoaded && !!recentUsers.length && displayRecentUserList && (
            <SearchBarSpacing>
              <RecentUserList
                heading="Recent people you have booked for"
                users={recentUsers}
                onNext={onSelect}
                isGroupBooking={true}
              />
            </SearchBarSpacing>
          )}
        </>
      )}

      <NextButtonContainer
        onMouseDown={(event: React.MouseEvent) => event.preventDefault()}
      >
        <NextButton
          text="Find desks"
          width={isMobileView ? 150 : 170}
          isDisabled={isFlowDisabled}
          /* TO-DO - Currently sending first user in the list - */
          onClick={() => !isFlowDisabled && onNext(selectedUsersList)}
        />
      </NextButtonContainer>
    </Container>
  );
};

const SearchBarSpacing = styled.div`
  margin-bottom: 44px;
`;

export const UserChipHolder = styled.div`
  @media (max-width: 600px) {
    padding-top: 10px;
  }

  @media (min-width: 601px) {
    padding-top: 17px;
  }
`;

const NextButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const HintText = styled(Typography)`
  color: ${({ theme }) => theme.primaryGray};

  @media (max-width: 600px) {
    font-size: 12px;
    margin-bottom: 5px;
  }

  @media (min-width: 601px) {
    font-size: 14px;
    margin-bottom: 14px;
  }

  margin-top: 30px;
`;
