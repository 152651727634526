import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button, Grid, Typography } from '@material-ui/core';
import { AppFooter } from 'AppFooter';
import { BaseContainer } from 'Shared/BaseContainer';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorIcon from '@material-ui/icons/Error';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { useLocation } from 'react-router-dom';

const Padding = styled.div`
  @media (max-width: 600px) {
    padding-top: 311px;
  }

  @media (min-width: 601px) {
    padding-top: 145px;
  }
`;

const TextInputContainer = styled.div`
  padding-top: 33px;
  padding-bottom: 90px;
  height: 50px;
`;

export const Login: React.FC<{
  onLogin: (domainHint: string, loginHint: string) => void;
}> = ({ onLogin }) => {
  useDocumentTitle(['Sign In']);
  // -- Custom styling used for MUI textfield
  const useStyles = makeStyles(() =>
    createStyles({
      textField: {
        background: theme.primaryWhite,
        width: 352,
        height: 46,
        marginTop: 5,
      },
      underline: {
        color: theme.primaryBlack,
        '&::before': {
          borderBottom: '1px solid ' + theme.primaryBlack,
        },
        '&$error:after': {
          borderBottom: '2px solid ' + theme.palette.errorRed,
        },
      },
      inputFocused: {
        '&::after': {
          borderBottom: '2px solid ' + theme.palette.secondary,
        },
      },
      floatingLabelFocusStyle: {
        '&.Mui-focused': {
          color: theme.palette.secondary,
          fontWeight: 'bold',
        },
        '&.Mui-error': {
          color: theme.palette.errorRed,
          fontWeight: 'bold',
        },
      },
      error: {
        '&.MuiFormHelperText-root.Mui-error': {
          color: theme.palette.errorRed,
        },
      },
    })
  );

  const helperTexts = {
    none: '', // blank message
    default: 'Select to enter email',
    update: 'Select to edit email',
    empty: 'Please enter a valid email address',
    invalid: 'This is not a valid email address',
    unauthorised: 'This email is not authorised to access myWorkplace',
  };

  const theme = useTheme();
  const classes = useStyles();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const queryError = query.get('error');
  const queryLoginHint = query.get('login_hint');

  const isUnauthorized = queryError === 'unauthorized' && queryLoginHint !== '';

  const [email, setEmail] = useState(isUnauthorized ? queryLoginHint : '');
  const [hasFocus, setHasFocus] = useState(false);
  const [formState, setFormState] = useState<keyof typeof helperTexts>(
    isUnauthorized ? 'unauthorised' : 'default'
  );

  const isError = (): boolean =>
    ['empty', 'invalid', 'unauthorised'].includes(formState);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const generateDomainHint = (email: string) => {
    return email.split('@')[1];
  };

  const signIn = () => {
    const isValidEmail = validateEmail(email);

    if (email === '') {
      setFormState('empty');
    } else if (!isValidEmail) {
      setFormState('invalid');
    } else {
      setFormState('none');
      const tenant = generateDomainHint(email);
      onLogin(tenant, email);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode == 13) {
      signIn();
    }
  };

  const handleOnClick = () => {
    signIn();
  };

  useEffect(() => {
    if (email === '') {
      setFormState('default');
    } else if (!isError()) {
      if (hasFocus) {
        setFormState('none');
      } else if (!hasFocus) {
        setFormState('update');
      }
    }
  }, [email, hasFocus]);

  const onTextChange = (event) => {
    const text = event.target.value;

    // user started changing email after an error message,
    // therefore reset the helper message.
    if (isError()) {
      setFormState('none');
    }
    setEmail(text.trim());
  };

  const onFocusChange = (event) => {
    if (!isError()) {
      setHasFocus(event.type === 'focus');
    }
  };

  return (
    <BaseContainer>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Padding>
            <Typography
              variant={'h1'}
              style={{
                color: theme.typography.primary,
                fontFamily: 'inherit',
                fontSize: 36,
              }}
            >
              <strong>my</strong>Workplace
            </Typography>
          </Padding>
        </Grid>
        <Grid item>
          <TextInputContainer>
            <TextField
              onKeyDown={handleOnKeyDown}
              error={isError()}
              id="filled-basic"
              label="Your email"
              helperText={helperTexts[formState]}
              margin="normal"
              variant="filled"
              color="primary"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              FormHelperTextProps={{
                classes: {
                  error: classes.error,
                },
              }}
              InputProps={{
                className: classes.textField,
                classes: {
                  underline: classes.underline,
                  focused: classes.inputFocused,
                  error: classes.error,
                },
                style: {
                  backgroundColor: theme.primaryWhite,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {isError() && <ErrorIcon />}
                  </InputAdornment>
                ),
              }}
              onChange={onTextChange}
              onFocus={onFocusChange}
              onBlur={onFocusChange}
              value={email}
            />
          </TextInputContainer>
        </Grid>
        <Grid item style={{ paddingTop: 24, paddingBottom: 24 }}>
          <Button
            onClick={handleOnClick}
            variant="contained"
            style={{
              color: theme.primaryWhite,
              background: theme.palette.secondary,
              fontSize: 14,
              textTransform: 'none',
              borderRadius: 125,
              width: 227,
              height: 45,
            }}
          >
            Sign In
          </Button>
        </Grid>
      </Grid>
      <AppFooter />
    </BaseContainer>
  );
};
