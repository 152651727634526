import { IconButton, InputAdornment, InputBase } from '@material-ui/core';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Search, Close } from '@material-ui/icons';
import React, { FocusEvent } from 'react';
import styled from 'styled-components';
import { SearchOutcomes } from './SearchOutcomes';
import { UserModel } from 'Services/MeService';
import { UserModel as RecentUser } from '../../../Services/MeService';
import { WarningDialog } from 'components/WarningDialog/WarningDialog';

export interface Props {
  prompt?: string;
  debounce?: number;
  results?: Array<UserModel>;
  selectedUsersList?: Array<UserModel>;
  resetInput: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: (event: FocusEvent) => void;
  onReset?: () => void;
  onSubmit?: (value: string) => void;
  onSelect?: (value: RecentUser) => void;
}

export const UserSearchBar: React.FC<Props> = (props: Props) => {
  const searchTimeoutAmount = props.debounce ?? 0; // ms
  const searchPrompt = props.prompt ?? '';
  const searchResults = props.results ?? [];
  const { resetInput } = props;
  const [searchTimeout, setSearchTimeout] =
    useState<ReturnType<typeof setTimeout>>();
  const [visibleClear, setVisibleClear] = useState(false);
  const [userInput, setUserInput] = useState('');
  const userInputRef = useRef(null);
  const onMouseDownRef = useRef(false);
  const [displayWarningDialogue, setDisplayWarningDialogue] = useState(false);

  useEffect(() => {
    if (resetInput) {
      userInputRef.current.value = '';
    }
  }, [resetInput]);

  const onInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;

    if (searchTimeoutAmount > 0) {
      // Update user input and clear prior searches.
      clearTimeout(searchTimeout);

      setSearchTimeout(
        setTimeout(() => {
          handleOnChange(value);
        }, searchTimeoutAmount)
      );
    } else {
      handleOnChange(value);
    }

    // Update Clear Button visibility.
    if (value.length > 0 && !visibleClear) {
      setVisibleClear(true);
    } else if (value.length < 1 && visibleClear) {
      setVisibleClear(false);
    }
  };

  const checkUserAlreadySelected = (user: UserModel) => {
    return !!props.selectedUsersList?.find(
      (selectedUser) => selectedUser.userId === user.userId
    );
  };

  const checkUserAlreadyHasDeskBooking = (user: UserModel) => {
    return (
      user.availability &&
      !!Object.values(user.availability).find(
        (date) => date.isAvailable === false
      )
    );
  };

  const handleOnChange = (value: string) => {
    props.onChange && props.onChange(value);
    setUserInput(value);
  };

  const handleOnSelect = (user: RecentUser) => () => {
    const hasBooking = checkUserAlreadyHasDeskBooking(user);

    if (!checkUserAlreadySelected(user) && !hasBooking) {
      props.onSelect && props.onSelect(user);
    }

    if (hasBooking) {
      setDisplayWarningDialogue(true);
    }
  };

  const displayResults = () => {
    if (searchResults.length <= 0) {
      return <React.Fragment />;
    } else {
      return (
        <>
          {searchResults.map((result: UserModel) => (
            <SearchOutcomes
              key={result.userId}
              search={userInput}
              highlight={true}
              initials=" "
              name={result.name}
              email={result.email}
              groupName={'user-search'}
              onClick={handleOnSelect(result)}
              isUserAlreadySelected={checkUserAlreadySelected(result)}
              alreadyHasDeskBooking={checkUserAlreadyHasDeskBooking(result)}
            />
          ))}
        </>
      );
    }
  };

  const onFocus = (): void => {
    props.onFocus && props.onFocus();
  };

  const onBlur = (event): void => {
    props.onBlur && !onMouseDownRef.current && props.onBlur(event);
  };

  const handleOnMouseDown = (): void => {
    onMouseDownRef.current = true;
  };

  const handleOnMouseUp = (): void => {
    onMouseDownRef.current = false;
  };

  const onReset = (): void => {
    setVisibleClear(false);
    setUserInput('');
    clearTimeout(searchTimeout);
    userInputRef.current.value = '';

    props.onReset && props.onReset();
  };

  const onSubmit = (event): void => {
    event.preventDefault();
    props.onSubmit && props.onSubmit(event.target.value);
  };

  return (
    <Field
      role="search"
      aria-label="User search"
      onFocus={onFocus}
      onBlur={onBlur}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <InputBar>
        <StyledInputBase
          placeholder="Search"
          type="text"
          spellCheck="false"
          inputProps={{ maxLength: 50 }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIconContainer>
                <SearchIcon role="searchbox" data-testid="searchIcon" />
              </SearchIconContainer>
            </InputAdornment>
          }
          onInput={onInput}
          inputRef={userInputRef}
        />
        {visibleClear && (
          <IconButton
            type="reset"
            aria-label="Clear search"
            data-group-name="user-search"
            data-testid="userSearch"
            style={{
              marginRight: '25px',
              width: '48px',
              height: '48px',
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </InputBar>
      {searchPrompt.length > 0 && (
        <SearchOutcomes
          search={userInput}
          highlight={false}
          name={searchPrompt}
        />
      )}
      <SearchOutcomeList
        role="list"
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
      >
        {displayResults()}
      </SearchOutcomeList>
      <WarningDialog
        labelOne="You can not add this person to desk booking because they
already have a desk booked."
        labelTwo="A user can not have more than one desk on any given day."
        onClick={() => {
          setDisplayWarningDialogue(false);
        }}
        open={displayWarningDialogue}
      />
    </Field>
  );
};

const SearchIconContainer = styled.div`
  @media (max-width: 600px) {
    margin-left: -10px;
  }
`;

const SearchIcon = styled(Search)`
  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
  }
  @media (min-width: 601px) {
    width: 36px;
    height: 36px;
  }
`;

const Field = styled.form``;

const InputBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ theme }) => theme.primaryWhite};
  border-radius: 4px;

  @media (max-width: 600px) {
    height: 45px;
  }

  @media (min-width: 601px) {
    height: 70px;
  }

  & * {
    transition: all 0.25s ease-in-out;
  }
`;

const SearchOutcomeList = styled.div`
  padding: 0px;
  margin: 0px;
`;

const StyledInputBase = styled(InputBase)`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 18px;
  text-transform: none;
  width: calc(100% - 48px);
  padding: 19px 25px 15px 25px;

  input[placeholder='Search']:focus::placeholder {
    color: transparent;
  }
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.secondaryBlack};
`;
