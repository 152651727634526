import { Container } from '@material-ui/core';
import React from 'react';
import { useTheme } from 'styled-components';

export const BaseContainer: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Container
      style={{
        minWidth: '100%',
        height: '100vh',
        backgroundColor: theme.background.primary,
        paddingLeft: 0,
        paddingRight: 0,
        overflowX: 'hidden',
      }}
      children={children}
    />
  );
};
