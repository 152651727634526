import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { SearchUserContent } from './SearchUserContent';
import { UserModel } from 'Services/MeService';
import { BookingType } from '../../BookingType';

export const SearchUser: React.FC<{
  type: string;
  bookingFlowType: BookingType;
  onNext: (users: UserModel[]) => void;
  onBack: () => void;
  selectionLimit: number;
}> = ({ type, onNext, onBack, bookingFlowType, selectionLimit }) => {
  const heading = `Book for a group: ${type}`;
  useDocumentTitle([heading, 'Select users']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <SearchUserContent
        onNext={onNext}
        type={type}
        bookingFlowType={bookingFlowType}
        selectionLimit={selectionLimit}
      />
    </BaseContainer>
  );
};
