export enum BookingType {
  Unspecified = 0,
  SiteVisit = 100,
  Desk = 200,
  Room = 300,
  CollaborationSpace = 400,
  CarSpace = 500,
  BehalfOf = 700,
  BehalfOfColleague = 800,
}
