import { BookingType } from 'Booking/BookingType';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ResourceFeatureOptions } from 'Services/ResourceService';

dayjs.extend(duration);

/**
 * Returns Parameter string for Object input.
 * E.g.:
 *      {filter: 'John', id: null, includeAvailability: true}
 *      will return a string as "filter=John&includeAvailability=true"
 *
 * @param params API params in an Object format.
 */

export const generateParamsString = (
  params: Record<string, unknown>
): string => {
  const parameters = [];

  Object.entries(params).forEach(([key, value]) => {
    parameters.push(
      value !== undefined && value !== null && value !== ''
        ? `${key}=${value}`
        : null
    );
  });

  return parameters.filter(Boolean).join('&');
};

/**
 * Generate Flow Header - generates a header string to match the booking flow type
 * @param bookingHeaderType - The booking(flow) type you are generating the header for
 * @param type - Type of asset you wish to display
 * @returns string - Heading string to match the Flow type
 */
export const generateFlowHeader = (
  bookingHeaderType: BookingType,
  type: string
): string => {
  const heading =
    bookingHeaderType === BookingType.BehalfOf
      ? `Book for a group: ${type}`
      : bookingHeaderType === BookingType.BehalfOfColleague
      ? `Book on behalf of someone: ${type}`
      : `Book a ${type}`;

  return heading;
};

/**
 * Check for selected type, .
 * @param type: Type of the selected booking.
 * @returns boolean - Returns true for Meeting Room.
 */
export const isMeetingRoomBooking = (type: string | number): boolean =>
  type === 'Meeting Room' || type === BookingType.Room;

/**
 * Generate Duration String
 * @param bookingDuration: duration of the booking in format hh:mm
 * @returns string - Returns string for time duration in the format: 'x hour(s) y minute(s)'
 */
export const generateDurationString = (bookingDuration: string): string => {
  const hours = bookingDuration.toString().split(':')[0];
  const minutes = bookingDuration.toString().split(':')[1];
  const hoursInt = parseInt(hours);
  const minutesInt = parseInt(minutes);
  const plural = (val: string) => `${parseInt(val) === 1 ? '' : 's'}`;
  const hourStr = hoursInt ? `${hoursInt} hour${plural(hours)}` : '';
  const minuteStr = minutesInt ? `${minutesInt} minute${plural(minutes)}` : '';

  return `(${`${hourStr} ${minuteStr}`.trim()})`;
};

/**
 * Generate seating capacity for meeting room as a resource feature
 * @param seatingCapacity - The seating capacity of the selected room
 * @returns Object - Resource feature option for meeting room capacity
 */
export const getPersonInfo = (
  seatingCapacity: number
): ResourceFeatureOptions => {
  return {
    resourceId: null,
    featureOptionId: null,
    resourceFeatureOptionName: `${seatingCapacity ?? 0} Person`,
    resourceFeatureOptionIcon:
      '<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" color="#000000" aria-hidden="true" style="font-size: 48px;"><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path></svg>',
    resourceFeatureDefaultSelected: false,
  };
};

const parseTime = (time: string): number => {
  const timeValues = time.split(':');
  return parseInt(timeValues[0]) * 60 + parseInt(timeValues[1]);
};

/**
 * Generates Time Array
 * @param step: interval
 * @param startTimeValue: startTime
 * @param endTime: endTime
 * @returns Array - Returns array of strings ["10:00 AM","10:15 AM"]
 */
export const generateTimeList = (
  step: number,
  startTimeValue: string,
  endTime = 24
): string[] => {
  const times = []; // time array
  let startTime = parseTime(startTimeValue); // start time '08:00'

  const ap = ['AM', 'PM']; // AM-PM

  //loop to increment the time and push results in array
  for (let i = 0; startTime < endTime * 60; i++) {
    const hour = Math.floor(startTime / 60); // getting hours of day in 0-24 format
    const minutes = startTime % 60; // getting minutes of the hour in 0-55 format

    const timeString =
      ('' + (hour % 12)).slice(-2) +
      ':' +
      ('0' + minutes).slice(-2) +
      ' ' +
      ap[Math.floor(hour / 12)];

    const timeSplit = timeString.split(':');
    timeSplit[0] = timeSplit[0] === '0' ? '12' : timeSplit[0];
    times[i] = timeSplit.join(':'); // pushing data in array in [12:00 - 12:00 AM/PM format]

    startTime = startTime + step;
  }
  return times;
};
