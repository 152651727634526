import { Button } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  endIcon: {
    marginRight: '4px',
  },
}));

const StyledButton = styled(Button)`
  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.primary} !important;
    border: 2px solid ${(props) => props.theme.primaryWhite};
  }
`;

const StyledText = styled.span`
  flex: 1 0;
`;

export const ActionButton: React.FC<{
  text: string;
  style: CSSProperties;
  onClick: () => void;
}> = ({ text, style, onClick, children }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <StyledButton
      variant="contained"
      disableRipple
      disableFocusRipple
      style={{
        ...style,
        color: theme.primaryWhite,
        fontSize: 14,
        textTransform: 'none',
        borderRadius: 50,
        height: 45,
      }}
      onClick={onClick}
      classes={{ endIcon: classes.endIcon }}
      endIcon={children}
    >
      <StyledText>{text}</StyledText>
    </StyledButton>
  );
};
