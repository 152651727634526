import styled from 'styled-components';
import React, { FC } from 'react';
import { getDayName } from '../../Helpers/DateHelper';
import { baseText, DisableableOption, ChevronRight } from 'Shared/ButtonStyles';
import { AvailabilityIconText, Availability } from '../Availability';
import { makeStyles } from '@material-ui/styles';
import { OpenInNew } from '@material-ui/icons';
import { isMeetingRoomBooking } from 'Helpers/Utils';

const useStyles = makeStyles(() => ({
  endIcon: {
    marginRight: '31px',
    ['@media (max-width:600px)']: {
      marginRight: '4px',
    },
  },
}));

const DayText = styled(baseText)`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  @media (max-width: 600px) {
    margin-bottom: -8px;
  }
  @media (min-width: 601px) {
    margin-bottom: -10px;
  }
`;

const DateText = styled(baseText)`
  color: ${({ theme }) => theme.primaryGray};
`;

const DayTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 5%;
`;

const IconTextLayout = styled.span`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  padding-left: 35%;
`;

export const DateCard: FC<{
  onSelect: () => void;
  date: Date;
  availability: Availability;
  bookingFlowType?: number;
  guestUser?: boolean;
  isGroupBooking?: boolean;
}> = ({
  onSelect,
  date,
  availability,
  bookingFlowType,
  guestUser,
  isGroupBooking,
}) => {
  const classes = useStyles();

  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const isAvailable =
    availability === Availability.AVAILABLE
      ? true
      : availability === Availability.OVERFLOWPARKINGONLY
      ? true
      : false;

  const handleOnClick = () => {
    if (availability === Availability.OVERFLOWPARKINGONLY) {
      window.open(
        'https://apps.powerapps.com/play/f2902581-f154-4a55-9090-8da6416caf3d?tenantId=49dfc6a3-5fb7-49f4-adea-c54e725bb854&redirected=true',
        '_blank',
        'noopener'
      );
    } else {
      onSelect();
    }
  };

  return (
    <div
      onFocus={() => {
        setTooltipIsOpen(true);
      }}
      onBlur={() => {
        setTooltipIsOpen(false);
      }}
      onMouseEnter={() => {
        setTooltipIsOpen(true);
      }}
      onMouseLeave={() => {
        setTooltipIsOpen(false);
      }}
      tabIndex={isAvailable || isMeetingRoomBooking(bookingFlowType) ? null : 0}
    >
      <DisableableOption
        variant="text"
        fullWidth
        disableRipple
        disableFocusRipple
        disabled={
          bookingFlowType && !isMeetingRoomBooking(bookingFlowType)
            ? isAvailable
              ? false
              : true
            : false
        }
        onClick={() => handleOnClick()}
        classes={{ endIcon: classes.endIcon }}
        endIcon={
          !bookingFlowType ||
          isMeetingRoomBooking(bookingFlowType) ||
          (isAvailable && availability !== Availability.OVERFLOWPARKINGONLY) ? (
            <ChevronRight data-testid="chevron" />
          ) : (
            availability === Availability.OVERFLOWPARKINGONLY && (
              <OpenInNew data-testid="openNew" />
            )
          )
        }
      >
        <DayTextContainer>
          <DayText>{getDayName(date)}</DayText>
          <DateText>{date.toLocaleDateString('en-AU')}</DateText>
        </DayTextContainer>
        <IconTextLayout>
          {bookingFlowType && !isGroupBooking && (
            <AvailabilityIconText
              availability={availability}
              toolTip={tooltipIsOpen}
              bookingFlowType={bookingFlowType}
              guestUser={guestUser}
            />
          )}
        </IconTextLayout>
      </DisableableOption>
    </div>
  );
};
