import React, { useContext, useState } from 'react';
import { BookingModel } from 'Services/BookingService';
import { Title } from 'Shared/Title';
import { ContainerSingleColumn } from 'Shared/ContainerSingleColumn';
import { TimeSelectList } from 'Booking/MeetingRoomBooking/TimeSelect/TimeSelectList';
import FlowContext from 'Booking/FlowContext';
import { generateTimeList } from 'Helpers/Utils';
import { CustomTimePicker } from 'components/CustomTimePicker/CustomTimePicker';
import dayjs from 'dayjs';

export type StartTime = `${number}:${number}:${number}`;

export const TimeContent: React.FC<{
  startListTime: StartTime;
  listDurationHours: number;
  incrementSizeMinutes: number;
  customTimeLabel: string;
  displaySubLabel: boolean;
  customMinTime: string;
  customMaxTime: number;
  headingText: string;
  onNext: (bookingModel?: BookingModel) => void;
}> = ({
  onNext,
  startListTime,
  listDurationHours,
  incrementSizeMinutes,
  customTimeLabel,
  displaySubLabel,
  customMinTime,
  customMaxTime,
  headingText,
}) => {
  const bookingModel: BookingModel = useContext(FlowContext).bookingModel;
  const bookedDate = bookingModel.bookedDate;
  const dateTime = bookedDate.split('T')[0] + startListTime;
  const [openTimePicker, setOpenTimePicker] = useState<boolean>(false);
  const selectedTime = dayjs(dateTime).format('h:mm A').toString();

  return (
    <ContainerSingleColumn role="main">
      <Title text={headingText} />
      <TimeSelectList
        startDateTime={dateTime}
        listDurationHours={listDurationHours}
        incrementSizeMinutes={incrementSizeMinutes}
        customTimeLabel={customTimeLabel}
        displaySubLabel={displaySubLabel}
        onNext={onNext}
        onCustomTimeNext={() => setOpenTimePicker(true)}
      />
      {openTimePicker && (
        <CustomTimePicker
          label={customTimeLabel}
          open={openTimePicker}
          onClick={onNext}
          onClose={() => setOpenTimePicker(false)}
          timeArray={generateTimeList(5, customMinTime, customMaxTime)}
          defaultTime={selectedTime}
          selectedDate={bookedDate}
        />
      )}
    </ContainerSingleColumn>
  );
};
