import { DefaultTheme } from 'styled-components';

export const telstraTheme: DefaultTheme = {
  primaryWhite: '#ffffff',
  primaryBlack: '#000000',
  secondaryBlack: '#333333',
  primaryGray: '#484848',
  palette: {
    primary: '#001e82',
    secondary: '#0064D2',
    green: '#074f30',
    red: '#7E0221',
    redLight: '#7E0221',
    yellow: '#b0680c',
    orange: '#954900',
    greenLight: '#4B7900',
    selectedBlue: '#0164D2',
    textGray: '#837676',
    lightGray: '#e5e5e5',
    borderGray: '#797979',
    errorRed: '#b00020',
    warningRed: '#a60014',
    lightPink: '#a6001424',
    pink: '#a6001480',
    disabledBlue: '#0064d233',
    warningBrown: '#9f6816',
    disabledGrey: '#ffffff80',
    loadingGrey: '#c9c9c9',
  },
  typography: {
    primary: '#001e82',
    secondary: '#2d4698',
    green: '#4b790080',
  },
  background: {
    primary: '#f1f5f9',
    secondary: '#e2f4ff',
    green: '#4b790021',
  },
};
