import React, { ChangeEvent, useEffect, useRef } from 'react';
import { Checkbox, Radio, Typography } from '@material-ui/core';
import { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import { FilterModel } from 'Services/ResourceService';
import { defaultFilterItem } from '../DefaultFilterItem';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedOutlined from '@material-ui/icons/RadioButtonCheckedOutlined';

export interface FilterItemModel {
  name: string;
  value: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  type: 'radio' | 'checkbox';
  icon?: FilterModel['resourceAttributeIcon'];
  description?: string;
}

export const FilterItem: React.FC<FilterItemModel> = (props) => {
  const { name, value, label, onChange, checked, type, icon, description } =
    props;

  const ref = useRef();
  const iconRef = useRef();

  const describedBy =
    !!description && description.length > 0
      ? `describeFor${name}${value}`
      : null;

  const componentProps = {
    name: name,
    value: value,
    onChange: onChange,
    checked: checked,
    inputRef: ref,
    disableRipple: true,
    inputProps: { 'aria-label': label, 'aria-describedby': describedBy },
  };

  const handleOnClick = (event) => {
    const elm = ref.current as HTMLInputElement;
    const inputElm = ['radio', 'checkbox'];
    if (
      event.type === 'click' &&
      !inputElm.includes(event.target.type) &&
      event.clientX !== 0 &&
      event.clientY !== 0
    ) {
      elm.focus();
      elm.click();
    }
  };

  useEffect(() => {
    const iconContainer = iconRef.current as SVGElement;

    if (iconContainer && !iconContainer.childElementCount) {
      const tempElm = document.createElement('div');
      tempElm.innerHTML = icon && icon.length > 0 ? icon : defaultFilterItem;

      const tempSvgElm = tempElm.querySelector('svg') as SVGElement;

      // Set accessibility attributes
      tempSvgElm.setAttribute('aria-hidden', 'true');
      tempSvgElm.setAttribute('focusable', 'false');

      iconContainer.appendChild(tempSvgElm);
    }
  }, [iconRef]);

  return (
    <Container checked={checked}>
      <Wrapper checked={checked} onClick={handleOnClick}>
        {type === 'radio' ? (
          <StyledRadio
            icon={
              <RadioIconContainer>
                <RadioButtonUncheckedIcon />
              </RadioIconContainer>
            }
            checkedIcon={
              <RadioIconContainer>
                <RadioButtonCheckedOutlined />
              </RadioIconContainer>
            }
            {...componentProps}
          />
        ) : (
          <StyledCheckbox {...componentProps} />
        )}
        <IconAndLabel>
          <Icon checked={checked} ref={iconRef} />
          <Label aria-hidden={true}>{label}</Label>
        </IconAndLabel>
      </Wrapper>
      {describedBy && <Description id={describedBy}>{description}</Description>}
    </Container>
  );
};

const FixedWidthMixin = css`
  width: 135px;
  height: 95px;
  @media (min-width: 601px) {
    min-width: 155px;
    height: 120px;
  }
`;

const FixedSizeMixin = css`
  ${FixedWidthMixin};
  height: 100%;
  @media (min-width: 601px) {
    height: 120px;
  }
`;

const Container = styled.div<{ checked: boolean; theme: DefaultTheme }>`
  display: flex;
  border-radius: 2px;
  flex-direction: column;
  @media (min-width: 601px) {
    flex-direction: row;
  }
  ${FixedWidthMixin};
  cursor: pointer;
  background: ${({ theme }) => theme.primaryWhite};
  ${({ checked, theme }) =>
    checked &&
    `
    background: ${theme.palette.selectedBlue};
  `}
`;

const Wrapper = styled.div<{ checked: boolean; theme: DefaultTheme }>`
  position: relative;
  display: flex;
  user-select: none;

  border-radius: 2px;
  box-sizing: border-box;

  transition: box-shadow 250ms, background-color 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${FixedSizeMixin};
  ${({ checked, theme }) =>
    checked &&
    `
    color: ${theme.primaryWhite};
  `}

  &:focus-within {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.primary};
    border: 2px solid ${(props) => props.theme.primaryWhite};
  }
`;

const IconAndLabel = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.div<{ checked: boolean; theme: DefaultTheme }>`
  & svg {
    color: ${({ checked, theme }) =>
      checked ? theme.primaryWhite : theme.primaryBlack};

    height: 32px;
    @media (min-width: 601px) {
      height: 36px;
    }
  }

  display: flex;
  justify-content: center;

  height: 32px;
  width: 128px;

  margin-bottom: 10px;

  @media (min-width: 601px) {
    height: 36px;
  }
`;

const Label = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 0 10px;
  @media (min-width: 601px) {
    font-size: 18px;
  }
`;

const RadioIconContainer = styled.span`
  display: flex;
  position: relative;
`;

const StyledCss = css<{ checked: boolean; theme: DefaultTheme }>`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.primaryGray};
  &.Mui-checked {
    color: ${({ theme }) => theme.primaryWhite};
  }
  &.Mui-focusVisible {
    top: -2px;
    left: -2px;
  }
`;

const StyledRadio = styled(Radio)`
  ${StyledCss}
`;

const StyledCheckbox = styled(Checkbox)`
  ${StyledCss}
`;

const Description = styled(Typography)`
  height: auto;
  font-size: 12px;
  line-height: 12px;
  padding: 10px;
  margin-bottom: -100%;
  margin-right: auto;
  @media (min-width: 601px) {
    font-size: 18px;
    line-height: 24px;
    padding: 10px 20px;
    margin-bottom: auto;
    margin-right: -145%;
  }
`;
