import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import React from 'react';
import { BookingOptionModel } from 'Services/ConfigService';
import { BaseContainer } from 'Shared/BaseContainer';
import { UserBookingContent } from './UserBookingContent';

export const UserBooking: React.FC<{
  bookingId: number;
  typeModel: BookingOptionModel;
  onNext: (cancelResult: boolean) => void;
  onBack: () => void;
  onViewFloorPlan?: (img) => void;
}> = ({ bookingId, typeModel, onNext, onBack, onViewFloorPlan }) => {
  const heading = `${typeModel.caption} Booking`;
  useDocumentTitle([heading, 'Your Booking']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <UserBookingContent
        bookingId={bookingId}
        typeModel={typeModel}
        onCancel={onNext}
        onViewFloorPlan={onViewFloorPlan}
      />
    </BaseContainer>
  );
};
