import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const PaddedGrid = styled(Grid)`
  @media (max-width: 600px) {
    padding-top: 30px;
  }

  @media (min-width: 601px) {
    padding-top: 70px;
  }
`;

export const GridSingleColumn: React.FC = ({ children }) => {
  return (
    <PaddedGrid container item direction="column" xs={11} md={6} spacing={2}>
      {children}
    </PaddedGrid>
  );
};
