import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { LocationsContent } from './LocationContent';
import { BookingModel } from 'Services/BookingService';
import { NextStepTrigger } from './NextStepTrigger';
import { LocationModel } from 'Services/LocationService';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BookingType } from 'Booking/BookingType';
import { generateFlowHeader } from 'Helpers/Utils';

export const SelectLocation: React.FC<{
  type: string;
  onNext: (
    trigger: NextStepTrigger,
    bookingModel?: BookingModel,
    pastLocations?: LocationModel[]
  ) => void;
  onBack: () => void;
  bookingFlowType?: BookingType;
}> = ({ type, onNext, onBack, bookingFlowType }) => {
  const heading = generateFlowHeader(bookingFlowType, type);
  useDocumentTitle([heading, 'Select Location']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <LocationsContent onNext={onNext} type={type} />
    </BaseContainer>
  );
};
