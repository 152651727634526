import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { DateContent } from './DateContent';
import { BookingModel } from 'Services/BookingService';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { BookingType } from 'Booking/BookingType';
import { generateFlowHeader } from 'Helpers/Utils';

export const SelectDate: React.FC<{
  type: string;
  onNext: (bookingModel?: BookingModel) => void;
  onBack: () => void;
  bookingFlowType: number;
  guestUser?: boolean;
  bookingHeaderType?: BookingType;
  isGroupBooking?: boolean;
}> = ({
  type,
  onNext,
  onBack,
  bookingFlowType,
  guestUser,
  isGroupBooking,
  bookingHeaderType,
}) => {
  const heading = generateFlowHeader(bookingHeaderType, type);
  useDocumentTitle([heading, 'Select Date']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <DateContent
        onNext={onNext}
        bookingFlowType={bookingFlowType}
        guestUser={guestUser}
        isGroupBooking={isGroupBooking}
      />
    </BaseContainer>
  );
};
