import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { SearchUserContent } from './SearchUserContent';
import { UserModel as RecentUser } from '../../../Services/MeService';

export const SearchUser: React.FC<{
  type: string;
  loggedInEmail: string;
  onNext: (user: RecentUser) => void;
  onBack: () => void;
}> = ({ type, loggedInEmail, onNext, onBack }) => {
  const heading = `Book on behalf of someone: ` + type;
  useDocumentTitle([heading, 'Select A User']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <SearchUserContent
        onNext={onNext}
        type={type}
        loggedInEmail={loggedInEmail}
      />
    </BaseContainer>
  );
};
