import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { CheckCircle, Error, Timer } from '@material-ui/icons';
import { OperationType } from './OperationType';
import { BookingOptionModel } from 'Services/ConfigService';
import { BookingType } from 'Booking/BookingType';

const MessageText = styled(Typography)`
  color: ${({ theme }) => theme.secondaryBlack};
  text-transform: none;
  font-size: 18px;
`;

const IconStyles = css`
  height: 48px;
  width: 48px;
`;

const SuccessIcon = styled(CheckCircle)`
  color: ${({ theme }) => theme.palette.greenLight};
  ${IconStyles}
`;

const FailIcon = styled(Error)`
  color: ${({ theme }) => theme.palette.redLight};
  ${IconStyles}
`;

const TimerIcon = styled(Timer)`
  color: ${({ theme }) => theme.palette.orange};
  ${IconStyles}
`;

const ContainerSingleColumn = styled(Container)`
  background-color: ${({ theme }) => theme.primaryWhite} !important;
  border-radius: 4px;
`;

const TextContainer = styled.div`
  max-width: 580px;
`;

export const CompletionCard: React.FC<{
  isOperationSuccessful: boolean;
  operationType: OperationType;
  type?: BookingOptionModel;
}> = ({ isOperationSuccessful, operationType, type }) => {
  const getMessageText = () => {
    switch (operationType) {
      case OperationType.CONFIRM:
        return isOperationSuccessful ? (
          type?.resourceType === BookingType.Room ? (
            <TextContainer>
              Your booking is pending. It may take some time for your booking to
              be confirmed. Once the booking is confirmed, it will show up on
              your home screen. You will also receive an email notification once
              the pending status changes.
            </TextContainer>
          ) : (
            'Your booking is now confirmed'
          )
        ) : (
          'Sorry, we couldn’t confirm this booking.'
        );
      case OperationType.CANCEL:
        return isOperationSuccessful
          ? 'Your booking is now cancelled'
          : 'Sorry, we couldn’t cancel this booking.';
    }
    return '';
  };

  return (
    <ContainerSingleColumn>
      <Grid container direction="column" alignItems="center">
        <Grid item style={{ paddingTop: 30 }}>
          <MessageText>
            {isOperationSuccessful ? (
              type?.resourceType === 300 ? (
                <TimerIcon />
              ) : (
                <SuccessIcon />
              )
            ) : (
              <FailIcon />
            )}
          </MessageText>
        </Grid>
        <Grid item style={{ paddingTop: 10, paddingBottom: 40 }}>
          <MessageText style={{ textAlign: 'center' }}>
            {getMessageText()}
          </MessageText>
          {!isOperationSuccessful && (
            <MessageText style={{ textAlign: 'center' }}>
              Please try again.
            </MessageText>
          )}
        </Grid>
      </Grid>
    </ContainerSingleColumn>
  );
};
