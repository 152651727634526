import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { BaseContainer } from 'Shared/BaseContainer';
import React from 'react';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import { SelectBehalfOfTypeContent } from './SelectBehalfOfTypeContent';

export const SelectBehalfOfType: React.FC<{
  onNext: (isGroupBooking: boolean) => void;
  onBack: () => void;
}> = ({ onNext, onBack }) => {
  const heading = `Book for others`;
  useDocumentTitle([heading, 'Book for others']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <SelectBehalfOfTypeContent
        onNext={(isGroupBooking) => onNext(isGroupBooking)}
      />
    </BaseContainer>
  );
};
