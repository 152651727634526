import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import styled, { useTheme } from 'styled-components';

const CancelDialogContent = styled(DialogContent)`
  width: 262px;
  color: ${({ theme }) => theme.primaryBlack};
`;

const DialogButtonText = styled(Typography)<{ component?: React.ElementType }>`
  text-transform: none;
`;

const DialogButton = styled(Button)`
  color: ${({ theme }) => theme.typography.secondary};
  background: ${({ theme }) => theme.background.primary};
  font-size: 18px;
  border-radius: 50px;
  width: 200px;
  height: 36px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.palette.primary};
  }
`;

export const CancelConfirm: React.FC<{
  open: boolean;
  onYes: () => void;
  onNo: () => void;
  cancelReserved?: boolean;
}> = ({ open, onYes, onNo, cancelReserved }) => {
  const theme = useTheme();

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          style: {
            backgroundColor: theme.background.primary,
            borderRadius: 10,
            width: 310,
          },
        }}
        onEscapeKeyDown={onNo}
        $aria-modal={true}
      >
        <CancelDialogContent style={{ paddingTop: 30 }}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography
                gutterBottom
                align="center"
                style={{ lineHeight: 1.9, width: 200, outline: 'none' }}
                tabIndex={-1}
                ref={(node) => {
                  node && node.focus();
                }}
              >
                Are you sure you want to cancel your booking?
              </Typography>
            </Grid>
          </Grid>
        </CancelDialogContent>
        <DialogActions style={{ paddingBottom: 20 }}>
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid item>
              <DialogButton disableRipple disableFocusRipple onClick={onYes}>
                <DialogButtonText style={{ fontSize: '18px' }} component="span">
                  Yes, cancel booking
                </DialogButtonText>
              </DialogButton>
            </Grid>
            <Grid item>
              <DialogButton disableRipple disableFocusRipple onClick={onNo}>
                <DialogButtonText style={{ fontSize: '18px' }} component="span">
                  {cancelReserved ? "No, don't cancel" : 'No, keep it'}
                </DialogButtonText>
              </DialogButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
