import { BookingStepHeader } from 'Booking/BookingStepHeader';
import { useDocumentTitle } from 'Helpers/CustomHooks';
import React from 'react';
import { BookingOptionModel } from 'Services/ConfigService';
import { BaseContainer } from 'Shared/BaseContainer';
import { ConfirmationContent } from './ConfirmationContent';

export const Confirmation: React.FC<{
  typeModel: BookingOptionModel;
  onNext: (result: boolean) => void;
  onBack: () => void;
}> = ({ typeModel, onNext, onBack }) => {
  const heading = `Book a ${typeModel.caption}`;
  useDocumentTitle([heading, 'Confirm Booking']);

  return (
    <BaseContainer>
      <BookingStepHeader heading={heading} onBack={onBack} />
      <ConfirmationContent typeModel={typeModel} onNext={onNext} />
    </BaseContainer>
  );
};
